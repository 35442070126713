import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie';

import { Flex, Row, Col, Typography, message } from "antd";
import { CameraOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";

import notLoadingImage from "assets/images/icon/not_loading_image.svg";

import { partnerRegisterDataGroup } from "data/mypage/advertiser/partner/register";
import { usePartnerRegistration } from 'hooks/usePartnerRegistration';


function AdvertiserPartnerRegister() {
    const navigate = useNavigate();

    const formRef = useRef<{ [key: string]: any }>({});
    const { registerPartner, isLoading, error } = usePartnerRegistration();

    partnerRegisterDataGroup.forEach((element) => {
        formRef.current[element.mainInput.id] = "";
    });

    const handleInputChange = (id: string, value: any) => {
        formRef.current[id] = value;
    };

    async function joinPartnerButtonEvent() {
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            console.log("Validation Errors:", errors);
            message.error(Object.values(errors).join('/'));
            return;
        }
        
        const partnerData = {
            
            flag: "partner",
            member_serial: Number(Cookies.get('serial')) || 0, // 쿠키에서 serial 값을 number로 변환
            name: formRef.current.name || "",
            owner: formRef.current.owner || "",
            number: formRef.current.number || "",            
            business_type: formRef.current.business_type || "",
            business_item: formRef.current.business_item || "",
            zipcode: formRef.current.zipcode || "",
            city: formRef.current.city || "",
            addr: formRef.current.addr || "",
            manager: formRef.current.manager || "",
            email: formRef.current.email || "",
            image: "/assets/images/240521161809.jpg",
            tell: formRef.current.tell || "",
            memo: formRef.current.memo || "",
        };
        
        // 일반적인 return success를 전달주지 않고, true 라는 값을 전달 주고 있음

        try {
          const result = await registerPartner(partnerData);
          
          if (result === true) { // result가 true인지 직접 확인
            console.log('가입 성공:', result);
            navigate("../campaign");
          } else {
            console.log('가입 실패:', result);
            message.error(result);
          }
        //   if (result.success) {
        //     console.log('가입 성공:', result);
        //     // navigate("./complete");
        //   } else {
        //     console.log('가입 실패:', result.message);
        //     message.error(result.message);
        //   }
        } catch (err) {
          console.error('가입 Error:', err);
          // 에러 처리 로직 추가
        }
      }

    const validateForm = () => {
        const errors: { [key: string]: string } = {};

        if (!formRef.current.name) errors.name = "회사명을 입력해주세요.";
        if (!formRef.current.owner) errors.owner = "대표자명를 입력해주세요.";
        if (!formRef.current.number) errors.number = "사업자등록번호를 입력해주세요.";
        if (!formRef.current.business_type) errors.business_type = "업태명을 입력해주세요.";
        // if (!formRef.current.business_item) errors.business_item = "업종명을 입력해주세요.";
        if (!formRef.current.city) errors.city = "주소를 입력해주세요.";
        if (!formRef.current.manager) errors.manager = "담당자를 입력해주세요.";
        if (!formRef.current.email) errors.email = "이메일을 입력해주세요.";
        if (!formRef.current.tell) errors.tell = "담당자 연락처를 입력해주세요.";
        return errors;
    };

    const subButtonEventArray = [
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
    ];

    function modifyButtonEvent() {
        navigate("/mypage/advertiser/partner-management/add-partner/campaign");
    }


    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Row gutter={[16, 16]} justify="center" align="middle">
                <Col span={4}>
                    <Box className="categoryTitleBox">
                        <Box className="categoryTitleBoxInner">
                            <Typography>회사 로고</Typography>
                        </Box>
                    </Box>
                </Col>
                <Col span={20}>
                    <Box className="categoryDataBox">
                        <Box className="partnerLogoImgBox">
                            <Box
                                className="imageViewBox"
                                sx={{
                                    backgroundImage: `url(${notLoadingImage})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "28px",
                                    backgroundPosition: "center center",
                                }}
                            >
                                <Box className="imageViewBoxInner">
                                    {/* 이미지 영역 */}
                                </Box>
                            </Box>
                            <Box className="uploadImgButtonBox">
                                <VcAntButton
                                    icon={<CameraOutlined />}
                                    conceptType="concept"
                                    onClick={() => {
                                        console.log("사진 변경!");
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Col>
            </Row>
            <SectionFormControl
                categoryInputData={partnerRegisterDataGroup}
                subButtonEventData={subButtonEventArray}
                onInputChange={handleInputChange}
            />
        </Box>
    );

    return (
        <Box className="advertiserPartnerRegisterWrap">
            <MypageSectionCard
                topTitle="파트너사 등록 - 회사 정보"
                bodyContent={bodyContentData}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            // onClick={modifyButtonEvent}
                            onClick={joinPartnerButtonEvent}
                            // onClick={() => {
                            //     console.log('입력된 partnerData:', formRef.current);
                            // }}
                            buttonTitle="다음"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserPartnerRegister;
