import axios from "axios";

interface FindPasswordResponse {
  answer: string;
  passwd: string;
}

const API_URL = '/api/';
export const findPassword = async (userId:string, phoneNumber: string): Promise<FindPasswordResponse[]> => {
  try {
      
      const response = await axios.post(`${API_URL}?cat=member&req=resetpwd`,{id:userId, hp:phoneNumber});

      const data = response.data;
      
      // "FAIL 회원없음" 체크
      if (data[0].answer.includes("FAIL")) {
          return [{
              answer: "FAIL",
              passwd: ""
          }];
      }

      // SUCCESS인 경우 반환된 비밀번호와 함께 전달
      return [{
          answer: "SUCCESS",
          passwd: data[0].passwd
      }];

  } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          throw new Error(`서버 오류: ${error.response.status}`);
        } else if (error.request) {
          throw new Error('서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.');
        } else {
          throw new Error(`요청 오류: ${error.message}`);
        }
      } else {
        const errorMessage = (error as Error).message;
        throw new Error(errorMessage);
      }
  }
};