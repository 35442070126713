import axios from "axios";

const API_URL = "/api/";

interface CreateMessageParams {
  sender_serial: string;
  receiver_serial: string;
  msg: string;
}

export const createMessage = async (params: CreateMessageParams) => {
  try {
    const response = await axios.post(
      `${API_URL}?cat=message&req=create`,
      params
    );

    if (response.status !== 200) {
      throw new Error("메시지 전송에 실패했습니다");
    }

    return response.data;
  } catch (error) {
    console.error("메시지 전송 실패:", error);
    throw error;
  }
};
