export const partnerRegisterDataGroup = [
    {
        title: "회사명",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "회사명을 입력해주세요",
            id: "name",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "대표자명",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "대표자명을 입력해주세요",
            id: "owner",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "사업자등록번호",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "123-12-12345",
            id: "number",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "업태/업종",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "업태명을 입력해주세요",
            id: "business_type",
        },  
        secondInput: {
            switch: true,
            type: "basic",
            placeholder: "업종명을 입력해주세요",
            id: "business_item",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "주소",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "주소를 입력해주세요",
            id: "city"
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: true,
                label: "우편번호 찾기",
                id: "zipcode"
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: true,
            type: "basic",
            placeholder: "상세주소를 입력해주세요",
            id: "addr"
        },
    },
    {
        title: "세금계산서 이메일",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "abc@email.com",   
            id: "email"
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "담당자명",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "담당자명을 입력해주세요",
            id: "manager"
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "담당자 연락처",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "담당자 연락처를 입력해주세요",
            id: "tell"
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
];

export const advertiserPartnerRegisterCompleteDataGroup = {
    guideText: "파트너사 등록이 완료되었습니다.",
    guideDescription: "",
};

// sample
// {
//     title: "타이틀",
//     mainInput: {
//         switch: true,
//         type: "basic",
//         placeholder: "",
//     },
//     secondInput: {
//         switch: false,
//         type: "basic",
//         placeholder: "",
//     },
//     subButton: {
//         left: {
//             switch: false,
//             label: "",
//         },
//         right: {
//             switch: false,
//             label: "",
//         },
//     },
//     moreInput: {
//         switch: false,
//         type: "basic",
//         placeholder: "",
//     },
// },
