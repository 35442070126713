export const membershipWithdrawalCommonDataGroup = {
    pageNoticeDescription: "서비스 탈퇴 전 아래의 주의사항을 꼭 확인해 주세요",
    greyBoxTitle: "탈퇴할 계정",
    warningBox: [
        {
            title: "개인정보 및 서비스 이용정보 삭제",
            list: [
                "회원 탈퇴와 동시에 개인정보 및 개인화 서비스 이용정보가 모두 삭제 되며, 삭제된 데이터는 절대 복구 불가합니다.",
                "탈퇴한 개인정보는 관련법령에 따라 즉시 파기됩니다.",
            ],
        },
        {
            title: "소셜 계정 연결 정보 삭제",
            list: ["이메일 ID에 소셜 계정을 연결한 경우 탈퇴시 연결 정보도 함께 삭제 됩니다."],
        },
        {
            title: "탈퇴 후 제한",
            list: ["탈퇴 처리된 이메일 ID는 90일동안 재가입이 불가합니다."],
        },
    ],
    confirm: {
        text: "위 조건에 따른 주의사항을 모두 인지하고, 그 내용에 대해서 동의 하십니까?",
        checkDefaultValue: false,
    },
    modal: {
        text: {
            top: "탈퇴 최종 확인",
            body: ["회원탈퇴를 정말 하시겠습니까?", "비밀번호 입력 후 '회원탈퇴' 버튼을 누르시면", "최종 탈퇴 처리 됩니다."],
        },
    },
};

export const membershipWithdrawalDataGroup = {
    userInfoAccount: "abcde123@email.com",
};
