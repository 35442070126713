import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // useLocation 추가

import { Flex, Typography, Radio, Tabs, Row, Col } from "antd";
import type { TabsProps } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import AdvertiserCampaignDetailMaterialLayout from "./materialLayout";

import { campaignListDataGroup } from "data/mypage/advertiser/campaign/list";
import { materialListDataGroup } from "data/mypage/advertiser/material/list";

import useFetchCampaignData from 'hooks/useFetchCampaignData';


/**
 * 쿠키에서 특정 값을 가져오는 유틸리티 함수
 * @param name 쿠키 이름
 * @returns 쿠키 값
 */
const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
};

interface Material {
    title: string;
    materialPreview: string;
    content: string;
}

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserCampaignDetail({ className, style }: vcProps) {
    const navigate = useNavigate();
    const location = useLocation(); // useLocation 훅 사용

    // URL의 쿼리 파라미터에서 serial 값을 추출
    const queryParams = new URLSearchParams(location.search);
    const campaign_serial = getCookie('serial') || '';
    const material_serial = queryParams.get("serial") || "";
    
    const { data: campaignData, loading: campaignLoading, error: campaignError } = useFetchCampaignData("all",campaign_serial);
    const { data: materialData, loading: materialLoading, error: materialError } = useFetchCampaignData("materials",material_serial);
    console.log(materialData);
    if (materialLoading) {
        return <Typography>로딩 중...</Typography>;
    }

    if (materialError) {
        return <Typography>데이터를 불러오는 데 실패했습니다.</Typography>;
    }

    if (campaignLoading) {
        return <Typography>캠페인 데이터를 로딩 중...</Typography>;
    }

    if (campaignError) {
        return <Typography>캠페인 데이터를 불러오는 데 실패했습니다.</Typography>;
    }
    
    const currentCampaign = campaignData.find((campaign: any) => campaign.serial === material_serial);

    if (!currentCampaign) {
        return <Typography>캠페인을 찾을 수 없습니다.</Typography>;
    }

    function campaignEditOnClickEvent() {
        // console.log("캠페인 편집");
        navigate("/mypage/advertiser/campaign-management/campaign-name/edit");
    }

    function goToListButtonEvent() {
        // console.log("목록으로");
        navigate("/mypage/advertiser/campaign-management/campaign-list");
    }

    const bodyContentData1 = (
        <Box className="bodyContentDataBox bodyContentDataBox1">
            <Box className="bodyContentDataLayoutBox">
                <Flex justify="flex-start" align="center" gap="16px">
                    <Box className="imgBox">
                        <Box className="imgBoxInner">
                            <img
                                src={campaignListDataGroup[0].imgPath}
                                alt=""
                            />
                        </Box>
                    </Box>
                    <Box className="informationBox">
                        <Box className="informationBoxInner">
                            <Flex
                                justify="flex-start"
                                align="center"
                                style={{ width: "100%", height: "100%" }}
                            >
                                <Box className="textBox">
                                    <Box className="nameBox">
                                        <Typography>
                                            {currentCampaign.title}
                                        </Typography>
                                    </Box>
                                    <Box className="subinformationBox">
                                        <Typography>
                                            {currentCampaign.content}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Box className="buttonBox">
                        <Box>
                            <VcAntButton
                                conceptType="concept"
                                buttonType="default"
                                onClick={campaignEditOnClickEvent}
                                buttonTitle="캠페인 편집"
                                buttonSize="middle"
                            />
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );

    function materialChangeTabRadioEvent(key: string) {
        console.log(key);
    }

    const tabsBoxSetStyles = {
        "&": {
            "& .ant-tabs": {
                "& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn": {},
            },
        },
    };

    const items: TabsProps["items"] = [];
    // materialListDataGroup.forEach((element: any, index: any) =>
    //     items.push({
    //         key: index,
    //         label: element.materialName,
    //         children: (
    //             <AdvertiserCampaignDetailMaterialLayout
    //                 matName={element.materialName}
    //                 matPreview={element.materialPreview}
    //                 matDescription={element.description}
    //             />
    //         ),
    //     })
    // );

    if (materialData && Array.isArray(materialData)) {
        materialData.forEach((element: Material, index: number) =>
            items.push({
                key: index.toString(),
                label: element.title,
                children: (
                    <AdvertiserCampaignDetailMaterialLayout
                        matName={element.title}
                        matPreview={element.materialPreview}
                        matDescription={element.content}
                    />
                ),
            })
        );
    }

    // materialListDataGroup.map(
    //     (data: any, index: number) => console.log(data)
    // );

    const bodyContentData2 = (
        <Box className="bodyContentDataBox bodyContentDataBox2">
            <Box className="tabsBox" sx={tabsBoxSetStyles}>
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={materialChangeTabRadioEvent}
                    tabPosition="left"
                />
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserCampaignDetailWrap">
            <MypageSectionCard
                topTitle="캠페인 상세"
                bodyContent={bodyContentData1}
            />
            <MypageSectionCard
                topTitle="광고 소재"
                bodyContent={bodyContentData2}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={goToListButtonEvent}
                            buttonTitle="목록으로"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserCampaignDetail;
