import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Row, Col, Typography } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";
import SectionMaterialFormControl from "pages/mypage/common/sectionMaterialFormControl";

import {
    campaignEditDataGroup,
    MaterialFormType,
} from "data/mypage/advertiser/campaign/edit";

function AdvertiserCampaignEdit() {
    const navigate = useNavigate();
    const [materialForms, setMaterialForms] = useState<MaterialFormType[]>([]);

    function handleInputChange(id: string, value: any) {
        console.log(`Input changed: ${id} = ${value}`);
    }

    function modifyCancelButtonEvent() {
        navigate(-1);
    }

    function modifySaveButtonEvent() {
        console.log("저장하기!");
    }

    function createMaterialEditDataGroup(index: number) {  // index 매개변수 추가
        return {
            dataGroup: {
                lineGroup1: {
                    title: "광고 소재",
                    mainInput: {
                        switch: true,
                        type: "basic",
                        placeholder: "광고 소재를 입력해주세요1",
                        id: `materialTitle_${index}`  // index 추가
                    },
                    subButton: {
                        left: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기");
                            },
                        },
                        right: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기");
                            },
                        },
                    },
                },
                lineGroup2: {
                    title: "광고 소재 URL",
                    mainInput: {
                        switch: true,
                        type: "basic",
                        placeholder: "광고 소재 URL을 입력해주세요2",
                        id: `materialUrl_${index}`  // index 추가
                    },
                    subButton: {
                        left: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기1");
                            },
                        },
                        right: {
                            switch: true,
                            label: "보기",
                            event: () => {
                                console.log("보기1");
                            },
                        },
                    },
                },
                lineGroup3: {
                    title: "광고 소재 내용",
                    mainInput: {
                        switch: true,
                        inputType: "textarea",
                        type: "basic",
                        placeholder: "광고 소재 내용을 입력해주세요3",
                        id: `materialContent_${index}`  // index 추가
                    },
                    subButton: {
                        left: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기2");
                            },
                        },
                        right: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기2");
                            },
                        },
                    },
                },
            },
        };
    }

    function materialPlusOnClickEvent() {
        setMaterialForms(prevForms => [...prevForms, createMaterialEditDataGroup(prevForms.length)]);
    }
    

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <SectionFormControl categoryInputData={campaignEditDataGroup} onInputChange={handleInputChange} />
            {materialForms.map((materialData, index) => (
                <SectionMaterialFormControl
                    key={index}
                    categoryInputData={[materialData]} // 배열로 감싸서 전달
                    onInputChange={handleInputChange}
                />
            ))}
            <Box className="materialPlusButtonBox">
                <Flex justify="center" align="center">
                    <VcAntButton
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={materialPlusOnClickEvent}
                    />
                </Flex>
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserCampaignEditWrap">
            <MypageSectionCard
                topTitle="캠페인 편집"
                bodyContent={bodyContentData}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="space-between" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifyCancelButtonEvent}
                            buttonTitle="취소"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifySaveButtonEvent}
                            buttonTitle="저장하기"
                            buttonSize="middle"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserCampaignEdit;
