import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { Typography, Flex, Modal } from "antd";
// import { CaretDownOutlined } from "@ant-design/icons/lib/icons";

import { Box } from "@mui/material";

import ContentCard from "components/layout/contentCard";

import VcAntInput from "components/antd/data-entry/input";
import VcAntInputNumber from "components/antd/data-entry/inputNumber";
import VcAntSelect from "components/antd/data-entry/select";
import VcAntButton from "components/antd/general/button";

import { controlButtonDataGroup, formDataGroup, findPwGuideMessageDataGroup, findPwModalDataGroup, pageTitleData } from "data/authority/findauth";

import { VcAntButtonTheme } from "theme/component";
import { useFindPassword } from "hooks/useFindPassword"; // Hook import

function FindPassword() {
    const navigate = useNavigate();

    const {
        phoneStep1,
        phoneStep2,
        phoneStep3,
        userId,
        notFoundModalOpen,
        foundResultModalOpen,
        foundPassword,        
        handleUserIdChange,
        phoneNumberSelectOnChangeEvent,
        handlePhoneStep2Change,
        handlePhoneStep3Change,
        findPwSubmitEvent,
        setNotFoundModalOpen,
        setFoundResultModalOpen,   
    } = useFindPassword();


    
    // 탭형식 버튼 그룹
    function tabsButtonFindIdPageEvent() {
        // console.log("아이디 찾기 페이지로");
        navigate("/authority/find-id");
    }
    function tabsButtonFindPwPageEvent() {
        // console.log("비밀번호 찾기 페이지로");
        navigate("/authority/find-pw");
    }
    // ---

    // 전화번호 셀렉트
    // function phoneNumberSelectOnChangeEvent(value: string) {
    //     console.log(`selected ${value}`);
    // }

    // 비밀번호 찾기 submit
    // function findPwSubmitEvent() {
    //     console.log("비밀번호 찾기");
    // }

    // 결과 - 비밀번호 없음 X
    function notFoundModalOpenEvent() {
        setNotFoundModalOpen(true);
    }
    function notFoundModalHandleCancel() {
        setNotFoundModalOpen(false);
    }
    function notFoundModalFindIdOnClickEvent() {
        // console.log("아이디 찾기");
        navigate("/authority/find-id");
    }
    function notFoundModalNaviJoinPageEvent() {
        // console.log("회원가입");
        navigate("/authority/join-membership");
    }

    // 결과 - 비밀번호 찾았음 O
    function foundResultModalOpenEvent() {
        setFoundResultModalOpen(true);
    }
    function foundResultModalHandleCancel() {
        setFoundResultModalOpen(false);
    }
    function foundResultModalCancelEvent() {
        // console.log("취소");
        setFoundResultModalOpen(false);
    }
    function foundResultModalPwChangeAlertEvent() {
        // console.log("비밀번호 변경");
        alert("비밀번호가 변경되었습니다. 로그인 해주세요.");
        setFoundResultModalOpen(false);
    }

    const modalClassNames = {
        mask: "findModalMask",
        content: "findModalContent",
        header: "findModalHeader",
        body: "findModalBody",
        footer: "findModalFooter",
    };

    const bodyContentData = (
        <Box className="findBodyContent">
            <Box className="contentDataWrap">
                <Box className="controlDataWrap">
                    <Flex justify="center" align="center">
                        <Box className="dataBox tabsFindIdPageButtonBox">
                            <VcAntButton
                                conceptType="clear"
                                buttonTitle={controlButtonDataGroup[0].label}
                                onClick={tabsButtonFindIdPageEvent}
                                boxStyle={{ width: "100%" }}
                                buttonStyle={{ width: "100%", borderColor: VcAntButtonTheme.borderColor }}
                            />
                        </Box>
                        <Box className="dataBox tabsFindPwPageButtonBox">
                            <VcAntButton
                                conceptType="concept"
                                buttonTitle={controlButtonDataGroup[1].label}
                                onClick={tabsButtonFindPwPageEvent}
                                boxStyle={{ width: "100%" }}
                                buttonStyle={{ width: "100%" }}
                            />
                        </Box>
                    </Flex>
                </Box>
                <Box className="mainDataBox">
                    <Flex vertical justify="flex-start" align="center">
                        <Box className="guideMessageBox">
                            <pre>
                                <Typography>{findPwGuideMessageDataGroup.message}</Typography>
                            </pre>
                        </Box>
                        <Box className="userInfoInputBox">
                            <Flex vertical justify="flex-start" align="center">
                                <Box className="userInfoRowBox">
                                    <Flex justify="space-between" align="center">
                                        <Box className="userInfoColBox userInfoTitleBox">
                                            <Typography>{formDataGroup.id.title}</Typography>
                                        </Box>
                                        <Box className="userInfoColBox userInfoContentBox">
                                            <Box className="idInputBox">
                                                <VcAntInput
                                                    chooseType="basic"
                                                    size="large"
                                                    placeholder={formDataGroup.id.placeholder}
                                                    onChange={handleUserIdChange}
                                                />
                                            </Box>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box className="userInfoRowBox">
                                    <Flex justify="space-between" align="center">
                                        <Box className="userInfoColBox userInfoTitleBox">
                                            <Typography>{formDataGroup.phone.title}</Typography>
                                        </Box>
                                        <Box className="userInfoColBox userInfoContentBox">
                                            <Box className="phoneNumberInputBox">
                                                <Flex justify="center" align="center">
                                                    <Box className="phoneNumberBox phoneStep1Box">
                                                        <VcAntSelect
                                                            className="phoneNumberSelect"
                                                            onChange={phoneNumberSelectOnChangeEvent}
                                                            options={formDataGroup.phone.option}
                                                            defaultValue="010"
                                                            dropdownStyle={{ textAlign: "center" }}
                                                            // suffixIcon={<CaretDownOutlined />}
                                                            fullWidth="100%"
                                                            size="large"
                                                        />
                                                    </Box>
                                                    <Box className="dashBox">
                                                        <Typography>-</Typography>
                                                    </Box>
                                                    <Box className="phoneNumberBox phoneStep2Box">
                                                        <VcAntInputNumber controls={false} min={0} max={9999} fullWidth="100%" size="large" 
                                            onChange={handlePhoneStep2Change}
                                            />
                                                    </Box>
                                                    <Box className="dashBox">
                                                        <Typography>-</Typography>
                                                    </Box>
                                                    <Box className="phoneNumberBox phoneStep3Box">
                                                        <VcAntInputNumber controls={false} min={0} max={9999} fullWidth="100%" size="large" 
                                                        onChange={handlePhoneStep3Change}
                                                        />
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                        <Box className="buttonGroupBox">
                            <Box className="buttonBox">
                                <VcAntButton
                                    conceptType="concept"
                                    buttonType="default"
                                    onClick={findPwSubmitEvent}
                                    buttonTitle="비밀번호 찾기"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Box>
    );

    const notFoundModalInnerDetailData = (
        <Box className="detailDataWrap">
            <Box className="detailHeaderBox">
                <Typography>{findPwModalDataGroup.modalTitle}</Typography>
            </Box>

            <Box className="detailBodyBox">
                <Box className="detailMainBox">
                    <Flex vertical justify="flex-start" align="center" gap="24px">
                        <Box className="messageBox">
                            <pre>
                                <Typography>{findPwModalDataGroup.notFound.message}</Typography>
                            </pre>
                        </Box>
                    </Flex>
                </Box>
                <Box className="detailFooterBox">
                    <Box className="footerButtonGroupBox">
                        <Flex justify="flex-start" align="center" gap="12px">
                            <Box className="footerButtonBox footerFindIdButton">
                                <VcAntButton
                                    conceptType="clear"
                                    buttonType="default"
                                    onClick={notFoundModalFindIdOnClickEvent}
                                    buttonTitle="아이디 찾기"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                            <Box className="footerButtonBox footerJoinButton">
                                <VcAntButton
                                    conceptType="concept"
                                    buttonType="default"
                                    onClick={notFoundModalNaviJoinPageEvent}
                                    buttonTitle="회원가입"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const foundResultModalInnerDetailData = (
        <Box className="detailDataWrap">
            <Box className="detailHeaderBox">
                <Typography>{findPwModalDataGroup.modalTitle}</Typography>
            </Box>

            <Box className="detailBodyBox">
                <Box className="detailMainBox">
                    <Flex vertical justify="flex-start" align="center" gap="24px">
                        <Box className="messageBox">
                            <pre>
                                <Typography>{findPwModalDataGroup.foundResult.message}</Typography>
                            </pre>
                        </Box>
                        <Box className="changeBox">
                            <Box className="changeFormGroup">
                                <Flex justify="space-between" align="center">
                                    <Box className="changeFormTitleBox">
                                        <Typography>{findPwModalDataGroup.foundResult.inputProp.changePwInput.label}</Typography>
                                    </Box>
                                    <Box className="changeFormInputBox">
                                        <VcAntInput
                                            chooseType="basic"
                                            placeholder={findPwModalDataGroup.foundResult.inputProp.changePwInput.placeHolder}
                                            size="large"
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                            <Box className="changeFormGroup">
                                <Flex justify="space-between" align="center">
                                    <Box className="changeFormTitleBox">
                                        <Typography>{findPwModalDataGroup.foundResult.inputProp.changePwReInput.label}</Typography>
                                    </Box>
                                    <Box className="changeFormInputBox">
                                        <VcAntInput
                                            chooseType="basic"
                                            placeholder={findPwModalDataGroup.foundResult.inputProp.changePwReInput.placeHolder}
                                            size="large"
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>

                <Box className="detailFooterBox">
                    <Box className="footerButtonGroupBox">
                        <Flex justify="flex-start" align="center" gap="12px">
                            <Box className="footerButtonBox footerLoginButton">
                                <VcAntButton
                                    conceptType="clear"
                                    buttonType="default"
                                    onClick={foundResultModalCancelEvent}
                                    buttonTitle="취소"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                            <Box className="footerButtonBox footerFindPwButton">
                                <VcAntButton
                                    conceptType="concept"
                                    buttonType="default"
                                    onClick={foundResultModalPwChangeAlertEvent}
                                    buttonTitle="비밀번호 변경"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box className="findPasswordWrap">
            {/* 비밀번호 찾기 */}
            <ContentCard topTitle={pageTitleData.findPassword} bodyContent={bodyContentData} />
            <Modal
                open={notFoundModalOpen}
                onCancel={notFoundModalHandleCancel}
                classNames={modalClassNames}
                wrapClassName="resultNotFoundModal"
                footer={null}
                maskClosable={false}
                centered={true}
                width="auto"
            >
                {notFoundModalInnerDetailData}
            </Modal>
            <Modal
                open={foundResultModalOpen}
                onCancel={foundResultModalHandleCancel}
                classNames={modalClassNames}
                wrapClassName="foundResultModal"
                footer={null}
                maskClosable={false}
                centered={true}
                width="auto"
            >
                {foundResultModalInnerDetailData}
            </Modal>
            <Box className="buttonGroupBox" sx={{ position: "fixed", bottom: 0, left: 0, boxSizing: "border-box", padding: "12px" }}>
                <Box sx={{ width: "100%", textAlign: "center", "& .ant-typography": { color: "white" } }}>
                    <Typography>임시 버튼 영역입니다. 추후 삭제 예정!</Typography>
                </Box>
                <Box className="buttonBox">
                    <Flex justify="center" align="center" gap="8px">
                        <VcAntButton
                            conceptType="clear"
                            buttonType="default"
                            onClick={notFoundModalOpenEvent}
                            buttonTitle="팝업 - 비밀번호 없음"
                            buttonSize="large"
                        />
                        <VcAntButton
                            conceptType="clear"
                            buttonType="default"
                            onClick={foundResultModalOpenEvent}
                            buttonTitle="팝업 - 비밀번호를 찾았습니다"
                            buttonSize="large"
                        />
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
}

export default FindPassword;
