import React, { useEffect, useState, useCallback } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";

import { Flex, Steps, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import ContentCard from "components/layout/contentCard";

import { pageTitleData, advertisingDataGroup } from "data/advertiser/advertising/main";

import "assets/scss/advertiser.scss";

import { VcAntButtonTheme } from "theme/component";

function Advertising() {
    const navigate = useNavigate();
    const location = useLocation();

    const advertisingData = advertisingDataGroup;
    const advertisingDataLength = advertisingData.length;

    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

    const items = advertisingData.map((data) => ({ key: data.key, title: data.label }));
    const itemsDataPathArray = advertisingData.map((data: any, index: number) => data.path);

    function currentLocationCheckEvent() {
        const currentLocation = location.pathname;
        const checkIndex = itemsDataPathArray.indexOf(currentLocation);        
        setCurrentPageIndex(checkIndex);
    }

    function next(event: any) {
        if (location.pathname === '/mypage/advertiser/campaign-management/advertising/target') {
            const targetComponent = document.querySelector('.buttonWrap');
            if (targetComponent) {
                const nextButton = document.querySelector('.target-next-button') as HTMLElement;
                nextButton?.click();
                return;
            }
        }else if (location.pathname === '/mypage/advertiser/campaign-management/advertising/kpi') {
            const targetComponent = document.querySelector('.buttonWrap');
            if (targetComponent) {
                const nextButton = document.querySelector('.kpi-next-button') as HTMLElement;
                nextButton?.click();
                return;
            }
        }else if (location.pathname === '/mypage/advertiser/campaign-management/advertising/campaign') {
            const targetComponent = document.querySelector('.buttonWrap');
            if (targetComponent) {
                const nextButton = document.querySelector('.campaign-next-button') as HTMLElement;
                nextButton?.click();
                return;
            }
        }else if (location.pathname === '/mypage/advertiser/campaign-management/advertising/media-list') {
            const targetComponent = document.querySelector('.buttonWrap');
            if (targetComponent) {
                const nextButton = document.querySelector('.media-next-button') as HTMLElement;
                nextButton?.click();
                return;
            }
        }else{
            navigate(itemsDataPathArray[currentPageIndex + 1]);
        }
    }

    function prev(event: any) {
        navigate(itemsDataPathArray[currentPageIndex - 1]);
    }

    useEffect(() => {
        currentLocationCheckEvent();
    }, [location]);

    const topBarStepData = (
        <Box className="progressWrap">
            <Box className="stepBox">
                <Steps className="site-navigation-steps" current={currentPageIndex} items={items} />
            </Box>
        </Box>
    );

    const buttonWrapSetStyles = {
        "& .ant-btn": {
            minWidth: "120px",
        },
    };

    const bodyContentData = (
        <Box className="advertisingBodyDataWrap">
            <Flex vertical justify="space-between" align="center" style={{ height: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Outlet />
                </Box>
                <Box style={{ width: "100%" }} className="buttonWrap" sx={buttonWrapSetStyles}>
                    <Box style={{ marginTop: 24 }}>
                        <Flex justify="space-between" align="center">
                            <Box>
                                {/* 처음만 */}
                                {currentPageIndex === 0 && (
                                    <VcAntButton
                                        conceptType="clear"
                                        buttonType="default"
                                        onClick={() => {
                                            console.log("나가기!");
                                        }}
                                        buttonTitle="나가기"
                                        buttonSize="large"
                                        icon={<LeftOutlined />}
                                        iconPosition="start"
                                    />
                                )}
                                {/* 중간 ~ 마지막 (처음 제외) */}
                                {currentPageIndex > 0 && (
                                    <VcAntButton
                                        conceptType="clear"
                                        buttonType="default"
                                        onClick={(event: any) => {
                                            prev(event);
                                        }}
                                        buttonTitle="이전"
                                        buttonSize="large"
                                        icon={<LeftOutlined />}
                                        iconPosition="start"
                                    />
                                )}
                            </Box>
                            <Box>
                                {/* 처음 ~ 중간 */}
                                {currentPageIndex < advertisingDataLength - 1 && (
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonType="default"
                                        onClick={(event: any) => {
                                            next(event);
                                        }}
                                        buttonTitle="다음"
                                        buttonSize="large"
                                        icon={<RightOutlined />}
                                        iconPosition="end"
                                    />
                                )}
                                {/* 마지막 만 */}
                                {currentPageIndex === advertisingDataLength - 1 && (
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonType="default"
                                        onClick={() => {
                                            console.log("완료!");
                                        }}
                                        buttonTitle="완료"
                                        buttonSize="large"
                                        icon={<RightOutlined />}
                                        iconPosition="end"
                                    />
                                )}
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );

    return (
        <Box className="advertisingWrap">
            {/* 광고하기 */}
            <ContentCard
                topTitle={pageTitleData}
                topBarContent={topBarStepData}
                bodyContent={bodyContentData}
            />
        </Box>
    );
}

export default Advertising;
