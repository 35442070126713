import React, { useState, PureComponent, useEffect } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import { ResponsiveContainer, BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import { mediaDetailCard5ChartDataGroup } from "data/advertiser/advertising/media";

import { colorGroup } from "theme/palette";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function MediaDetailCard5ChartFour({ className, style }: vcProps) {
    const setStyles = {
        "&": {},
        ...style,
    };

    const data: any = mediaDetailCard5ChartDataGroup.chart4;

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <Box className="customTooltip">
                    <Box
                        className="tooltipTitle"
                        sx={label === undefined ? { display: "none" } : { boxSizing: "border-box", paddingBottom: "4px" }}
                    >
                        <Typography>{label}세</Typography>
                    </Box>
                    <Box className="tooltipNameValue tooltipNameValueOne">
                        <Flex justify="flex-start" align="flex-end">
                            <Box className="tooltipName">
                                <Typography>{payload[0].name}</Typography>
                            </Box>
                            <Box className="tooltipSeparator">
                                <Typography>:</Typography>
                            </Box>
                            <Box className="tooltipValue">
                                <Typography>{payload[0].value}</Typography>
                            </Box>
                        </Flex>
                    </Box>
                    <Box className="tooltipNameValue tooltipNameValueTwo">
                        <Flex justify="flex-start" align="flex-end">
                            <Box className="tooltipName">
                                <Typography>{payload[1].name}</Typography>
                            </Box>
                            <Box className="tooltipSeparator">
                                <Typography>:</Typography>
                            </Box>
                            <Box className="tooltipValue">
                                <Typography>{payload[1].value}</Typography>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            );
        } else {
            return null;
        }
    };

    return (
        <Box className="mediaDetailCard5ChartFourWrap" sx={setStyles}>
            <Flex justify="center" align="center">
                <ResponsiveContainer width="100%" aspect={4.0 / 2.2}>
                    <BarChart width={500} height={300} data={data} margin={{ top: 24, right: 32, bottom: 16, left: -4 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            tickLine={false}
                            tickMargin={8}
                            padding={{ left: 16, right: 16 }}
                            axisLine={{ stroke: "#777", strokeWidth: 2 }}
                            strokeDasharray="3 3"
                        />
                        <YAxis
                            tickLine={false}
                            tickMargin={8}
                            // padding={{ top: 16, bottom: 16 }}
                            axisLine={{ stroke: "#777", strokeWidth: 2 }}
                            strokeDasharray="3 3"
                        />
                        <Tooltip content={<CustomTooltip />} cursor={false} />
                        <Legend
                            verticalAlign="top"
                            align="center"
                            wrapperStyle={{
                                paddingBottom: "8px",
                            }}
                        />
                        <Bar dataKey="남성" fill="rgba(130, 202, 157, 1)" />
                        <Bar dataKey="여성" fill="rgba(136, 132, 216, 1)" />
                    </BarChart>
                </ResponsiveContainer>
            </Flex>
        </Box>
    );
}

export default MediaDetailCard5ChartFour;
