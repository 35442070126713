import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie'; // 쿠키 사용을 위해 추가

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import "assets/scss/page/main.scss";

import { mainPageAdvertiserDataGroup } from "data/main/section";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function MainIndex({ className, style }: vcProps) {
    const navigate = useNavigate();

    useEffect(() => {
        // 로그인 상태 확인
        const isAuthenticated = Cookies.get('authority');
        
        if (!isAuthenticated) {
            navigate("/authority/login");
        }
    }, [navigate]);

    const setStyles = {
        "&": { color: "white" },
        ...style,
    };

    const targetData = mainPageAdvertiserDataGroup;

    return (
        <Box className="mainIndexBox" sx={setStyles}>
            {/* <Box>대시보드 삭제X</Box> */}
            {/* <Box>메인페이지 입니다.</Box> */}
            <Box className="mainIndexInnerBox">
                <Box className="contentSectionBox contentSectionOne">
                    <Box className="contentSectionInnerBox">
                        <Box className="backgroundImagebox">
                            <img src={targetData.section.one.backgroundImg} alt="" />
                        </Box>
                    </Box>
                </Box>
                <Box className="contentSectionBox contentSectionTwo">
                    <Box className="contentSectionInnerBox">
                        <Flex vertical justify="flex-start" align="center" gap="24px">
                            <Box className="titleBox">
                                <Typography>{targetData.section.two.title}</Typography>
                            </Box>
                            <Box className="descriptionBox">
                                <Flex vertical justify="flex-start" align="center">
                                    {targetData.section.two.description.map((data: any, index: number) => {
                                        return <Typography key={index}>{data}</Typography>;
                                    })}
                                </Flex>
                            </Box>
                            <Box className="bannerBox">
                                <Box className="bannerInnerBox">
                                    <Box className="bannerFlexBox">
                                        <Flex justify="center" align="center" gap="20px">
                                            {targetData.section.two.banner.map((data: any, index: number) => {
                                                return (
                                                    <Box className="bannerItemBox" key={index}>
                                                        <Box className="itemThumbnailBox">
                                                            <Box className="itemThumbnailInnerBox">
                                                                <img src={data.thumbnail} alt="" />
                                                            </Box>
                                                        </Box>
                                                        <Box className="itemInfoBox">
                                                            <Flex vertical justify="flex-start" align="flex-start" gap="6px">
                                                                <Box className="infoTitlebox">
                                                                    <Typography>{data.title}</Typography>
                                                                </Box>
                                                                <Box className="explainBox">
                                                                    {data.explain.map((eData: any, eIndex: number) => {
                                                                        return <Typography key={eIndex}>{eData}</Typography>;
                                                                    })}
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Flex>
                                    </Box>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                <Box className="contentSectionBox contentSectionThree">
                    <Box className="contentSectionInnerBox">
                        <Box className="backgroundImagebox">
                            <img src={targetData.section.three.backgroundImg} alt="" />
                        </Box>
                        <Box className="titleGroupBox">
                            <Box className="titleGroupInnerBox">
                                <Flex vertical justify="flex-start" align="center" gap="20px">
                                    <Box className="titleBox">
                                        <Typography>{targetData.section.three.title}</Typography>
                                    </Box>
                                    <Box className="descriptionBox">
                                        {targetData.section.three.description.map((data: any, index: number) => {
                                            return <Typography key={index}>{data}</Typography>;
                                        })}
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <Box className="bannerGroupBox">
                            <Box className="bannerGroupInnerBox">
                                <Flex vertical justify="flex-start" align="flex-start" gap="20px">
                                    <Box className="miniTitleBox">
                                        <Typography>{targetData.section.three.banner.miniTitle}</Typography>
                                    </Box>
                                    <Box className="bannerItemListBox">
                                        <Box className="bannerItemListInnerBox">
                                            <Flex vertical justify="flex-start" align="flex-start" gap="28px">
                                                {targetData.section.three.banner.itemList.map((data: any, index: number) => {
                                                    return (
                                                        <Box className="bannerItem" key={index}>
                                                            <Flex justify="flex-start" align="center" gap="16px">
                                                                <Box className="itemThumbnailBox">
                                                                    <Box className="itemThumbnailInnerBox">
                                                                        <img src={data.thumbnail} alt="" />
                                                                    </Box>
                                                                </Box>
                                                                <Box className="bannerItemInfoBox">
                                                                    <Box className="bannerItemInfoInnerBox">
                                                                        <Flex vertical justify="flex-start" align="flex-start" gap="6px">
                                                                            <Box className="bannerItemTitleBox">
                                                                                <Typography>{data.title}</Typography>
                                                                            </Box>
                                                                            <Box className="bannerItemExplainBox">
                                                                                {data.explain.map((eData: any, eIndex: number) => {
                                                                                    return <Typography key={eIndex}>{eData}</Typography>;
                                                                                })}
                                                                            </Box>
                                                                        </Flex>
                                                                    </Box>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    );
                                                })}
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="contentSectionBox contentSectionFour">
                    <Box className="contentSectionInnerBox">
                        <Flex vertical justify="flex-start" align="center" gap="24px">
                            <Box className="titleBox">
                                <Typography>{targetData.section.four.title}</Typography>
                            </Box>
                            <Box className="descriptionBox">
                                <Flex vertical justify="flex-start" align="center">
                                    {targetData.section.four.description.map((data: any, index: number) => {
                                        return <Typography key={index}>{data}</Typography>;
                                    })}
                                </Flex>
                            </Box>
                            <Box className="centerImageBox">
                                <Box className="centerImageInnerBox">
                                    <img src={targetData.section.four.centerImage} alt="" />
                                </Box>
                            </Box>
                            <Box className="explainListBox">
                                <Box className="explainListInnerBox">
                                    <Box className="explainItemBox">
                                        <Flex vertical justify="center" align="center" gap="6px">
                                            {targetData.section.four.explain.map((data: any, index: number) => {
                                                return <Typography key={index}>{data}</Typography>;
                                            })}
                                        </Flex>
                                    </Box>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default MainIndex;
