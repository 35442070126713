import React, { useEffect,useState } from "react";

import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";
import SectionCheckboxControl from "pages/mypage/common/sectionCheckboxControl";

import VcChangePasswordModal from "pages/mypage/common/changePasswordModal";

import { generalMenuItemsDataGroup } from "data/mypage/main";
import { generalUserProfileDataGroup } from "data/mypage/profile/generalUser";
import { profileCategoryCheckDataGroup, receiveAgreeDataGroup } from "data/mypage/common/receiveAgree";

import Cookies from 'js-cookie'; // 추가된 부분
import { useProfile } from 'hooks/useProfile';

function GeneralProfile() {
    const [changePwModalOpenState, setChangePwModalOpenState] = useState(false);
    const [userId, setUserId] = useState<string>('');

    useEffect(() => {
        const storedUserId = Cookies.get('userId');
        if (storedUserId) {
            setUserId(storedUserId);
        } else {
            console.error("사용자 ID가 쿠키에 존재하지 않습니다.");
        }
    }, []);
    
    const { userData, loading, error } = useProfile(userId); // userId는 string 타입
    
    if (!userId) {
        return <div>사용자 ID를 찾을 수 없습니다. 로그인 해주세요.</div>;
    }

    // modal open, close 이벤트
    // function changePwModalOpenEvent() {
    //     setChangePwModalOpenState(true);
    // }
    function changePwModalHandleCancel() {
        setChangePwModalOpenState(false);
    }

    const subButtonEventArray = [
        // 아이디
        { event: undefined },
        // 비밀번호
        {
            event: function passwordChangeEvent() {
                // console.log("비밀번호 변경하기");
                setChangePwModalOpenState(true);
            },
        },
        // 휴대폰
        {
            event: function sendAuthNumberEvent() {
                console.log("휴대폰 인증번호 전송");
            },
        },
        // 인증번호
        {
            event: function authNumberCheckEvent() {
                console.log("인증번호 확인");
            },
        },
    ];

    function modifyButtonEvent() {
        console.log("수정하기");
    }

    const userMergeData = [...generalUserProfileDataGroup];

    if (userData) {
        userMergeData[0].mainInput.value = userData.id;
        userMergeData[2].mainInput.value = userData.hp; 
    }

    function handleInputChange(id: string, value: any) {
        console.log(`Input changed: ${id} = ${value}`);
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <SectionFormControl categoryInputData={userMergeData} subButtonEventData={subButtonEventArray} onInputChange={handleInputChange} />
            <SectionCheckboxControl titleData={profileCategoryCheckDataGroup.title} contentData={receiveAgreeDataGroup} />
        </Box>
    );

    const topTitleData = generalMenuItemsDataGroup[0].label;

    return (
        <Box>
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifyButtonEvent}
                            buttonTitle="수정하기"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
            <VcChangePasswordModal open={changePwModalOpenState} onCancel={changePwModalHandleCancel} />
        </Box>
    );
}

export default GeneralProfile;
