import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import $ from "jquery";

import { Flex, Tabs, Typography } from "antd";
import type { TabsProps } from "antd";
import { PlusOutlined, SendOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import ContentCard from "components/layout/contentCard";
import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";

import {
  messageCommonDataGroup,
  myProfileData,
  messageDataGroup,
} from "data/mypage/common/message";

import { colorGroup } from "theme/palette";

import useFlagSerialData from "hooks/useFlagSerialData";
import { fetchMessageData } from "services/findMessage";
import { useCreateMessage } from "hooks/useCreateMessage";

interface vcProps {
  className?: string;
  style?: React.CSSProperties;
}

function CommonMessage({ className, style }: vcProps) {
  const [tabsState, setTabsState] = useState<string>("0");
  const [selectedRoomSerial, setSelectedRoomSerial] = useState<string>("");
  const [selectedReceiverSerial, setSelectedReceiverSerial] =
    useState<string>("");
  const [messageInput, setMessageInput] = useState<string>("");

  const {
    data: messageRoomListData,
    loading: messageRoomListLoading,
    error: messageRoomListError,
  } = useFlagSerialData(fetchMessageData, "list", "7"); //company_serial || '');

  const {
    data: messageListData,
    loading: messageListLoading,
    error: messageListError,
  } = useFlagSerialData(
    fetchMessageData,
    "room",
    selectedRoomSerial // 선택된 방의 시리얼 번호 전달
  );

  const {
    sendMessage,
    loading: sendMessageLoading,
    error: sendMessageError,
  } = useCreateMessage();

  // 시간 차이 계산 함수 추가
  const getTimeDifference = (regdate: string): string => {
    if (!regdate) return "";

    const now = new Date();
    const messageDate = new Date(regdate);
    const diffInMinutes = Math.floor(
      (now.getTime() - messageDate.getTime()) / (1000 * 60)
    );

    if (diffInMinutes < 1) return "방금 전";
    if (diffInMinutes < 60) return `${diffInMinutes}분 전`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours}시간 전`;

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays}일 전`;

    return messageDate.toLocaleDateString();
  };

  // 날짜 그룹화 함수 추가
  const groupMessagesByDate = (messages: any[]) => {
    const groups: { [key: string]: any[] } = {};

    messages.forEach((message) => {
      const date = new Date(message.regdate);
      const dateKey = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(message);
    });

    return groups;
  };

  // 메시지 전송 함수 수정
  const handleSendMessage = async () => {
    if (!messageInput.trim() || !selectedReceiverSerial) return;

    try {
      await sendMessage({
        sender_serial: "7", // 현재 사용자의 serial
        receiver_serial: selectedReceiverSerial,
        msg: messageInput,
      });

      setMessageInput(""); // 성공 시 입력창 초기화
      // 필요한 경우 메시지 목록 새로고침
    } catch (error) {
      // 에러 처리는 이미 훅에서 하고 있으므로 추가 처리가 필요한 경우에만 작성
      console.error("메시지 전송 실패:", error);
    }
  };

  const tabsOnChangeEvent = (key: string) => {
    setTabsState(key);
  };

  const messageButtonOnClickEvent = (event: any) => {
    const targetIndex = event.currentTarget.dataset.index;
    const roomSerial = messageRoomListData[targetIndex].message_room_serial;
    const receiverSerial = messageRoomListData[targetIndex].member_serial_2; // 상대방 시리얼 저장
    setSelectedRoomSerial(roomSerial);
    setSelectedReceiverSerial(receiverSerial);
    setTabsState(String(targetIndex));
  };

  const messageButtonBorderBoxSetStyle = {
    "&": {},
  };

  const itemsDataArray = messageListData?.map((data: any, index: number) => ({
    key: String(index),
    label: data.sender_name,
    children: (
      <Box className="messageViewBox">
        <Box className="messageViewInnerBox">
          <Flex vertical justify="flex-start" align="center">
            <Box className="topCompanyInfoBox">
              <Typography>{data.sender_name}</Typography>
            </Box>
            <Box className="messageScrollBox">
              <Box className="messageScrollInnerBox">
                {Object.entries(groupMessagesByDate(messageListData)).map(
                  ([dateKey, messages]) => {
                    const [year, month, day] = dateKey.split("-");
                    return (
                      <React.Fragment key={dateKey}>
                        <Box className="messageDateGroupTitleBox">
                          <Flex justify="center" align="center">
                            <Typography className="messageDateValue">
                              {year}
                            </Typography>
                            <Typography className="dateMiddlePeriod">
                              {messageCommonDataGroup.dateMiddlePeriod}
                            </Typography>
                            <Typography className="messageDateValue">
                              {String(month).padStart(2, "0")}
                            </Typography>
                            <Typography className="dateMiddlePeriod">
                              {messageCommonDataGroup.dateMiddlePeriod}
                            </Typography>
                            <Typography className="messageDateValue">
                              {String(day).padStart(2, "0")}
                            </Typography>
                          </Flex>
                        </Box>
                        <Box className="messageMainBox">
                          <Box className="messageMainInnerBox">
                            {messages.map((message: any) => {
                              const isCurrentUser =
                                message.sender_serial === "7";
                              return (
                                <Box
                                  className="msgBox"
                                  sx={{
                                    paddingTop: isCurrentUser ? "40px" : "16px",
                                  }}
                                  key={message.message_serial}
                                >
                                  <Flex
                                    justify="flex-start"
                                    align="flex-start"
                                    gap="12px"
                                  >
                                    {!isCurrentUser && (
                                      <Box className="msgLeftBox">
                                        <Box className="msgProfileBox">
                                          <img src="https://picsum.photos/200/200" alt="" />
                                        </Box>
                                      </Box>
                                    )}
                                    <Box
                                      className="msgRightBox"
                                      sx={{
                                        paddingTop: isCurrentUser ? 0 : "6px",
                                      }}
                                    >
                                      {!isCurrentUser && (
                                        <Box className="msgCompanyName">
                                          <Typography>
                                            {message.sender_name}
                                          </Typography>
                                        </Box>
                                      )}
                                      <Box
                                        className="msgSpeechBubbleBox"
                                        sx={{
                                          paddingTop: isCurrentUser ? 0 : "6px",
                                        }}
                                      >
                                        <Flex
                                          justify="flex-start"
                                          align="flex-end"
                                          gap="8px"
                                          style={{
                                            flexDirection: isCurrentUser
                                              ? "row-reverse"
                                              : "row",
                                          }}
                                        >
                                          <Box className="msgSpeechBubbleBorderBox">
                                            <Typography>
                                              {message.msg}
                                            </Typography>
                                          </Box>
                                          <Box className="msgDateBox">
                                            <Box className="msgDateTitle">
                                              <Flex
                                                justify="flex-start"
                                                align="center"
                                                gap="2px"
                                              >
                                                <Typography>
                                                  {getTimeDifference(
                                                    message.regdate
                                                  )}
                                                </Typography>
                                              </Flex>
                                            </Box>
                                          </Box>
                                        </Flex>
                                      </Box>
                                    </Box>
                                  </Flex>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  }
                )}
              </Box>
            </Box>
            <Box className="messageInputBox">
              <Box className="messageInputInnerBox">
                <Flex justify="flex-start" align="center" gap="8px">
                  <Box className="plusButtonBox">
                    <VcAntButton
                      iconPosition="start"
                      icon={<PlusOutlined />}
                      buttonType="default"
                      conceptType="clear"
                      onClick={() => {
                        console.log("플러스 버튼");
                      }}
                    />
                  </Box>
                  <Box className="textInputbox">
                    <VcAntInput
                      chooseType="basic"
                      placeholder="메세지를 입력하세요"
                      value={messageInput}
                      onChange={(e) => setMessageInput(e.target.value)}
                      onPressEnter={handleSendMessage}
                      disabled={sendMessageLoading}
                    />
                  </Box>
                  <Box className="sendButtonBox">
                    <VcAntButton
                      iconPosition="start"
                      icon={<SendOutlined />}
                      buttonType="default"
                      conceptType="clear"
                      onClick={handleSendMessage}
                      disabled={sendMessageLoading}
                    />
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    ),
  }));

  const bodyContentData = (
    <Box className="mypageBodyContent">
      <Box className="contentDataWrap">
        <Box className="messageLayoutBox">
          <Flex justify="space-between" align="flex-start" gap="8px">
            <Box className="leftBox">
              <Box className="messageButtonListBox">
                <Box className="messageButtonList">
                  <Flex
                    vertical
                    justify="flex-start"
                    align="flex-start"
                    gap="8px"
                  >
                    {messageRoomListData?.map((data: any, index: number) => {
                      const lastMessageRegdate =
                        messageListData?.length > 0
                          ? getTimeDifference(
                              messageListData[messageListData.length - 1]
                                .regdate
                            )
                          : "";
                      return (
                        <Box
                          className="messageButtonBorderBox"
                          sx={
                            tabsState === String(index)
                              ? {
                                  "& .messageButtonBox": {
                                    background:
                                      colorGroup.realColor.white.main[10],
                                    boxShadow:
                                      "0 0 12px " +
                                      colorGroup.realColor.white.main[70],
                                  },
                                }
                              : {}
                          }
                          key={data.message_room_serial}
                        >
                          <Box
                            className="messageButtonBox"
                            onClick={messageButtonOnClickEvent}
                            data-index={index}
                          >
                            <Box className="messageButton">
                              <Box className="messageButtonInner">
                                <Flex
                                  justify="flex-start"
                                  align="center"
                                  gap="12px"
                                >
                                  <Box className="messageButtonLeft">
                                    <Box className="companyProfileImg">
                                      <img src="https://picsum.photos/200/200" alt="" />
                                    </Box>
                                  </Box>
                                  <Box className="messageButtonRight">
                                    <Box className="companyNameBox">
                                      <Typography.Paragraph
                                        ellipsis={{ rows: 1 }}
                                      >
                                        {data.member_serial_2_name}
                                      </Typography.Paragraph>
                                    </Box>
                                    <Box className="companyDescriptionBox">
                                      <Typography.Paragraph
                                        ellipsis={{ rows: 2 }}
                                      >
                                        {data.member_serial_2_name}
                                      </Typography.Paragraph>
                                    </Box>
                                  </Box>
                                </Flex>
                              </Box>
                            </Box>
                            <Box className="lastUpdateTime">
                              <Flex justify="flex-start" align="center">
                                <Typography>{lastMessageRegdate}</Typography>
                              </Flex>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Flex>
                </Box>
              </Box>
            </Box>

            <Box className="rightBox">
              <Tabs
                defaultActiveKey="1"
                activeKey={tabsState}
                items={itemsDataArray}
                onChange={tabsOnChangeEvent}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );

  const setStyles = {
    "&": {
      color: "white",
    },
    ...style,
  };

  return (
    <Box className="commonMessageWrap" sx={setStyles}>
      {/* 메세지 */}
      <ContentCard topTitle="메세지" bodyContent={bodyContentData} />
    </Box>
  );
}

export default CommonMessage;
