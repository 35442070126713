import axios from 'axios';

const API_URL = '/api/';

/**
 * 캠페인 정보를 가져오는 함수
 * @param serial 회원 시리얼 번호
 * @returns AxiosPromise<any>
 */

export const fetchCampaignData = async (flag: string, serial: string) => {
    let payload;

    switch (flag) {
        case 'all':
            payload = { flag, member_serial: serial };
            break;
        case 'campains':
            payload = { flag, company_serial: serial };
            break;            
        default:
            payload = { flag, campain_serial: serial };
    }

    const response = await axios.post(`${API_URL}?cat=campain&req=read`, payload);
    return response;
};