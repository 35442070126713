import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    imgPath?: string;
    titleData?: string;
    description?: string;
    companySerial?: string;
}

function AdvertiserPartnerListLayout({ className, style, imgPath, titleData, description, companySerial }: vcProps) {
    const navigate = useNavigate();

    const detailPath = "/mypage/advertiser/partner-management/partner-name/detail";

    function partnerListDetailViewOnClickEvent() {
        navigate(detailPath + "/" + companySerial);
    }

    const setStyles = {
        "&": { cursor: "pointer" },
        ...style,
    };

    return (
        <Box className="advertiserPartnerListLayoutWrap" sx={setStyles} onClick={partnerListDetailViewOnClickEvent}>
            <Flex justify="flex-start" align="center">
                <Box className="imgBox">
                    <Box className="imgBoxInner">
                        <img src={imgPath} alt="" />
                    </Box>
                </Box>
                <Box className="informationBox">
                    <Box className="informationBoxInner">
                        <Flex justify="flex-start" align="center" style={{ width: "100%", height: "100%" }}>
                            <Box className="textBox">
                                <Box className="titleBox">
                                    <Typography>{titleData}</Typography>
                                </Box>
                                <Box className="descBox">
                                    <Typography>{description}</Typography>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
}

export default AdvertiserPartnerListLayout;
