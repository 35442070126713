import React, { useState, useEffect } from "react";

import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";
import SectionCheckboxControl from "pages/mypage/common/sectionCheckboxControl";

import VcChangePasswordModal from "pages/mypage/common/changePasswordModal";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { generalUserProfileDataGroup } from "data/mypage/profile/generalUser";
import { businessUserProfileDataGroup } from "data/mypage/profile/businessUser";
import {
    profileCategoryCheckDataGroup,
    receiveAgreeDataGroup,
} from "data/mypage/common/receiveAgree";

import useFetchCompanyData from 'hooks/useFetchCompanyData';

/**
 * 쿠키에서 특정 값을 가져오는 유틸리티 함수
 * @param name 쿠키 이름
 * @returns 쿠키 값
 */
const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
};

function AdvertiserProfile() {
    const [changePwModalOpenState, setChangePwModalOpenState] = useState(false);
    const [profileData, setProfileData] = useState(businessUserProfileDataGroup);

    // 테스트용 더미 데이터
    useEffect(() => {
        const dummyCompanyData = {
            name: "테스트 회사",
            owner: "홍길동",
            number: "123-45-67890",
            business_item: "서비스업",
            business_type: "소프트웨어 개발",
            city: "서울시 강남구",
            addr: "테헤란로 123",
            email: "test@company.com"
        };

        setProfileData(prevData => 
            prevData.map((item) => {
                switch(item.title) {
                    case "회사명":
                        return {
                            ...item,
                            mainInput: {
                                ...item.mainInput,
                                value: dummyCompanyData.name,
                            },
                        };
                    case "대표자명":
                        return {
                            ...item,
                            mainInput: {
                                ...item.mainInput,
                                value: dummyCompanyData.owner,
                            },
                        };
                    case "사업자등록번호":
                        return {
                            ...item,
                            mainInput: {
                                ...item.mainInput,
                                value: dummyCompanyData.number,
                            },
                        };
                    case "업태/업종":
                        return {
                            ...item,
                            mainInput: {
                                ...item.mainInput,
                                value: dummyCompanyData.business_item,
                            },
                            secondInput: {
                                ...item.secondInput,
                                value: dummyCompanyData.business_type,
                            },
                        };
                    case "주소":
                        return {
                            ...item,
                            mainInput: {
                                ...item.mainInput,
                                value: dummyCompanyData.city,
                            },
                            moreInput: {
                                ...item.moreInput,
                                value: dummyCompanyData.addr,
                            },
                        };
                    case "세금계산서 이메일":
                        return {
                            ...item,
                            mainInput: {
                                ...item.mainInput,
                                value: dummyCompanyData.email,
                            },
                        };
                    default:
                        return item;
                }
            })
        );
    }, []); // 컴포넌트 마운트 시 한 번만 실행

    function changePwModalOpenEvent() {
        setChangePwModalOpenState(true);
    }
    function changePwModalHandleCancel() {
        setChangePwModalOpenState(false);
    }

    function modifyButtonEvent() {
        console.log(profileData);
        console.log("수정하기");
    }

    const subButtonEventArray = [
        // 아이디
        { event: undefined },
        // 비밀번호
        {
            event: function passwordChangeEvent() {
                // console.log("비밀번호 변경하기");
                setChangePwModalOpenState(true);
            },
        },
        // 휴대폰
        {
            event: function sendAuthNumberEvent() {
                console.log("휴대폰 인증번호 전송");
            },
        },
        // 인증번호
        {
            event: function authNumberCheckEvent() {
                console.log("인증번호 확인");
            },
        },
        // 회사명
        { event: undefined },
        // 대표자명
        { event: undefined },
        // 사업자등록번호
        {
            event: function businessIdentificationNumberCheckEvent() {
                console.log("사업자등록번호 인증");
            },
        },
        // 업태업종
        { event: undefined },
        // 주소
        {
            event: function findPostcodeCheckEvent() {
                console.log("우편번호 찾기");
            },
        },
        // 세금계산서이메일
        { event: undefined },
    ];

    const userMergeData = [
        ...generalUserProfileDataGroup,
        ...profileData,
    ];

    function handleInputChange(id: string, value: any) {
        console.log(`Input changed: ${id} = ${value}`);
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <SectionFormControl
                categoryInputData={userMergeData}
                subButtonEventData={subButtonEventArray}
                onInputChange={handleInputChange}
            />
            <SectionCheckboxControl
                titleData={profileCategoryCheckDataGroup.title}
                contentData={receiveAgreeDataGroup}
            />
        </Box>
    );

    const topTitleData = advertiserMenuItemsDataGroup[0].label;

    return (
        <Box>
            <MypageSectionCard
                topTitle={topTitleData}
                bodyContent={bodyContentData}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifyButtonEvent}
                            buttonTitle="수정하기"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
            <VcChangePasswordModal
                open={changePwModalOpenState}
                onCancel={changePwModalHandleCancel}
            />
        </Box>
    );
}

export default AdvertiserProfile;
