export const notificationCommonDataGroup = {
    dateMiddlePeriod: ".",
    timeMiddleColon: ":",
};

export const notificationDataGroup = [
    {
        img: "https://picsum.photos/320/320",
        category: "이벤트",
        title: "Incididunt aliqua commodo consectetur ea aliquip.",
        reportingDate: { year: 2024, month: 2, day: 4, hour: 15, minute: 33 },
    },
    {
        img: "https://picsum.photos/322/322",
        category: "공지사항",
        title: "Anim adipisicing fugiat est est commodo.",
        reportingDate: { year: 2024, month: 2, day: 5, hour: 9, minute: 46 },
    },
    {
        img: "https://picsum.photos/324/324",
        category: "안내",
        title: "Quis ex labore fugiat pariatur eiusmod Lorem ipsum dolore.",
        reportingDate: { year: 2024, month: 2, day: 6, hour: 11, minute: 17 },
    },
    {
        img: "https://picsum.photos/326/326",
        category: "안내",
        title: "Aliqua anim tempor fugiat sunt culpa minim irure ut quis minim duis.",
        reportingDate: { year: 2024, month: 2, day: 7, hour: 13, minute: 52 },
    },
];
