import React, { useEffect, useState } from "react";

// import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntRadio from "components/antd/data-entry/radio";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import VcAntPaginationList from "components/custom/paginationList";
import AdvertiserContractListLayout from "./layout";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { partnerListDataGroup } from "data/mypage/advertiser/partner/list";
import {
  contractListDataGroup,
  contractFilterRadioData,
} from "data/mypage/advertiser/contract/list";

import type { RadioChangeEvent } from "antd";

import useFlagSerialData from "hooks/useFlagSerialData";
import { fetchContractData } from "services/findContract";
import { fetchMediaData } from "services/findMedia";
import { fetchCompanyData } from "services/findCompany";

/**
 * 쿠키에서 특정 값을 가져오는 유틸리티 함수
 * @param name 쿠키 이름
 * @returns 쿠키 값
 */
const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
};

interface vcProps {
  className?: string;
  style?: React.CSSProperties;
}

function AdvertiserContractList({ className, style }: vcProps) {
  const [postsState, setPostsState] = useState<any[]>([]);
  const [currentPageState, setCurrentPageState] = useState<number>(1);
  const [postsPerPageState, setPostsPerPageState] = useState<number>(10);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  // 원본 데이터를 저장할 새로운 state 추가
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [radioSelectState, setRadioSelectState] = useState("all"); // Radio State

  const member_serial = getCookie("serial") || "";
  const company_serial = getCookie("company_serial") || "";

  //데이터가 맞지않아 임의로 추가함

  const {
    data: ContractListData,
    loading: ContractListLoading,
    error: ContractListError,
  } = useFlagSerialData(fetchContractData, "me", "7"); //member_serial);
  const {
    data: MediaListData,
    loading: MediaListLoading,
    error: MediaListError,
  } = useFlagSerialData(fetchMediaData, "medias", "2");
  const {
    data: CompanyListData,
    loading: CompanyListLoading,
    error: CompanyListError,
  } = useFlagSerialData(fetchCompanyData, "my", "2"); //company_serial);

  useEffect(() => {
    if (ContractListData && MediaListData && CompanyListData) {
      const mappedData = ContractListData.map((item: any) => {
        // 해당 계약의 media_serial과 일치하는 미디어 찾기
        const mediaInfo = MediaListData.find(
          (media: any) => media.serial === item.media_serial
        );
        const mediaName = mediaInfo ? mediaInfo.name : "";

        const companyInfo = CompanyListData.find(
          (company: any) => company.serial === "2"
        ); //item.media_serial);
        const companyName = companyInfo ? companyInfo.name : "";

        return {
          imgPath: `https://picsum.photos/210/210?random=${item.serial}`,
          titleData: `${mediaName}`, // 미디어 이름과 ops 결합
          description: `${companyName}`,
          date: item.date,
          price: Number(item.amount).toLocaleString(),
          state: item.state,
          contractSerial: item.serial,
        };
      });
      setOriginalData(mappedData); // 원본 데이터 저장
      setPostsState(mappedData);
    }
  }, [ContractListData, MediaListData, CompanyListData, triggerRefresh]);

  function dataFullLoadEvent() {
    setTriggerRefresh((prev) => !prev);
  }

  function radioOnChangeEvent(event: RadioChangeEvent) {
    const targetValue = event.target.value;
    setRadioSelectState(targetValue);
    radioFilterEvent(targetValue);
  }

  // radioFilterEvent 함수 수정
  function radioFilterEvent(value: string) {
    setCurrentPageState(1);
    if (value === "all") {
      setPostsState(originalData);
    } else {
      const filteredData = originalData.filter(
        (element: any) => element.state === value
      );
      setPostsState(filteredData);
    }
  }

  // 회사명 필터 셀렉트박스 리스트
  const partnerListNameArray = partnerListDataGroup.map(
    (data: any, index: number) => data.companyName
  );

  const partnerListSelectData: any[] = [];
  partnerListNameArray.forEach((element, number) => {
    partnerListSelectData.push({
      label: element,
      value: "partnerName" + number,
    });
  });

  const finalData: any[] = [];
  postsState.forEach((element, number) => {
    finalData.push(
      <Box>
        <AdvertiserContractListLayout
          imgPath={element.imgPath}
          titleData={element.titleData}
          description={element.description}
          date={element.date}
          price={element.price}
          state={element.state}
          contractSerial={element.contractSerial}
        />
      </Box>
    );
  });

  const firstPostIndex = (currentPageState - 1) * postsPerPageState;
  const lastPostIndex = firstPostIndex + postsPerPageState;
  const currentPosts = finalData.slice(firstPostIndex, lastPostIndex);

  const bodyContentData = (
    <Box className="bodyContentDataBox">
      <Box className="listInformationBox">
        <Box>
          <VcAntRadio
            onChange={radioOnChangeEvent}
            value={radioSelectState}
            options={contractFilterRadioData}
            optionType="button"
            size="large"
            displayFlex={true}
          />
        </Box>
      </Box>
      <VcAntPaginationList
        listOptionData={currentPosts}
        postsItemCount={postsState.length}
        currentPage={currentPageState}
        setCurrentPage={setCurrentPageState}
        postsPerPage={postsPerPageState}
        itemGap={true}
      />
    </Box>
  );

  const topTitleData = advertiserMenuItemsDataGroup[4].label;

  return (
    <Box className="advertiserContractListWrap">
      <MypageSectionCard
        topTitle={topTitleData}
        bodyContent={bodyContentData}
      />
    </Box>
  );
}

export default AdvertiserContractList;
