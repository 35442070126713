import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";

import $ from "jquery";

import { Flex, Button, Typography, Dropdown } from "antd";
import { DownOutlined, MessageOutlined, BellOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import type { MenuProps } from "antd";


import Header from "components/layout/header";
import Footer from "components/layout/footer";

// import VcContainer from "components/layout/Container";

function MainLayout() {

    useEffect(() => {
        const headerWrapHeight = $("#headerWrapId").innerHeight();
        const paddingPlusValue = 24;
        $("#contentWrapId").css("padding-bottom", paddingPlusValue + "px");
        $("#contentWrapId").css("padding-top", Number(headerWrapHeight) + paddingPlusValue + "px");
    }, []);

    return (
        <Box className="mainLayout">
            <Header />
            <Box className="bodyWrap">
                <Box id="contentWrapId" className="contentWrap">
                    {/* <VcContainer fullWidth={true} fullHeight={true}> */}
                    <Outlet />
                    {/* </VcContainer> */}
                </Box>
            </Box>
            <Footer />
        </Box>
    );
}

export default MainLayout;
