import axios from "axios";

const API_URL = "/api/";

/**
 * 회사 정보를 가져오는 함수
 * @param serial 회원 시리얼 번호
 * @returns AxiosPromise<any>
 */

export const fetchMessageData = async (flag: string, serial: string) => {
  let payload;

  switch (flag) {
    case "list":
      payload = { flag, member_serial: serial };
      break;
    case "member":
      payload = {
        flag,
        member_serial: serial,
      };
      break;
    case "room":
      payload = {
        flag,
        message_room_serial: serial,
      };
      break;
    default:
      payload = { flag, member_serial: serial };
  }

  const response = await axios.post(`${API_URL}?cat=message&req=read`, payload);
  return response;
};
