import React, { useState, useEffect } from "react";

import { Flex, Progress, Typography } from "antd";

import { Box } from "@mui/material";

import { Height, WidthFull } from "@mui/icons-material";

function AdvertisingAiLoading() {
    const [loadingPercentValue, setLoadingPercentValue] = useState(0);
    const [descriptionText, setDescriptionText] = useState("AI 추천 중 입니다");

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingPercentValue((prevValue) => {
                if (prevValue >= 100) {
                    clearInterval(interval);
                    setDescriptionText("AI 추천이 완료되었습니다");
                    return 100;
                }
                return prevValue + 25;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const progressBarCode = (percent: number) => <Progress percent={percent} size="small" status="active" strokeColor="#173e77" />;

    return (
        <Box className="aiLoadingDataWrap">
            <Box className="percentBox">
                <Flex justify="center" align="flex-end">
                    <Box className="percentValue">
                        <Typography>{loadingPercentValue}</Typography>
                    </Box>
                    <Box className="percentUnit">
                        <Typography>%</Typography>
                    </Box>
                </Flex>
            </Box>
            <Box className="descriptionBox">
                <Box className="text">
                    <Typography>{descriptionText}</Typography>
                </Box>
            </Box>
            <Box className="progressBox">
                <Box className="progress">{progressBarCode(loadingPercentValue)}</Box>
            </Box>
        </Box>
    );
}

export default AdvertisingAiLoading;
