import React, { useState } from "react";

import { Flex, Typography, Modal, Space, Divider, Button } from "antd";

import { Box } from "@mui/material";

import VcAntModal from "components/antd/feedback/modal";

import mapImageSample from "assets/images/sample/map_img.jpg";

import ChartComp from "./chart";
import MediaDetailView from "./detail";

import { unitDataGroup, mediaDataGroup } from "data/advertiser/advertising/media";
import { fetchMediaData } from "services/findMedia";
import useFlagSerialData from "hooks/useFlagSerialData";
import Cookies from "js-cookie";

function AdvertisingMedia() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);

    const company_serial = Cookies.get('company_serial');
    
    function listDetailEvent(index: number) {
        setIsModalOpen(true);
        setSelectedMediaIndex(index);
    }

    function mediaModalHandleOk() {
        setIsModalOpen(false);
    }

    function mediaModalHandleCancel() {
        setIsModalOpen(false);
    }

    const classNames = {
        mask: "mediaDetailModalMask",
        content: "mediaDetailModalContent",
        header: "mediaDetailModalHeader",
        body: "mediaDetailModalBody",
        footer: "mediaDetailModalFooter",
    };
    const {
        data: MediaListData,
        loading: MediaListLoading,
        error: MediaListError,
    } = useFlagSerialData(fetchMediaData, "medias", company_serial || "");

    // mediaDataGroup과 MediaListData를 결합
    const combinedMediaData = React.useMemo(() => {
        if (!MediaListData) return mediaDataGroup;
        
        return mediaDataGroup.map((item, index) => {
            const apiData = MediaListData[index];
            if (!apiData) return item;

            return {
                ...item,
                information: {
                    ...item.information,
                    name: apiData.name,
                    serial: apiData.serial,
                    operate_hour: apiData.operate_hour,
                    infoSize: apiData.standard, // standard 정보를 character에 매핑
                }
            };
        });
    }, [MediaListData]);

    // 'Next' 버튼 클릭 시 로컬 스토리지에 데이터 저장
    const handleNext = () => {
       console.log('media next');
    };

    return (
        <Box className="MediaDataWrap">
            <Box className="mapBox">
                <Box className="mapContent">
                    <img src={mapImageSample} alt="맵이미지" />
                </Box>
            </Box>
            <Box className="listBox">
                <ul>
                    {combinedMediaData.map((data: any, index: number) => {
                        return (
                            <li key={index}>
                                <Box className="mediaContent">
                                    <span className="mediaClickWrap" onClick={() => listDetailEvent(data.information.serial)}>
                                        <Box className="number">{String(data.number).padStart(2, "0")}</Box>
                                        <Flex vertical justify="flex-start" align="flex-start">
                                            <Box className="imgChartBox">
                                                <Flex justify="space-between" align="flex-start" style={{ width: "100%" }}>
                                                    <Box className="imgBox">
                                                        <img src={data.bannerImg} alt="배너이미지" />
                                                    </Box>
                                                    <Box className="chartBox">
                                                        <ChartComp chartBoxWidth={220} chartBoxHeight={150} chartSize={300} />
                                                    </Box>
                                                </Flex>
                                            </Box>
                                            <Box className="mediaInfo">
                                                <Box className="infoLine1">
                                                    <Box className="infoPrice">
                                                        <Flex justify="flex-start" align="flex-end">
                                                            <Typography className="priceDateUnit">
                                                                {unitDataGroup.price.dateUnit}
                                                            </Typography>
                                                            <Typography className="priceValue">
                                                                {data.price.value.toLocaleString("ko-KR")}
                                                            </Typography>
                                                            <Typography className="priceValueUnit">
                                                                {unitDataGroup.price.valueUnit}
                                                            </Typography>
                                                            <Typography className="priceEtc">{unitDataGroup.price.priceEtc}</Typography>
                                                        </Flex>
                                                    </Box>
                                                </Box>
                                                <Box className="infoLine2">
                                                    <Box className="infoTitle">
                                                        <Typography>{data.information.name}</Typography>
                                                    </Box>
                                                    <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                                                        <Box className="infoCharacter">
                                                            <Typography>{data.information.character}</Typography>
                                                        </Box>
                                                        <Box className="infoSize">
                                                            <Typography>
                                                                {data.information.infoSize}
                                                            </Typography>
                                                        </Box>
                                                    </Space>
                                                </Box>
                                                <Box className="mediaMatching">
                                                    <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                                                        <Box className="matchingTitle">
                                                            <Typography>{unitDataGroup.matching.percentTitle}</Typography>
                                                        </Box>
                                                        <Box className="matchingPercent">
                                                            <Flex justify="flex-start" align="flex-end">
                                                                <Box className="matchingPercentValue">
                                                                    <Typography>{data.matching.percentValue}</Typography>
                                                                </Box>
                                                                <Box className="matchingPercentUnit">
                                                                    <Typography>{unitDataGroup.matching.percentUnit}</Typography>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    </Space>
                                                </Box>
                                            </Box>
                                        </Flex>
                                    </span>
                                </Box>
                            </li>
                        );
                    })}
                </ul>
            </Box>
            <VcAntModal
                open={isModalOpen}
                onOk={mediaModalHandleOk}
                onCancel={mediaModalHandleCancel}
                classNames={classNames}
                wrapClassName="listDetailModal"
                footer={null}
                maskClosable={false}
                centered={true}
                width="auto"
                mainContent={<MediaDetailView selectedIndex={selectedMediaIndex} />}
            />
            <Button 
                id="media-next-button" 
                className="media-next-button" 
                color="primary" 
                style={{ display: 'none' }}  // sx 대신 style 사용
                onClick={handleNext}
            >
                다음
            </Button>
        </Box>

    );
}

export default AdvertisingMedia;
