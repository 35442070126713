import { useState } from 'react';
import { campaignData, materialData, campaignService } from '../services/campaignService';

export const useCampaign = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const createCampaign = async ( campaignData: campaignData) => {
        setIsLoading(true);
        setError(null);
        
        try {
            const result = await campaignService.createCampaign(campaignData);
            return result;
          } catch (err) {
            setError(err instanceof Error ? err.message : '캠페인 생성에서 오류가 발생했습니다.');
            throw err;
          } finally {
            setIsLoading(false);
          }
    };

    const createMaterial = async (materialData: materialData) => {
        setIsLoading(true);
        setError(null);

        try {
            const result = await campaignService.createMaterial(materialData);
            return result;
        } catch (err) {
            setError(err instanceof Error ? err.message : '매체 생성에서 오류가 발생했습니다.');
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const getCampaign = async (flag: string, serial: string) => {
        setIsLoading(true);
        setError(null);
        
        try {
            const result = await campaignService.getCampaign(flag, serial);
            return result;
          } catch (err) {
            setError(err instanceof Error ? err.message : '캠페인 생성에서 오류가 발생했습니다.');
            throw err;
          } finally {
            setIsLoading(false);
          }
    };

    return {
        isLoading,
        error,
        createCampaign,
        createMaterial,
        getCampaign,
    };
};