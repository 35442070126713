import { useState } from "react";
import { findPassword } from "../services/findPasswordService";

interface FindPasswordResponse {
    answer: string;
    passwd: string;
}

export const useFindPassword = () => {
    const [userId, setUserId]         = useState("");
    const [phoneStep1, setPhoneStep1] = useState("010");
    const [phoneStep2, setPhoneStep2] = useState("");
    const [phoneStep3, setPhoneStep3] = useState("");

    const [notFoundModalOpen, setNotFoundModalOpen] = useState(false);
    const [foundResultModalOpen, setFoundResultModalOpen] = useState(false);
    const [foundPassword, setFoundPassword] = useState("");

    const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserId(e.target.value);
    };
    
    const phoneNumberSelectOnChangeEvent = (value: string) => {
        setPhoneStep1(value);
    };

    const handlePhoneStep2Change = (value: number) => {
        setPhoneStep2(value.toString());
    };

    const handlePhoneStep3Change = (value: number) => {
        setPhoneStep3(value.toString());
    };


    const findPwSubmitEvent = async () => {
        if (!userId || userId.trim() === '') {
            alert('아이디를 입력해주세요!.');
            return;
        }

        if (!phoneStep2 || !phoneStep3) {
            alert('전화번호를 입력해주세요.');
            return;
        }

        const phoneNumber = `${phoneStep1}-${phoneStep2}-${phoneStep3}`;
        try {
            const data = await findPassword(userId, phoneNumber);
            if (data.length > 0 && data[0].answer === "SUCCESS") {
                alert("변경된 비밀번호는 " + data[0].passwd + " 입니다.");
                //setFoundResultModalOpen(true);                
            } else {
                setNotFoundModalOpen(true);
            }
        } catch (error) {
            setNotFoundModalOpen(true);
        }
    };

    return {
        phoneStep1,
        phoneStep2,
        phoneStep3,
        userId,
        notFoundModalOpen,
        foundResultModalOpen,
        foundPassword,        
        handleUserIdChange,
        phoneNumberSelectOnChangeEvent,
        handlePhoneStep2Change,
        handlePhoneStep3Change,
        findPwSubmitEvent,
        setNotFoundModalOpen,
        setFoundResultModalOpen,        
    };
};