import React, { useEffect, useState } from "react";

import { Col, Row, Typography, Flex, Space, Divider,message } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import type { DatePickerProps } from "antd";

import { useNavigate } from "react-router-dom";

import { Box,} from "@mui/material";

import VcAntSelect from "components/antd/data-entry/select";
import VcAntDatePicker from "components/antd/data-entry/datePicker";
import VcAntButton from "components/antd/general/button";

import {
    unitDataGroup,
    mediaDataGroup,
    mediaDetailCardTitleDataGroup,
    mediaDetailChartCardTitleDataGroup,
    mediaDetailCard3TableThDataGroup,
    mediaDetailCard3TableTdValueDataGroup,
    mediaDetailCard3TableTdUnitDataGroup,
    mediaDetailCard4TableThDataGroup,
} from "data/advertiser/advertising/media";

import ChartComp from "../chart";

import MediaDetailCard5ChartOne from "./chartList/chart1";
import MediaDetailCard5ChartTwo from "./chartList/chart2";
import MediaDetailCard5ChartThree from "./chartList/chart3";
import MediaDetailCard5ChartFour from "./chartList/chart4";
import MediaDetailCard5ChartFive from "./chartList/chart5";
import MediaDetailCard5ChartSix from "./chartList/chart6";
import MediaDetailCard5ChartSeven from "./chartList/chart7";

import moment from "moment";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { colorGroup } from "theme/palette";

import Cookies from "js-cookie";
import { fetchMediaData } from "services/findMedia";
import useFlagSerialData from "hooks/useFlagSerialData";
import { useBooking } from "hooks/useBooking";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY/MM/DD";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";

/** Manually entering any of the following formats will perform date parsing */
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

const customFormat: DatePickerProps["format"] = (value) => `custom format: ${value.format(dateFormat)}`;

const customWeekStartEndFormat: DatePickerProps["format"] = (value) =>
    `${dayjs(value).startOf("week").format(dateFormat)} ~ ${dayjs(value).endOf("week").format(dateFormat)} ${dayjs(value)}`;

interface MediaDetailViewProps {
    selectedIndex: number;
}

function MediaDetailView({ selectedIndex }: MediaDetailViewProps) {
    const [bookingInfoTableSelectOneState, setBookingInfoTableSelectOneState] = useState<number>(1);
    const [bookingInfoTableSelectThreeState, setBookingInfoTableSelectThreeState] = useState<number>(1);

    const [bookingInfoTableSelectFourPriceState, setBookingInfoTableSelectFourPriceState] = useState<Number>(15000000);

    const [card3CategoryAllDataArrayState, setCard3CategoryAllDataArrayState] = useState<any[]>([]);
    const [card4CategoryAllDataArrayState, setCard4CategoryAllDataArrayState] = useState<any[]>([]);

    const { registerBooking, isLoading, error } = useBooking();

    const navigate = useNavigate();

    const company_serial = Cookies.get('company_serial');
    const {
        data: MediaListData,
        loading: MediaListLoading,
        error: MediaListError,
    } = useFlagSerialData(fetchMediaData, "medias", company_serial || "");

    const selectedMediaData = MediaListData?.find((media: any) => media.serial === String(selectedIndex));

    useEffect(() => {
        // 카드3
        const card3TargetDataTitleArray = mediaDetailCard3TableThDataGroup.title.map((data: any, index: number) => data);
        const card3TargetDataTitleArrayLength = card3TargetDataTitleArray.length;
        const card3TargetDataEmptyArray = [...Array(card3TargetDataTitleArrayLength)];

        const card3TargetAllDataArray = [];
        for (let i = 0; i < card3TargetDataTitleArrayLength; i++) {
            card3TargetDataEmptyArray[i] = Array.from(card3TargetDataTitleArray[i]);
            card3TargetAllDataArray[i] = { title: card3TargetDataTitleArray[i], breakTitle: card3TargetDataEmptyArray[i] };
        }
        setCard3CategoryAllDataArrayState(card3TargetAllDataArray);

        // 카드4
        const card4TargetDataTitleArray = mediaDetailCard4TableThDataGroup.map((data: any, index: number) => data.title);
        const card4TargetDataTitleArrayLength = card4TargetDataTitleArray.length;
        const card4TargetDataEmptyArray = [...Array(card4TargetDataTitleArrayLength)];

        const card4TargetAllDataArray = [];
        for (let i = 0; i < card4TargetDataTitleArrayLength; i++) {
            card4TargetDataEmptyArray[i] = Array.from(card4TargetDataTitleArray[i]);
            card4TargetAllDataArray[i] = { title: card4TargetDataTitleArray[i], breakTitle: card4TargetDataEmptyArray[i] };
        }
        setCard4CategoryAllDataArrayState(card4TargetAllDataArray);
    }, []);

    const dataTarget = mediaDataGroup[0];

    const contentOne = (
        <Box className="content contentOneBox">
            <Box className="slideBox">
                <Flex justify="flex-start" align="flex-start" gap="12px">
                    <Box className="slideImgViewBox">
                        <img src={dataTarget.bannerImg} alt="" />
                    </Box>
                    <Box className="slideScrollBox">
                        <Box className="slideUl">
                            <Box className="slideLi">
                                <Box className="slideItemBox">
                                    <img src={dataTarget.bannerImg} alt="" />
                                </Box>
                            </Box>
                            <Box className="slideLi">
                                <Box className="slideItemBox">
                                    <img src={dataTarget.bannerImg} alt="" />
                                </Box>
                            </Box>
                            <Box className="slideLi">
                                <Box className="slideItemBox">
                                    <img src={dataTarget.bannerImg} alt="" />
                                </Box>
                            </Box>
                            <Box className="slideLi">
                                <Box className="slideItemBox">
                                    <img src={dataTarget.bannerImg} alt="" />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );

    const contentTwo = (
        <Box className="content contentTwoBox">
            <Box className="matchingDataBox">
                <Box className="mediaMatching">
                    <Box className="mediaMatchingInner">
                        {/* <Box className="matchingTitle">
                            <Typography>{unitDataGroup.matching.percentTitle}</Typography>
                        </Box> */}
                        <Box className="matchingPercent">
                            <Box className="matchingPercentValue">
                                <Typography>{dataTarget.matching.percentValue}</Typography>
                            </Box>
                            <Box className="matchingPercentUnit">
                                <Typography>{unitDataGroup.matching.percentUnit}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="matchingChart">
                    <ChartComp chartBoxWidth={250} chartBoxHeight={155} chartSize={320} />
                </Box>
            </Box>
            <Box className="matchingResultDataBox">
                <Box className="matchingResultTitle">
                    <Typography>매칭 결과 내용</Typography>
                </Box>
                <Box className="matchingResultText">
                    <Typography>
                        Anim do eu cillum pariatur veniam aute. Ad irure magna excepteur deserunt veniam id excepteur exercitation eu velit
                        eu officia duis sint. Deserunt ut sunt do voluptate ut ea eu culpa. Deserunt tempor mollit fugiat fugiat mollit
                        laborum quis ipsum. Qui incididunt sit qui anim id dolor voluptate adipisicing Lorem enim. Fugiat magna anim
                        excepteur sit ad id exercitation sunt ad ea. Deserunt laboris consectetur do in ipsum consectetur magna id sit. Sunt
                        nostrud reprehenderit et excepteur ipsum ullamco id. Quis in mollit nisi fugiat voluptate cupidatat culpa fugiat
                        deserunt.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    const startTimeFormat = moment().format("YYYY-MM-DD " + mediaDetailCard3TableTdValueDataGroup[6].startTime + ":00:00");
    const startTimeValue = moment(startTimeFormat).hour();
    const endTimeFormat = moment().format("YYYY-MM-DD " + mediaDetailCard3TableTdValueDataGroup[6].endTime + ":00:00");
    const endTimeValue = moment(endTimeFormat).hour();
    const timeInterval = moment(endTimeFormat).diff(startTimeFormat, "hour");
    
    const contentThree = (
        <Box className="content contentThreeBox">
            <Box className="mediaInfoBox">
                <Box className="mediaDescription">
                    <Typography>
                        Ad commodo enim commodo quis eu incididunt voluptate excepteur laboris esse ad excepteur proident aliqua.
                        Adipisicing quis eiusmod id deserunt laboris. Enim dolore exercitation ut magna labore dolore laboris ullamco tempor
                        nulla deserunt veniam. Veniam dolore minim officia enim aliquip irure ea aute consequat id ipsum dolore tempor
                        nulla. Aliquip consectetur sint proident id quis officia et nisi velit. Ipsum eiusmod ea nisi exercitation officia
                        exercitation qui. Qui labore duis anim amet nostrud nostrud cillum fugiat commodo. Non quis excepteur dolore sit
                        laboris commodo ex. Sit culpa sit occaecat reprehenderit fugiat deserunt tempor amet exercitation. Fugiat esse culpa
                        enim laborum culpa.
                    </Typography>
                </Box>
                <Box className="mediaTableBox">
                    {card3CategoryAllDataArrayState.map((data: any, index: number) => {
                        const card3SubBreakTitle = data.breakTitle;
                        return (
                            <Box className="infoTableRow" key={index}>
                                <Box className="infoTableCol infoTableTh">
                                    <Box className="infoTableThBorderBox">
                                        <Flex justify="space-between" align="center">
                                            {card3SubBreakTitle.map((data: any, wIndex: number) => {
                                                return <Typography key={wIndex}>{data}</Typography>;
                                            })}
                                        </Flex>
                                    </Box>
                                </Box>
                                <Box className="infoTableCol infoTableTd">
                                    {index === 1 ? (
                                        <Typography>{dataTarget.information.character}</Typography>
                                    ) : index === 5 ? (
                                        <Box>
                                            <Flex>
                                                <Typography>
                                                    {selectedMediaData ? selectedMediaData.standard : "사이즈 정보가 없습니다."}
                                                </Typography>
                                            </Flex>
                                        </Box>
                                    ) : index === 6 ? (
                                        <Box>
                                            <Flex>
                                                <Typography>{selectedMediaData ? selectedMediaData.operate_hour : "운영시간 정보가 없습니다."}</Typography>
                                            </Flex>
                                        </Box>
                                    ) : (
                                        // <Typography>{dataTarget.information.size.displayHorizontalValue}</Typography>
                                        <Typography>{mediaDetailCard3TableTdValueDataGroup[index]}</Typography>
                                    )}

                                    {/* "6시 ~ 24시 (일 18시간)" */}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );

    function bookInfoTableSelectOneOnChangeEvent(value: any) {
        setBookingInfoTableSelectOneState(value);
    }
    function bookInfoTableSelectThreeOnChangeEvent(value: any) {
        setBookingInfoTableSelectThreeState(value);
    }

    const mediaDetailBookingInfoTableDataContentGroup: any = [
        {
            index: 1,
            content: (
                <Box>
                    <VcAntSelect
                        placeholder="옵션을 선택해주세요"
                        options={[
                            { label: "30초 소재, 1일 50회 이상", value: 1 },
                            { label: "30초 소재, 1일 100회 이상", value: 2 },
                            { label: "30초 소재, 1일 150회 이상", value: 3 },
                            { label: "30초 소재, 1일 200회 이상", value: 4 },
                        ]}
                        value={bookingInfoTableSelectOneState}
                        onChange={bookInfoTableSelectOneOnChangeEvent}
                        dropdownStyle={{
                            textAlign: "center",
                        }}
                        suffixIcon={<CaretDownOutlined />}
                        fullWidth="100%"
                    />
                </Box>
            ),
        },
        {
            index: 2,
            content: (
                <Box>
                    <VcAntDatePicker
                        chooseType="rangePicker"
                        popupClassName="mediaDetailDatePicker"
                        separator={<Typography style={{ color: colorGroup.realColor.black.main[30] }}>~</Typography>}
                        fullWidth="100%"
                    />
                </Box>
            ),
        },
        {
            index: 3,
            content: (
                <Box>
                    <VcAntSelect
                        placeholder="옵션을 선택해주세요"
                        options={[
                            { label: "1구좌", value: 1 },
                            { label: "2구좌", value: 2 },
                            { label: "3구좌", value: 3 },
                            { label: "4구좌", value: 4 },
                        ]}
                        value={bookingInfoTableSelectThreeState}
                        onChange={bookInfoTableSelectThreeOnChangeEvent}
                        dropdownStyle={{
                            textAlign: "center",
                        }}
                        suffixIcon={<CaretDownOutlined />}
                        fullWidth="100%"
                    />
                </Box>
            ),
        },
        {
            index: 4,
            content: (
                <Box>
                    <Box className="bookingInfoPrice">
                        <Flex justify="flex-end" align="flex-end">
                            <Typography className="priceValue">{bookingInfoTableSelectFourPriceState.toLocaleString("ko-KR")}</Typography>
                            <Typography className="priceValueUnit">{unitDataGroup.price.valueUnit}</Typography>
                            <Typography className="priceEtc">{unitDataGroup.price.priceEtc}</Typography>
                        </Flex>
                    </Box>
                </Box>
            ),
        },
    ];

    const handleBookingClick = async () => {
        console.log('선택된 미디어 인덱스:', selectedIndex);
        try {
            const result =await registerBooking(selectedIndex);
            if (result === true) { // result가 true인지 직접 확인
                console.log('가입 성공:', result);
                navigate("../select-media-name/booking-request-complete");
              } else {
                console.log('가입 실패:', result);
                message.error(result);
              }        
        } catch (error) {
            // 에러 처리
            console.error('부킹 요청 실패:', error);
        }
    };

    const contentFour = (
        <Box className="content contentFourBox">
            <Flex vertical justify="flex-start" align="flex-start" gap="32px">
                <Box className="bookingInformationBox">
                    <Box className="bookingInfoTableBox">
                        {card4CategoryAllDataArrayState.map((data: any, index: number) => {
                            const card4SubBreakTitle = data.breakTitle;
                            return (
                                <Box className="bookInfoTableRow bookInfoTableTr" key={index}>
                                    <Flex justify="flex-start" align="center" gap="40px">
                                        <Box className="bookInfoTableCol bookInfoTableTh">
                                            <Flex justify="flex-start" align="flex-end" gap="8px">
                                                <Box className="numberBox">
                                                    <Typography>{String(index + 1).padStart(2, "0")}</Typography>
                                                </Box>

                                                <Box className="titleBox">
                                                    <Flex justify="space-between" align="center">
                                                        {card4SubBreakTitle.map((data: any, wIndex: number) => {
                                                            return <Typography key={wIndex}>{data}</Typography>;
                                                        })}
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box className="bookInfoTableCol bookInfoTableTd">
                                            <Box className="SelectBox">{mediaDetailBookingInfoTableDataContentGroup[index].content}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Box className="bookingButtonBox">
                    <Flex justify="flex-start" align="center" gap="16px">
                        <Box className="3dSimulationBox">
                            <VcAntButton buttonTitle="DT시뮬레이션" buttonType="default" conceptType="concept" />
                        </Box>
                        <Box className="bookingRequestBox">
                            <VcAntButton 
                                buttonTitle="부킹신청" 
                                buttonType="default" 
                                conceptType="concept" 
                                onClick={handleBookingClick}
                            />
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );

    const contentFiveData = [
        { idx: 1, title: mediaDetailChartCardTitleDataGroup[0], content: <MediaDetailCard5ChartOne /> },
        { idx: 2, title: mediaDetailChartCardTitleDataGroup[1], content: <MediaDetailCard5ChartTwo /> },
        { idx: 3, title: mediaDetailChartCardTitleDataGroup[2], content: <MediaDetailCard5ChartThree /> },
        { idx: 4, title: mediaDetailChartCardTitleDataGroup[3], content: <MediaDetailCard5ChartFour /> },
        { idx: 5, title: mediaDetailChartCardTitleDataGroup[4], content: <MediaDetailCard5ChartFive /> },
        { idx: 6, title: mediaDetailChartCardTitleDataGroup[5], content: <MediaDetailCard5ChartSix /> },
        { idx: 7, title: mediaDetailChartCardTitleDataGroup[6], content: <MediaDetailCard5ChartSeven /> },
    ];

    const contentFive = (
        <Box className="content contentFiveBox">
            <Box>
                <Box className="gridTableWrap">
                    <Box className="gridTable">
                        <Box className="gridRow gridTr">
                            <Flex className="gridRowFlex" justify="center" align="center" gap="16px">
                                {contentFiveData.map((data: any, index: number) => {
                                    return (
                                        <Box className="gridCol gridTd" key={index}>
                                            <Box className="gridInner">
                                                <Box className="miniTitleBox">
                                                    <Typography>{data.title}</Typography>
                                                </Box>
                                                <Box className="chartBox">{data.content}</Box>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const titleData = [
        { index: 1, title: mediaDetailCardTitleDataGroup.title[0], content: contentOne },
        { index: 2, title: mediaDetailCardTitleDataGroup.title[1], content: contentTwo },
        { index: 3, title: selectedMediaData ? selectedMediaData.name : "선택된 미디어가 없습니다.", content: contentThree },
        { index: 4, title: mediaDetailCardTitleDataGroup.title[3], content: contentFour },
        { index: 5, title: mediaDetailCardTitleDataGroup.title[4], content: contentFive },
    ];

    return (
        <Box className="mediaDetailWrap">
            <Row gutter={[16, 16]} justify="center" align="top">
                {titleData.map((data: any, index: number) => {
                    return (
                        <Col span={index === 4 ? 24 : 12} key={index}>
                            <Box className="detailContentBox">
                                <Box className="detailContentBoxInner">
                                    <Box className="contentMain">
                                        <Box className="number">
                                            <Flex justify="flex-start" align="center" gap="6px">
                                                <Typography>{String(data.index).padStart(2, "0")}</Typography>
                                                <Typography>{data.title}</Typography>
                                            </Flex>
                                        </Box>
                                        {data.content}
                                    </Box>
                                </Box>
                            </Box>
                        </Col>
                    );
                })}
            </Row>
        </Box>
    );
}

export default MediaDetailView;
