import { useState, useEffect } from "react";

/**
 * 회사 데이터를 가져오는 커스텀 훅
 * @param fetchData 데이터 가져오는 함수
 * @param flag 플래그
 * @param serial 회원 시리얼 번호
 */
const useFlagSerialData = (
  fetchData: (flag: string, serial: string) => Promise<any>,
  flag: string,
  serial: string
) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchData(flag, serial);
        setData(response.data);
      } catch (error) {
        console.error("데이터 가져오기 실패:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (serial) {
      getData();
    }
  }, [fetchData, flag, serial]);

  return { data, loading, error };
};

export default useFlagSerialData;
