import axios from 'axios';

const API_URL = "/api/";

export interface PartnerData {
    flag: string;
    member_serial: number;
    name: string;
    owner: string;
    number: string;
    business_type: string;
    business_item: string;
    zipcode: string;
    city: string;
    addr: string;
    manager: string;
    email: string;
    tell: string;
    // image: string;
    memo: string;
}

export const partnerService = {
    createPartner: async (partnerData: PartnerData) => {
        try {
            const response = await axios.post(
                `${API_URL}?cat=company&req=create`,
                partnerData
              );
          
            return response.data;
        } catch (error) {
            throw new Error('파트너사 등록 중 오류가 발생했습니다.');
        }
    },

    updatePartner: async (partnerData: PartnerData) => {
        try {
            const response = await axios.post('?cat=company&req=update', partnerData);
            return response.data;
        } catch (error) {
            throw new Error('파트너사 수정 중 오류가 발생했습니다.');
        }
    },

    getPartner: async (partnerData: PartnerData) => {
        try {
            const response = await axios.post('?cat=company&req=read', partnerData);
            return response.data;
        } catch (error) {
            throw new Error('파트너사 정보 조회 중 오류가 발생했습니다.');
        }
    }
}; 