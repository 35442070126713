import React, { useEffect, useState } from "react";

import { Flex, Typography } from "antd";
import { PlusOutlined, CaretDownOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntSelect from "components/antd/data-entry/select";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import VcAntPaginationList from "components/custom/paginationList";
import AdvertiserBookingListLayout from "./layout";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { bookingListDataGroup } from "data/mypage/advertiser/booking/list";
import { partnerListDataGroup } from "data/mypage/advertiser/partner/list";

import useFetchCampaignData from 'hooks/useFetchCampaignData';

import useFlagSerialData from 'hooks/useFlagSerialData';
import { fetchBookingData } from 'services/findBooking';


/**
 * 쿠키에서 특정 값을 가져오는 유틸리티 함수
 * @param name 쿠키 이름
 * @returns 쿠키 값
 */
const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
};

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserBookingList({ className, style }: vcProps) {
    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentPageState, setCurrentPageState] = useState<number>(1);
    const [postsPerPageState, setPostsPerPageState] = useState<number>(10);

    const member_serial = getCookie('serial') || '';
    const company_serial = getCookie('company_serial') || '';

    const { data: bookingListData, loading: bookingListLoading, error: bookingListError } = useFlagSerialData(fetchBookingData, 'ad_co', member_serial || '');

    const { data: campaignData, loading: campaignLoading, error: campaignError } = useFetchCampaignData("campains",company_serial || '');

    useEffect(() => {
        if (campaignData) {
            const mappedData = campaignData.map((item: any) => ({
                imgPath: `https://picsum.photos/210/210?random=${item.serial}`,
                campaignName: item.title,
                description: item.content,
                campaignSerial: item.serial,
            }));
            setPostsState(mappedData);
        } else {
            // 기본 데이터 사용
            // setPostsState(campaignListDataGroup);
        }
    }, [campaignData]);

    // useEffect(() => {
    //     setPostsState(bookingListDataGroup);
    // }, []);

    // 회사명 필터 셀렉트박스 리스트
    const partnerListNameArray = partnerListDataGroup.map((data: any, index: number) => data.companyName);

    const partnerListSelectData: any[] = [];
    partnerListNameArray.forEach((element, number) => {
        partnerListSelectData.push({
            label: element,
            value: "partnerName" + number,
        });
    });

    function selectOnChangeEvent(value: string) {
        console.log(`selected ${value}`);
    }

    const finalData: any[] = [];
    postsState.forEach((element, number) => {
        finalData.push(
            <Box>
                <AdvertiserBookingListLayout
                    imgPath={element.imgPath}
                    titleData={element.campaignName}
                    description={element.description}
                    campaignSerial={element.campaignSerial}
                />
            </Box>
        );
    });

    const firstPostIndex = (currentPageState - 1) * postsPerPageState;
    const lastPostIndex = firstPostIndex + postsPerPageState;
    const currentPosts = finalData.slice(firstPostIndex, lastPostIndex);

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="listInformationBox">
                <Flex justify="space-between" align="flex-end">
                    <Box className="listCountBox">
                        <Typography>Total {postsState.length} bookings</Typography>
                    </Box>
                    <Box>
                        <Flex justify="flex-end" align="center" gap="12px">
                            <Box>
                                <VcAntSelect
                                    className="advertisingBookingSelect"
                                    placeholder="파트너사 분류"
                                    onChange={selectOnChangeEvent}
                                    options={partnerListSelectData}
                                    dropdownStyle={{ textAlign: "center" }}
                                    suffixIcon={<CaretDownOutlined />}
                                    selectStyle={{ width: "150px" }}
                                />
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
            <VcAntPaginationList
                listOptionData={currentPosts}
                postsItemCount={postsState.length}
                currentPage={currentPageState}
                setCurrentPage={setCurrentPageState}
                postsPerPage={postsPerPageState}
                itemGap={true}
            />
        </Box>
    );

    const topTitleData = advertiserMenuItemsDataGroup[3].label;

    return (
        <Box className="advertiserBookingListWrap">
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default AdvertiserBookingList;
