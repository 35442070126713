import React, { useState, useCallback, useEffect } from "react";
import { Box, Button } from "@mui/material";
import AdministrativeDistrict from "./AdministrativeDistrict";
import VcAdTgCheckCpnt from "./AdTargetCheckComp";
import AdvertisingTableLayout from "pages/advertiser/advertising/tableLayout";
import { administrativeCategoryData, checkButtonCategoryDataGroup } from "data/advertiser/advertising/target";
import useAdvertiser from 'hooks/useAdvertiser';

import { useNavigate } from "react-router-dom";

function AdvertisingTarget() {
    const { handleClick } = useAdvertiser();

    // 각 카테고리별로 선택된 항목을 저장하는 상태
    const [selectedData, setSelectedData] = useState<{ [category: string]: string[] }>({});

    const navigate = useNavigate();

    // 각 카테고리에서 선택된 항목을 업데이트하는 함수
    const handleCategoryClick = useCallback((category: string, checkedItems: string[]) => {
        setSelectedData(prevData => {
            const newData = {
                ...prevData,
                [category]: checkedItems,
            };
            // 이전 상태와 새로운 상태가 동일하면 업데이트하지 않음
            if (JSON.stringify(prevData) === JSON.stringify(newData)) {
                return prevData;
            }
            return newData;
        });
    }, []);

    // 'Next' 버튼 클릭 시 로컬 스토리지에 데이터 저장하고 다음 페이지로 이동
    const handleNext = () => {
        try {
            const postData = selectedData;
            // 로컬 스토리지에 데이터 저장
            localStorage.setItem('advertisingSelectedData', JSON.stringify(postData));
            console.log('Selected Data saved to localStorage:', postData);

            navigate('../kpi');
        } catch (error) {
            console.error('Error saving data to localStorage:', error);
            // 에러 처리 로직을 추가할 수 있습니다.
        }
    };

    const targetDataArray: any[] = [];
    targetDataArray.push({
        categoryTitle: administrativeCategoryData[0].title,
        categoryData: (
            <AdministrativeDistrict
                gridType="span"
                gridSize={12}
                selectSize="large"
                onClick={(selectedItems: string[]) => {
                    handleCategoryClick(administrativeCategoryData[0].title, selectedItems);
                }} // 클릭 핸들러 전달
            />
        ),
    });

    checkButtonCategoryDataGroup.forEach((element) => {
        targetDataArray.push({
            categoryTitle: element.title,
            categoryData: (
                <VcAdTgCheckCpnt
                    itemsData={element.option}
                    gridType={element.gridType}
                    gridSize={element.gridSize}
                    buttonSize="large"
                    onClick={(checkedItems: string[]) => {
                        handleCategoryClick(element.title, checkedItems);
                    }} // 클릭 핸들러 전달
                />
            ),
        });
    });

    useEffect(() => {
        const handleCustomNext = () => {
            handleNext();
        };

        window.addEventListener('adTargetHandleNext', handleCustomNext);

        return () => {
            window.removeEventListener('adTargetHandleNext', handleCustomNext);
        };
    }, []);

    return (
        <Box className="targetDataWrap">
            <AdvertisingTableLayout tableData={targetDataArray} />
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button 
                    id="target-next-button" 
                    className="target-next-button" 
                    variant="contained" 
                    color="primary" 
                    sx={{ display: 'none' }} 
                    onClick={handleNext}
                >
                    다음
                </Button>
            </Box>
        </Box>
    );
}

export default AdvertisingTarget;