import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    imgPath?: string;
    titleData?: string;
    description?: string;
    campaignSerial?: string;
}

function AdvertiserBookingListLayout({ className, style, imgPath, titleData, description, campaignSerial }: vcProps) {
    const navigate = useNavigate();

    const detailPath = `/mypage/advertiser/booking-management/booking-campaign-name/detail?serial=${campaignSerial}`;

    function bookingListDetailViewOnClickEvent() {
        navigate(detailPath);
    }

    const setStyles = {
        "&": { cursor: "pointer" },
        ...style,
    };

    return (
        <Box className="advertiserBookingListLayoutWrap" sx={setStyles} onClick={bookingListDetailViewOnClickEvent}>
            <Flex justify="flex-start" align="center">
                <Box className="imgBox">
                    <Box className="imgBoxInner">
                        <img src={imgPath} alt="" />
                        {/* <img src="원래 이미지 주소.jpg" onerror="this.onerror=null; this.src='대체 이미지 주소';"></img> */}
                    </Box>
                </Box>
                <Box className="informationBox">
                    <Box className="informationBoxInner">
                        <Flex justify="flex-start" align="center" style={{ width: "100%", height: "100%" }}>
                            <Box className="textBox">
                                <Box className="titleBox">
                                    <Typography>{titleData}</Typography>
                                </Box>
                                <Box className="descBox">
                                    <Typography>{description}</Typography>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
}

export default AdvertiserBookingListLayout;
