import { useCallback } from 'react';
import { advertiserClick } from '../services/advertiserService';

const useAdvertiser = () => {
  const handleClick = useCallback((value: any) => {
    advertiserClick(value);
  }, []);

  return { handleClick };
};

export default useAdvertiser; 