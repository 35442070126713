import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import $ from "jquery";

import { Flex, Button, Typography, Dropdown } from "antd";
import { DownOutlined, MessageOutlined, BellOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function Footer({ className, style }: vcProps) {
    const setStyles = {
        "&": {},
        ...style,
    };

    return (
        <Box id="footerWrapId" className="footerWrap" sx={setStyles}>
            <Flex justify="center" align="center">
                <Box className="footerItem">
                    <Typography>©{new Date().getFullYear()} Created by VERITASCONNECT.</Typography>
                </Box>
                <Box className="footerDivider" />
                <Box className="footerItem footerNav">
                    <NavLink to="/main/terms">
                        <Typography>이용약관</Typography>
                    </NavLink>
                </Box>
                <Box className="footerDivider" />
                <Box className="footerItem footerNav">
                    <NavLink to="/main/privacy">
                        <Typography>개인정보처리방침</Typography>
                    </NavLink>
                </Box>
            </Flex>
        </Box>
    );
}

export default Footer;
