import axios from 'axios';

const API_URL = '/api/';

/**
 * 캠페인 정보를 가져오는 함수
 * @param serial 회원 시리얼 번호
 * @returns AxiosPromise<any>
 */

export const fetchContractData = async (flag: string, serial: string) => {
    let payload;

    switch (flag) {
        case 'ad':
            payload = { flag, ad_co_serial: serial };
            break;
        case 'me':
            payload = { flag, me_co_serial: serial };
            break;
        case 'booking':
            payload = { flag, booking_serial: serial };
            break;
        default:
            payload = {};
    }
    
    const response = await axios.post(`${API_URL}?cat=contract&req=read`, payload);
    return response;
};