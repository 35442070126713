import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import VcAntPaginationList from "components/custom/paginationList";
import AdvertiserPartnerListLayout from "./layout";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { partnerListDataGroup } from "data/mypage/advertiser/partner/list";

import useFetchCompanyData from 'hooks/useFetchCompanyData';

/**
 * 쿠키에서 특정 값을 가져오는 유틸리티 함수
 * @param name 쿠키 이름
 * @returns 쿠키 값
 */
const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
};


interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserPartnerList({ className, style }: vcProps) {
    const navigate = useNavigate();

    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentPageState, setCurrentPageState] = useState<number>(1);
    const [postsPerPageState, setPostsPerPageState] = useState<number>(10);

    // 쿠키에서 serial 값 가져오기
    const serial = getCookie('serial') || '';

    // 커스텀 훅 사용하여 데이터 가져오기
    const { data: companyData, loading, error } = useFetchCompanyData("partners",serial);

    // useEffect(() => {
    //     setPostsState(partnerListDataGroup);
    // }, []);

    useEffect(() => {
        if (companyData) {
            const mappedData = companyData.map((company: any) => ({
                companyImgPath: company.imagePath || "https://picsum.photos/200/200", // 기본 이미지 설정
                companyName: company.name || "파트너사명",
                companyAddress: company.address || "서울특별시 서초구 강남대로 331",
                companyTel: company.phone || "010-1234-5678",
                campaignInformation: company.campaigns ? company.campaigns.join(', ') : "캠페인명 샬라샬라 외 3",
                companySerial: company.serial,
            }));
            setPostsState(mappedData);
        }
    }, [companyData]);


    function addAccountOnClickEvent() {
        // console.log("계정 추가 이벤트!");
        navigate("/mypage/advertiser/partner-management/add-partner/profile");
    }

    const finalData: any[] = postsState.map((element, number) => (
        <Box key={number}>
            <AdvertiserPartnerListLayout
                imgPath={element.companyImgPath}
                titleData={element.companyName}
                description={element.campaignInformation}
                companySerial={element.companySerial}
            />
        </Box>
    ));

    // partnerListDataGroup.forEach((element, number) => {
    //     finalData.push(
    //         <Box>
    //             <AdvertiserPartnerListLayout 
    //                 imgPath={element.companyImgPath} 
    //                 titleData={element.companyName} 
    //                 description={element.campaignInformation} 
    //             />
    //         </Box>
    //     );
    // });

    const firstPostIndex = (currentPageState - 1) * postsPerPageState;
    const lastPostIndex = firstPostIndex + postsPerPageState;
    const currentPosts = finalData.slice(firstPostIndex, lastPostIndex);

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="listInformationBox">
                <Flex justify="space-between" align="flex-end">
                    <Box className="listCountBox">
                        <Typography>Total {postsState.length} accounts</Typography>
                    </Box>
                    <Box>
                        <VcAntButton buttonTitle="계정 추가" onClick={addAccountOnClickEvent} icon={<PlusOutlined />} iconPosition="end" />
                    </Box>
                </Flex>
            </Box>
            <VcAntPaginationList
                listOptionData={currentPosts}
                postsItemCount={postsState.length}
                //postsItemCount={companyData?.length || 0}
                currentPage={currentPageState}
                setCurrentPage={setCurrentPageState}
                postsPerPage={postsPerPageState}
                itemGap={true}
            />
        </Box>
    );

    const topTitleData = advertiserMenuItemsDataGroup[1].label;

    return (
        <Box className="advertiserPartnerListWrap">
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default AdvertiserPartnerList;
