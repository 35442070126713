import React, { useState } from "react";

import { DatePicker } from "antd";
import type { DatePickerProps } from "antd";

import { CheckCircleOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { VcAntDatePickerTheme } from "theme/component";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

interface vcProps {
    chooseType: "datePicker" | "rangePicker" | undefined;
    className?: string;
    style?: React.CSSProperties;
    fullWidth?: "100%" | "auto" | "flex" | undefined;

    separator?: React.ReactNode;
    prevIcon?: React.ReactNode;
    nextIcon?: React.ReactNode;
    superPrevIcon?: React.ReactNode;
    superNextIcon?: React.ReactNode;
    size?: "small" | "middle" | "large" | undefined;
    status?: "error" | "warning" | undefined;
    variant?: "outlined" | "borderless" | "filled" | undefined;
    popupClassName?: string;
    popupStyle?: React.CSSProperties;
    getPopupContainer?: any;

    allowClear?: boolean | { clearIcon?: React.ReactNode };
    autoFocus?: boolean;
    dateRender?: React.ReactNode;
    cellRender?: React.ReactNode;
    components?: React.ReactNode;
    disabled?: boolean;
    disabledDate?: boolean;
    format?: any;
    order?: boolean;
    preserveInvalidOnBlur?: boolean;
    inputReadOnly?: boolean;
    locale?: object;
    minDate?: any;
    maxDate?: any;
    mode?: "time" | "date" | "month" | "year" | "decade" | undefined;
    needConfirm?: boolean;
    open?: boolean;
    panelRender?: React.ReactNode;
    picker?: "date" | "week" | "month" | "quarter" | "year" | undefined;
    placeholder?: string;
    placement?: "bottomLeft" | "bottomRight" | "topLeft" | "topRight" | undefined;
    presets?: React.ReactNode;
    suffixIcon?: React.ReactNode;
    onOpenChange?: any;
    onPanelChange?: any;
}

function VcAntDatePicker({
    chooseType,
    className,
    style,
    fullWidth,

    separator,
    prevIcon,
    nextIcon,
    superPrevIcon,
    superNextIcon,
    size,
    status,
    variant,
    popupClassName,
    popupStyle,
    getPopupContainer,

    allowClear,
    autoFocus,
    dateRender,
    cellRender,
    components,
    disabled,
    disabledDate,
    format,
    order,
    preserveInvalidOnBlur,
    inputReadOnly,
    locale,
    minDate,
    maxDate,
    mode,
    needConfirm,
    open,
    panelRender,
    picker,
    placeholder,
    placement,
    presets,
    suffixIcon,
    onOpenChange,
    onPanelChange,
}: vcProps) {
    const setFullWidth = fullWidth === "100%" ? { width: "100%" } : fullWidth === "auto" ? { width: "auto" } : fullWidth === "flex" ? { flex: 1 } : {};
    const setStyles = {
        "& .ant-picker": {
            ...setFullWidth,
            background: VcAntDatePickerTheme.backgroundColorFull,
            borderColor: VcAntDatePickerTheme.borderColor,
            borderRadius: VcAntDatePickerTheme.borderRadius,
            "&.ant-picker-outlined": {
                "&:hover": {
                    background: VcAntDatePickerTheme.backgroundColorHover,
                    borderColor: "#1677ff",
                    color: VcAntDatePickerTheme.fontColorBlack,
                },
            },
            "& .ant-picker-input >input": {
                textAlign: "center",
                "&:placeholder-shown": { textAlign: "center" },
            },
        },
        ...style,
    };

    switch (chooseType) {
        case "rangePicker":
            return (
                <Box sx={setStyles}>
                    <RangePicker
                        separator={separator}
                        format={format}
                        prevIcon={prevIcon}
                        nextIcon={nextIcon}
                        superNextIcon={superNextIcon}
                        superPrevIcon={superPrevIcon}
                        size={size}
                        status={status}
                        variant={variant}
                        popupClassName={popupClassName}
                        popupStyle={popupStyle}
                        getPopupContainer={getPopupContainer}
                    />
                </Box>
            );

        case "datePicker":
            return (
                <Box sx={setStyles}>
                    <DatePicker
                        format={format}
                        prevIcon={prevIcon}
                        nextIcon={nextIcon}
                        superNextIcon={superNextIcon}
                        superPrevIcon={superPrevIcon}
                        size={size}
                        status={status}
                        variant={variant}
                        popupClassName={popupClassName}
                        popupStyle={popupStyle}
                        getPopupContainer={getPopupContainer}
                    />
                </Box>
            );

        default:
            return (
                <Box sx={setStyles}>
                    <DatePicker
                        format={format}
                        prevIcon={prevIcon}
                        nextIcon={nextIcon}
                        superNextIcon={superNextIcon}
                        superPrevIcon={superPrevIcon}
                        size={size}
                        status={status}
                        variant={variant}
                        popupClassName={popupClassName}
                        popupStyle={popupStyle}
                        getPopupContainer={getPopupContainer}
                    />
                </Box>
            );
    }
}

export default VcAntDatePicker;

{
    /* <CheckCircleOutlined /> */
}
