import React, { useState } from "react";

import { Row, Col, Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons/lib/icons";

import { Box } from "@mui/material";

import { administrativeSelectTheme } from "theme/page";

import { AdministrativeCityName, AdministrativeCityData } from "data/advertiser/advertising/target";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    gridType?: "span" | "flex" | undefined;
    gridSize?: any;
    selectSize?: "small" | "middle" | "large";
    onClick?: (value: any) => void; // onClick prop 추가
}

const cityName: any[] = AdministrativeCityName;
const cityData = AdministrativeCityData;

type CityName = keyof typeof cityData;

const provinceData: CityName[] = cityName;

function AdministrativeDistrict({ className, style, gridType, gridSize, selectSize, onClick }: vcProps) {
    const [administrativeOneLevel, setAdministrativeOneLevel] = useState(cityData[provinceData[0] as CityName]);
    const [administrativeTwoLevel, setAdministrativeTwoLevel] = useState(cityData[provinceData[0]][0] as CityName);
    
    const handleProvinceChange = (value: CityName) => {
        setAdministrativeOneLevel(cityData[value]);
        setAdministrativeTwoLevel(cityData[value][0] as CityName);
        if (onClick) {
            onClick(value); // 선택된 값 전달
        }
    };
    
    const onSecondCityChange = (value: CityName) => {
        setAdministrativeTwoLevel(value);
        if (onClick) {
            onClick(value); // 선택된 값 전달
        }
    };

    const administrativeSetStyles = {
        "&": {
            "& .administrativeSelect": {
                "& .ant-select": {
                    // 버튼 - 선택 active primary
                    "&.ant-select-open": {
                        "&:hover": {
                            "& .ant-select-selector": {
                                borderColor: administrativeSelectTheme.borderColor,
                                "& .ant-select-selection-item": {
                                    color: administrativeSelectTheme.fontColorBlack + " !important",
                                },
                            },
                        },
                        "& .ant-select-selector": {
                            borderColor: administrativeSelectTheme.borderColor + " !important",
                            "& .ant-select-selection-item": {
                                color: administrativeSelectTheme.fontColorBlack + " !important",
                            },
                        },
                    },
                    // 버튼 - 셀렉트 hover 호버
                    "&:hover": {
                        "& .ant-select-selector": {
                            background: administrativeSelectTheme.backgroundColorHover,
                            borderColor: administrativeSelectTheme.borderColor,
                            color: administrativeSelectTheme.fontColorBlack,
                        },
                    },
                    // 버튼 - 셀렉트 default
                    "&.ant-select-focused": {
                        "&.ant-select-outlined": {
                            "& .ant-select-selector": { borderColor: administrativeSelectTheme.borderColor },
                        },
                    },
                    "&.ant-select-outlined": {
                        "&:hover": {
                            "& .ant-select-selector": {
                                borderColor: administrativeSelectTheme.borderColor,
                            },
                        },
                    },
                    // 셀렉트 - 기본 default
                    "& .ant-select-selector": {
                        background: administrativeSelectTheme.backgroundColorFull,
                        borderColor: administrativeSelectTheme.borderColor,
                        borderRadius: administrativeSelectTheme.borderRadius,
                        color: administrativeSelectTheme.fontColorBlack,
                    },
                    "& .ant-select-arrow": {
                        color: administrativeSelectTheme.fontColorBlack,
                    },
                },
            },
        },
    };

    return (
        <Box className="administrativeWrap" sx={administrativeSetStyles}>
            <Row gutter={[16, 16]}>
                <Col {...(gridType === "span" ? { span: gridSize } : gridType === "flex" ? { flex: gridSize } : {})}>
                    <Box className="adminOneLevelClass administrativeSelect">
                        <Select
                            defaultValue={provinceData[0]}
                            onChange={handleProvinceChange}
                            options={provinceData.map((data) => ({ label: data, value: data }))}
                            dropdownStyle={{ textAlign: "center" }}
                            size={selectSize}
                            popupClassName="administrativeSelectClassName"
                            suffixIcon={<CaretDownOutlined />}
                        />
                    </Box>
                </Col>
                <Col {...(gridType === "span" ? { span: gridSize } : gridType === "flex" ? { flex: gridSize } : {})}>
                    <Box className="adminTwoLevelClass administrativeSelect">
                        <Select
                            value={administrativeTwoLevel}
                            onChange={onSecondCityChange}
                            options={administrativeOneLevel.map((data) => ({ label: data, value: data }))}
                            dropdownStyle={{ textAlign: "center" }}
                            size={selectSize}
                            popupClassName="administrativeSelectClassName"
                            suffixIcon={<CaretDownOutlined />}
                        />
                    </Box>
                </Col>
            </Row>
        </Box>
    );
}

export default AdministrativeDistrict;
