import { useState } from "react";
import { createMessage } from "services/createMessage";

interface UseCreateMessageReturn {
  sendMessage: (params: {
    sender_serial: string;
    receiver_serial: string;
    msg: string;
  }) => Promise<void>;
  loading: boolean;
  error: Error | null;
}

export const useCreateMessage = (): UseCreateMessageReturn => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const sendMessage = async (params: {
    sender_serial: string;
    receiver_serial: string;
    msg: string;
  }) => {
    try {
      setLoading(true);
      setError(null);
      await createMessage(params);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("알 수 없는 오류가 발생했습니다")
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { sendMessage, loading, error };
};
