import React, { useState, useEffect } from "react";

import { Row, Col } from "antd";

import { Box } from "@mui/material";

import AdButtonCheckboxComp from "components/custom/buttonCheckBox";

interface Item {
    label: string;
    checkedValue: boolean;
}

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    itemsData?: Item[];
    gridType?: string;
    gridSize?: any;
    buttonSize?: "small" | "middle" | "large" | undefined;
    onClick?: (value: string[]) => void; // onClick prop 수정
}

function VcAdTgCheckCpnt({ className, style, itemsData = [], gridType, gridSize, buttonSize, onClick }: vcProps) {
    const itemsDataLabelArray = itemsData.map((data) => data.label);
    const itemsDataCheckedArray = itemsData.map((data) => data.checkedValue);

    const itemsDataAllChecked = [...Array(itemsDataCheckedArray.length)];

    const [allCheckState, setAllCheckState] = useState<boolean>(false);
    const [checkGroupState, setCheckGroupState] = useState<boolean[]>(itemsDataCheckedArray);

    // 현재 그룹에서 체크된 항목들을 저장하는 상태
    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    useEffect(() => {
        const currentCheckedItems = itemsData
            .filter((item, index) => checkGroupState[index])
            .map((item) => item.label);
        setCheckedItems(currentCheckedItems);

        if (onClick) {
            onClick(currentCheckedItems);
        }
    }, [checkGroupState, onClick, itemsData]);

    function allCheckOnChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked;
        setAllCheckState(isChecked);
        const allCheckedValue = itemsDataAllChecked.fill(isChecked);
        setCheckGroupState(allCheckedValue);
    }

    function allCheckButtonOnClickEvent() {
        const newCheckState = !allCheckState;
        setAllCheckState(newCheckState);
        const allCheckedValue = itemsDataAllChecked.fill(newCheckState);
        setCheckGroupState(allCheckedValue);
    }

    function checkOnChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked;
        const isValue = event.target.value;
        const checkIndex = itemsDataLabelArray.indexOf(isValue);
        if (checkIndex !== -1) {
            const updatedCheckGroupState = [...checkGroupState];
            updatedCheckGroupState[checkIndex] = isChecked;
            setCheckGroupState(updatedCheckGroupState);
        }
    }

    function checkButtonOnClickEvent(event: React.MouseEvent<HTMLButtonElement>) {
        const isValue = event.currentTarget.value;
        const checkIndex = itemsDataLabelArray.indexOf(isValue);
        if (checkIndex !== -1) {
            const updatedCheckGroupState = [...checkGroupState];
            updatedCheckGroupState[checkIndex] = !checkGroupState[checkIndex];
            setCheckGroupState(updatedCheckGroupState);
        }
    }

    function allCheckSyncEvent() {
        const isAllChecked = checkGroupState.every((state) => state === true);
        setAllCheckState(isAllChecked);
    }

    return (
        <Box className="targetCheckWrap" style={style}>
            <Row gutter={[16, 8]}>
                <Col {...(gridType === "span" ? { span: gridSize } : gridType === "flex" ? { flex: gridSize } : {})}>
                    <Box className="AllCheckBox">
                        <AdButtonCheckboxComp
                            buttonClassName="targetAllButton"
                            buttonType={allCheckState === true ? "primary" : "default"}
                            buttonOnClick={allCheckButtonOnClickEvent}
                            buttonLabel="전체"
                            buttonSize={buttonSize}
                            checkboxClassName="targetAllCheck"
                            checkboxChecked={allCheckState}
                            checkboxOnChange={allCheckOnChangeEvent}
                            checkboxLabel="전체"
                            fullWidth="100%"
                        />
                    </Box>
                </Col>
                {itemsData.map((data, index) => (
                    <Col
                        {...(gridType === "span" ? { span: gridSize } : gridType === "flex" ? { flex: gridSize } : {})}
                        key={index}
                    >
                        <Box className="checkGroupBox">
                            <AdButtonCheckboxComp
                                buttonClassName="targetButton"
                                buttonType={checkGroupState[index] === true ? "primary" : "default"}
                                buttonOnClick={checkButtonOnClickEvent}
                                buttonValue={data.label}
                                buttonLabel={data.label}
                                buttonSize={buttonSize}
                                checkboxClassName="targetCheck"
                                checkboxChecked={checkGroupState[index]}
                                checkboxOnChange={checkOnChangeEvent}
                                checkboxValue={data.label}
                                checkboxLabel={data.label}
                                fullWidth="100%"
                            />
                        </Box>
                    </Col>
                ))}
            </Row>
        </Box>
    );
}

export default VcAdTgCheckCpnt;