import React, { useState, PureComponent, useEffect } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import { ResponsiveContainer, PieChart, Pie, Sector, Cell, Tooltip } from "recharts";

import { mediaDetailCard5ChartDataGroup } from "data/advertiser/advertising/media";

import { colorGroup } from "theme/palette";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function MediaDetailCard5ChartFive({ className, style }: vcProps) {
    const [dataValueTotalSumState, setDataValueTotalSumState] = useState<any>();
    const [dataState, setDataState] = useState<any[]>([]);

    const setStyles = {
        "&": {},
        ...style,
    };

    const data: any = mediaDetailCard5ChartDataGroup.chart6;

    const dataValueArray = data.map((data: any, index: number) => data.value);
    let dataValueTotal = 0;

    for (let i = 0; i < dataValueArray.length; i++) {
        dataValueTotal += dataValueArray[i];
    }

    const dataPercent = data.map((data: any, index: number) => (data.value / dataValueTotal) * 100);

    const theFirstData = Math.max.apply(null, dataValueArray);

    const rankData = data.find((element: any) => element.value === Math.max.apply(null, dataValueArray));

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <Box className="customTooltip">
                    <Box
                        className="tooltipTitle"
                        sx={label === undefined ? { display: "none" } : { boxSizing: "border-box", paddingBottom: "4px" }}
                    >
                        <Typography>{label}</Typography>
                    </Box>
                    <Box className="tooltipNameValue tooltipNameValueOne">
                        <Flex justify="flex-start" align="flex-end">
                            <Box className="tooltipName">
                                <Typography>{payload[0].name}</Typography>
                            </Box>
                            <Box className="tooltipSeparator">
                                <Typography>:</Typography>
                            </Box>
                            <Box className="tooltipValue">
                                <Typography>{payload[0].value}</Typography>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            );
        } else {
            return null;
        }
    };

    return (
        <Box className="mediaDetailCard5ChartFiveWrap" sx={setStyles}>
            <Flex justify="center" align="center">
                <Box className="leftBox">
                    <Box className="rankBox">
                        <Box className="theFirstBox">
                            <Box className="theFirstName">
                                <Typography>{rankData.name}</Typography>
                            </Box>
                            <Box className="theFirstRanking">
                                <Typography>1위</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="positionBox">
                        <ResponsiveContainer width="100%" aspect={4.0 / 4.0}>
                            <PieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                                <Pie data={data} dataKey="value" cx="50%" cy="50%" innerRadius={60} outerRadius={90} fill="#82ca9d">
                                    {data.map((data: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={data.color} />
                                    ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} cursor={false} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
                <Box className="dividerBox"></Box>
                <Box className="rightBox">
                    <Box className="pieChartInfoBox">
                        <Flex justify="center" align="center">
                            <Box className="pieChartInfoInnerBox">
                                <Box className="pieChartInfoTableBox">
                                    <table className="pieChartInfoTable">
                                        <tbody>
                                            {data.map((data: any, index: number) => {
                                                return (
                                                    <tr className="pieChartInfoRow pieChartInfoTr" key={index}>
                                                        <td className="pieChartInfoCol pieChartInfoTd">
                                                            <Box className="infoColorBox">
                                                                <Box className="inforColor" style={{ background: data.color }}></Box>
                                                            </Box>
                                                        </td>
                                                        <td className="pieChartInfoCol pieChartInfoTd">
                                                            <Box className="infoNameBox">
                                                                <Typography>{data.name}</Typography>
                                                            </Box>
                                                        </td>
                                                        <td className="pieChartInfoCol pieChartInfoTd">
                                                            <Box className="infoValueBox">
                                                                <Flex justify="fle-start" align="center">
                                                                    <Box className="percentValue">
                                                                        <Typography>{dataPercent[index].toFixed(2)}</Typography>
                                                                    </Box>
                                                                    <Box className="percentUnit">
                                                                        <Typography>%</Typography>
                                                                    </Box>
                                                                </Flex>
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
}

export default MediaDetailCard5ChartFive;
