import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { Flex, Typography, Row, Col, Steps, message, Upload } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import { contractListDataGroup, priceUnit, contractFilterRadioData } from "data/mypage/advertiser/contract/list";
import { contractDetailDataUnitGroup, contractDetailDataTitleGroup, contractDetailDataValueGroup } from "data/mypage/advertiser/contract/detail";
import { colorGroup } from "theme/palette";

import useFlagSerialData from 'hooks/useFlagSerialData';
import { fetchContractData } from 'services/findContract';
import { fetchMediaData } from 'services/findMedia'; 
import { fetchCompanyData } from 'services/findCompany';


import type { UploadProps } from "antd";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserContractDetail({ className, style }: vcProps) {
    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentStepsState, setCurrentStepsState] = useState<number>(1);
    
    const location = useLocation(); // useLocation 훅 사용

    const queryParams = new URLSearchParams(location.search);
    const booking_serial = queryParams.get("serial") || "";

    const targetData = contractListDataGroup[0];
    
    //데이터가 맞지않아 임의로 추가함
    const { data: ContractListData, loading: ContractListLoading, error: ContractListError } = useFlagSerialData(fetchContractData, 'booking', "666");//booking_serial);
    const { data: MediaListData, loading: MediaListLoading, error: MediaListError } = useFlagSerialData(fetchMediaData, 'medias', "2");
    const { data: CompanyListData, loading: CompanyListLoading, error: CompanyListError } = useFlagSerialData(fetchCompanyData, 'my', "2");//company_serial);
    

    useEffect(() => {
        if (ContractListData && MediaListData && CompanyListData) {
            const mappedData = ContractListData.map((item: any) => {
                // 해당 계약의 media_serial과 일치하는 미디어 찾기
                const mediaInfo = MediaListData.find((media: any) => media.serial === item.media_serial);
                const mediaName = mediaInfo ? mediaInfo.name : '';

                    //데이터가 맞지않아 임의로 추가함
                const companyInfo = CompanyListData.find((company: any) => company.serial === "2");//item.media_serial);
                const companyName = companyInfo ? companyInfo.name : '';

                return {
                    imgPath: `https://picsum.photos/210/210?random=${item.serial}`,
                    titleData: `${mediaName}`, // 미디어 이름과 ops 결합
                    description: `${companyName}`,
                    date: item.date,
                    check_date: item.check_date,
                    confirm_date: item.confirm_date,
                    price: Number(item.amount).toLocaleString(),
                    ops: item.ops,
                    period: item.period,
                    quantity: item.quantity,
                    state: item.state,
                    contractSerial: item.serial,
                };
            });
            setPostsState(mappedData);
        }
    }, [ContractListData, MediaListData, CompanyListData]);
    

    const adCheckStyles = {
        "&": {
            background: colorGroup.realColor.clear,
            color: colorGroup.realColor.white.main[100],
            borderColor: colorGroup.realColor.white.main[20],
        },
    };
    const adConfirmStyles = {
        "&": {
            background: colorGroup.conceptColor.main[100],
            color: colorGroup.realColor.white.main[100],
            borderColor: colorGroup.realColor.white.main[60],
            fontWeight: "bold",
        },
    };

    const localeStringPrice = targetData.price.toLocaleString("ko-KR");

    const dataLabel1 = contractFilterRadioData[1].label; // 광고검수
    const dataLabel2 = contractFilterRadioData[2].label; // 광고확정
    const stateFilter = postsState[0]?.state === "check" ? dataLabel1 : postsState[0]?.state === "confirm" ? dataLabel2 : "-";
    const stateBoxStyleFilter = postsState[0]?.state === "check" ? adCheckStyles : postsState[0]?.state === "confirm" ? adConfirmStyles : {};

    const stepsData = [
        {
            title: <Typography>{contractDetailDataTitleGroup.lineTwo.left.oneLev}</Typography>,
        },
        {
            title: <Typography>{contractDetailDataTitleGroup.lineTwo.left.twoLev}</Typography>,
        },
        {
            title: <Typography>{contractDetailDataTitleGroup.lineTwo.left.threeLev}</Typography>,
        },
    ];

    const props: UploadProps = {
        name: "file",
        action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
        headers: {
            authorization: "authorization-text",
        },
        onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        maxCount: 1,
    };

    const stepTitleBoxSetStyles = {
        "&": {
            "& .ant-steps-item.ant-steps-item-finish": {
                "&>.ant-steps-item-container>.ant-steps-item-tail::after": {
                    backgroundColor: colorGroup.realColor.white.main[40],
                },
                "& .ant-steps-item-icon": {
                    width: "20px",
                    height: "20px",
                    fontSize: "11px",
                    backgroundColor: colorGroup.conceptColor.main[100],
                    borderColor: colorGroup.realColor.white.main[100],
                    "& .ant-steps-icon": {
                        // display: "none",
                        top: "-2px",
                        color: colorGroup.realColor.white.main[100],
                    },
                },
            },
            "& .ant-steps-item.ant-steps-item-process.ant-steps-item-active": {
                "& .ant-steps-item-icon": {
                    width: "20px",
                    height: "20px",
                    backgroundColor: colorGroup.realColor.white.main[50],
                    borderColor: colorGroup.realColor.white.main[100],
                    "& .ant-steps-icon": {
                        display: "none",
                        color: colorGroup.realColor.black.main[100],
                    },
                },
            },
            "& .ant-steps-item.ant-steps-item-wait": {
                "& .ant-steps-item-icon": {
                    width: "20px",
                    height: "20px",
                    backgroundColor: colorGroup.realColor.black.main[100],
                    borderColor: colorGroup.realColor.white.main[100],
                    "& .ant-steps-icon": {
                        display: "none",
                        color: colorGroup.realColor.white.main[100],
                    },
                },
            },
            "& .ant-steps.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail": { top: "-2px", insetInlineStart: "9px" },
        },
    };

    const bodyContentData1 = (
        <Box className="bodyContentDataBox bodyContentDataBox1">
            <Box className="advertiserContractDetailLayoutWrap">
                <Flex vertical justify="flex-start" align="center" gap="16px">
                    <Box className="lineOne">
                        <Flex justify="flex-start" align="center" gap="16px">
                            <Box className="imgBox">
                                <Box className="imgBoxInner">
                                    <img src={targetData.imgPath} alt="" />
                                    {/* <img src="원래 이미지 주소.jpg" onerror="this.onerror=null; this.src='대체 이미지 주소';"></img> */}
                                </Box>
                            </Box>
                            <Box className="informationBox">
                                <Box className="informationBoxInner">
                                    <Flex justify="flex-start" align="center" style={{ width: "100%", height: "100%" }}>
                                        <Box className="textBox">
                                            <Box className="titleBox">
                                                <Flex justify="space-between" align="center" style={{ width: "100%", height: "100%" }}>
                                                    <Box>
                                                        <Typography>{postsState[0]?.titleData}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Flex>
                                                            <Box className="priceTypo">
                                                                <Typography>{postsState[0]?.price}</Typography>
                                                            </Box>
                                                            <Box className="priceUnitTypo">
                                                                <Typography>{priceUnit}</Typography>
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                            <Box className="descBox">
                                                <Flex justify="space-between" align="center" style={{ width: "100%", height: "100%" }}>
                                                    <Box>
                                                        <Flex justify="flex-start" align="center" gap="16px">
                                                            <Box>
                                                                <Typography>{postsState[0]?.description}</Typography>
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                    <Box className="stateBox">
                                                        <Box className="stateBoxInner" sx={stateBoxStyleFilter}>
                                                            <Typography>{stateFilter}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                    <Box className="lineTwo">
                        <Box className="borderBox">
                            <Flex justify="space-evenly" align="center" gap="16px">
                                <Box className="leftBox">
                                    <Flex justify="flex-start" align="flex-start" gap="32px">
                                        <Box className="stepTitleBox" sx={stepTitleBoxSetStyles}>
                                            <Steps direction="vertical" size="small" current={currentStepsState} items={stepsData} />
                                        </Box>
                                        <Box className="stepValueBox">
                                            <Flex vertical justify="flex-start" align="flex-start">
                                                <Box className="stepValue stepValue1">
                                                    <Box className="flexBox">
                                                        <Typography>{postsState[0]?.date}</Typography>
                                                        <Typography>{contractDetailDataValueGroup.lineTwo.left.oneLev.time}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="stepValue stepValue2">
                                                    <Box className="flexBox">
                                                        <Typography>{postsState[0]?.check_date}</Typography>
                                                        <Typography>{contractDetailDataValueGroup.lineTwo.left.twoLev.time}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="stepValue stepValue3">
                                                    <Box className="flexBox">
                                                        <Typography>{postsState[0]?.confirm_date}</Typography>
                                                        <Typography>{contractDetailDataValueGroup.lineTwo.left.threeLev.time}</Typography>
                                                    </Box>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box className="dividerLine"></Box>
                                <Box className="rightBox">
                                    <Box className="rightTableBox">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <Typography>{contractDetailDataTitleGroup.lineTwo.right.media}</Typography>
                                                    </th>
                                                    <td>
                                                        <Typography>{postsState[0]?.titleData}</Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <Typography>{contractDetailDataTitleGroup.lineTwo.right.option}</Typography>
                                                    </th>
                                                    <td>
                                                        <Typography>{postsState[0]?.ops}</Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <Typography>{contractDetailDataTitleGroup.lineTwo.right.date}</Typography>
                                                    </th>
                                                    <td>
                                                        <Flex justify="flex-start" align="center" gap="4px">
                                                            <Typography>{postsState[0]?.period}</Typography>
                                                            {/* <Typography>{contractDetailDataValueGroup.lineTwo.right.date.start}</Typography>
                                                            <Typography>{contractDetailDataUnitGroup.lineTwo.right.dateSubTilde}</Typography>
                                                            <Typography>{contractDetailDataValueGroup.lineTwo.right.date.end}</Typography> */}
                                                        </Flex>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <Typography>{contractDetailDataTitleGroup.lineTwo.right.count}</Typography>
                                                    </th>
                                                    <td>
                                                        <Flex justify="flex-start" align="center" gap="2px">
                                                            <Typography>{postsState[0]?.quantity}</Typography>
                                                            {/* <Typography>{contractDetailDataUnitGroup.lineTwo.right.countUnit}</Typography> */}
                                                        </Flex>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Box className="lineThree">
                        <Box className="borderBox">
                            <Flex vertical justify="flex-start" align="center">
                                <Box className="lineThreeTitleBox">
                                    <Typography>광고 파일</Typography>
                                </Box>
                                <Box className="inputBox">
                                    <Box className="inputInnerBox">
                                        <Flex justify="flex-start" align="center">
                                            <Box className="fileUploadButtonBox">
                                                <Upload {...props}>
                                                    <VcAntButton className="" buttonTitle="파일찾기" buttonType="default" conceptType="concept" />
                                                </Upload>
                                            </Box>
                                        </Flex>
                                    </Box>
                                    <Box className="etcBox">
                                        <Flex justify="space-between" align="flex-start">
                                            <Box className="fileDescription">
                                                <ul>
                                                    <li>
                                                        <Typography>동영상: MP4, AVI</Typography>
                                                    </li>
                                                    <li>
                                                        <Typography>압축: ZIP/ALZ (최대 500MB)</Typography>
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Box className="saveButtonBox">
                                                <VcAntButton className="" buttonTitle="저장" buttonType="default" conceptType="concept" />
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserContractDetailWrap">
            <MypageSectionCard topTitle="계약 관리 상세" bodyContent={bodyContentData1} />
        </Box>
    );
}

export default AdvertiserContractDetail;
