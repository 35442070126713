import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import $ from "jquery";

import { Flex, Tabs, Typography } from "antd";
import type { TabsProps } from "antd";
import { PlusOutlined, SendOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";

import ContentCard from "components/layout/contentCard";
import VcAntPaginationList from "components/custom/paginationList";

import { notificationCommonDataGroup, notificationDataGroup } from "data/mypage/common/notification";

import { colorGroup } from "theme/palette";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function CommonNotification({ className, style }: vcProps) {
    const navigate = useNavigate();

    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentPageState, setCurrentPageState] = useState<number>(1);
    const [postsPerPageState, setPostsPerPageState] = useState<number>(10);

    useEffect(() => {
        setPostsState(notificationDataGroup);
    }, []);

    const finalData: any[] = [];
    notificationDataGroup.forEach((element, number) => {
        finalData.push(
            <Box className="notificationItem">
                <Box className="notificationItemInner">
                    <Flex justify="flex-start" align="cneter" gap="24px">
                        <Box className="notiImageBox">
                            <Box className="notiImageBoxInner">
                                <img src={element.img} alt="" />
                            </Box>
                        </Box>
                        <Box className="notiInfoBox">
                            <Box className="notiInfoInnerBox">
                                <Flex justify="space-between" align="center" gap="16px">
                                    <Box className="notiLeftBox">
                                        <Box className="categoryBox">
                                            <Typography>{element.category}</Typography>
                                        </Box>
                                        <Box className="titleBox">
                                            <Typography.Paragraph ellipsis={{ rows: 1 }}>{element.title}</Typography.Paragraph>
                                        </Box>
                                    </Box>
                                    <Box className="notiRightBox">
                                        <Flex justify="flex-end" align="center" gap="8px">
                                            <Box className="reportingDate">
                                                <Flex justify="flex-start" align="center">
                                                    <Typography className="reportingDateYear">{element.reportingDate.year}</Typography>
                                                    <Typography className="dateMiddlePeriod">
                                                        {notificationCommonDataGroup.dateMiddlePeriod}
                                                    </Typography>
                                                    <Typography className="reportingDateMonth">{element.reportingDate.month}</Typography>
                                                    <Typography className="dateMiddlePeriod">
                                                        {notificationCommonDataGroup.dateMiddlePeriod}
                                                    </Typography>
                                                    <Typography className="reportingDateDay">{element.reportingDate.day}</Typography>
                                                </Flex>
                                            </Box>
                                            <Box className="reportingTime">
                                                <Flex justify="flex-start" align="center">
                                                    <Typography className="reportingDateHour">{element.reportingDate.hour}</Typography>
                                                    <Typography className="timeMiddleColon">
                                                        {notificationCommonDataGroup.timeMiddleColon}
                                                    </Typography>
                                                    <Typography className="reportingDateMinute">{element.reportingDate.minute}</Typography>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        );
    });

    const firstPostIndex = (currentPageState - 1) * postsPerPageState;
    const lastPostIndex = firstPostIndex + postsPerPageState;
    const currentPosts = finalData.slice(firstPostIndex, lastPostIndex);

    const bodyContentData = (
        <Box className="mypageBodyContent">
            <Box className="contentDataWrap">
                <Box className="notificationListBox">
                    <Box className="notificationListInnerBox">
                        <VcAntPaginationList
                            listOptionData={currentPosts}
                            postsItemCount={postsState.length}
                            currentPage={currentPageState}
                            setCurrentPage={setCurrentPageState}
                            postsPerPage={postsPerPageState}
                            itemGap={true}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const setStyles = {
        "&": {},
        ...style,
    };

    return (
        <Box className="commonNotificationWrap" sx={setStyles}>
            {/* 알림 */}
            <ContentCard topTitle="알림" bodyContent={bodyContentData} />
        </Box>
    );
}

export default CommonNotification;
