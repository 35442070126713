import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import Cookies from 'js-cookie'; // 쿠키 사용을 위해 추가
import $ from "jquery";

import { useNavigate } from 'react-router-dom';

import { Flex, Button, Typography, Dropdown } from "antd";
import { DownOutlined, MessageOutlined, BellOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import Logo from "components/layout/logo";

import type { MenuProps } from "antd";

// import VcContainer from "components/layout/Container";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function Header({ className, style }: vcProps) {

    const navigate = useNavigate();

    const handleLogout = () => {
      // 모든 쿠키 삭제
      Cookies.remove('userId');
      Cookies.remove('serial');
      Cookies.remove('company_serial');
      Cookies.remove('authority');
      
      // 홈페이지로 리다이렉트
      navigate('/');
    };

    const authItems: MenuProps["items"] = [
        {
            key: 1,
            label: <NavLink to="/authority/login">로그인</NavLink>,
        },
        {
            key: 2,
            label: <NavLink to="/authority/join-membership">회원가입</NavLink>,
        },
        {
            key: 3,
            label: <NavLink to="/authority/find-id">아이디 찾기</NavLink>,
        },
        {
            key: 4,
            label: <NavLink to="/authority/find-pw">비밀번호 찾기</NavLink>,
        },
    ];
    
    const leftMenuItems = [
        {
            key: 1,
            label: "logo",
            content: (
                <Box className="logoBox">
                    <NavLink to="/main">
                        <Box className="logo">LOGO</Box>
                    </NavLink>
                </Box>
            ),
        },
        // {
        //     key: 2,
        //     label: "광고주",
        //     content: <Button>광고주</Button>,
        // },
        // {
        //     key: 3,
        //     label: "매체사",
        //     content: <Button>매체사</Button>,
        // },
        {
            key: 4,
            label: "지식서비스",
            content: (
                <NavLink to="/knowledge-service/category-name1/class-list">
                    <Button>지식서비스</Button>
                </NavLink>
            ),
        },
        {
            key: 5,
            label: "광고하기",
            content: (
                <NavLink to="/mypage/advertiser/campaign-management/advertising/target">
                    <Button>광고하기</Button>
                </NavLink>
            ),
        },
        {
            key: 6,
            label: "권한",
            content: (
                <Dropdown menu={{ items: authItems }}>
                    <Button>
                        권한
                        <DownOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    const getMypageItems = () => {
        const userAuthority = Cookies.get('authority');
        
        switch(userAuthority) {
            case 'GeneralRoutes':
                return [{
                    key: 1,
                    label: <NavLink to="/mypage/general/profile">마이페이지(일반유저)</NavLink>,
                }];
            case 'AdvertiserRoutes':
                return [{
                    key: 2,
                    label: <NavLink to="/mypage/advertiser/profile">마이페이지(광고주)</NavLink>,
                }];
            case 'MediaRoutes':
                return [{
                    key: 3,
                    label: <NavLink to="/mypage/media-company/profile">마이페이지(매체사)</NavLink>,
                }];
            default:
                return []; // 권한이 없는 경우 빈 배열 반환
        }
    };

    const mypageItems: MenuProps["items"] = getMypageItems();

    const rightMenuItems = [
        {
            key: 1,
            label: "메세지",
            content: (
                <NavLink to="/mypage/message">
                    <Button>
                        <MessageOutlined />
                    </Button>
                </NavLink>
            ),
        },
        {
            key: 2,
            label: "알람",
            content: (
                <NavLink to="/mypage/notification">
                    <Button>
                        <BellOutlined />
                    </Button>
                </NavLink>
            ),
        },
        {
            key: 3,
            label: "마이페이지",
            content: (
                <Dropdown menu={{ items: mypageItems }}>
                    <Button>
                        마이페이지
                        <DownOutlined />
                    </Button>
                </Dropdown>
            ),
        },
        {
            key: 4,
            label: "로그아웃",
            content: <Button onClick={handleLogout}>로그아웃</Button>,
        },
        // {
        //     key: 5,
        //     label: "메뉴",
        //     content: (
        //         <Button >
        //             <MenuOutlinedIcon />
        //         </Button>
        //     ),
        // },
    ];

    return (
        <Box id="headerWrapId" className="headerWrap">
            <Flex justify="space-between" align="center">
                <Box className="leftMenu">
                    <Flex justify="flex-start" align="center" gap="16px">
                        {leftMenuItems.map((data: any, index: number) => {
                            return (
                                <Box className="menuBtn" key={index}>
                                    {data.content}
                                </Box>
                            );
                        })}
                    </Flex>
                </Box>
                <Box className="rightMenu">
                    <Flex justify="flex-start" align="center" gap="16px">
                        {rightMenuItems.map((data: any, index: number) => {
                            return (
                                <Box className="menuBtn" key={index}>
                                    {data.content}
                                </Box>
                            );
                        })}
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
}

export default Header;
