import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import $ from "jquery";

import { Flex, Button, Typography } from "antd";

import { Box } from "@mui/material";

import type { MenuProps } from "antd";

// import VcContainer from "components/layout/Container";

// leftMenuItems
const authItems = [
    {
        key: 1,
        label: "logo",
        content: (
            <Box className="logoBox">
                <NavLink to="/main">
                    <Box className="logo">LOGO</Box>
                </NavLink>
            </Box>
        ),
    },
    {
        key: 2,
        label: "main",
        content: (
            <NavLink to="/main">
                <Button>메인으로 이동</Button>
            </NavLink>
        ),
    },
];


function AuthLayout() {
    useEffect(() => {
        const headerWrapHeight = $("#headerWrapId").innerHeight();
        const paddingPlusValue = 24;
        $("#contentWrapId").css("padding-bottom", paddingPlusValue + "px");
        $("#contentWrapId").css("padding-top", Number(headerWrapHeight) + paddingPlusValue + "px");
    }, []);

        
    const rightMenuItems = [
        {
            key: 1,
            label: "회원가입",
            content: (
                <NavLink to="/authority/join-membership">
                    <Button>회원가입</Button>
                </NavLink>
            ),
        },
        {
            key: 2,
            label: "로그인",
            content: (
                <NavLink to="/authority/login">
                    <Button>로그인</Button>
                </NavLink>
            ),
        },
        {
            key: 3,
            label: "아이디 찾기",
            content: (
                <NavLink to="/authority/find-id">
                    <Button>아이디 찾기</Button>
                </NavLink>
            ),
        },
        {
            key: 4,
            label: "비밀번호 찾기",
            content: (
                <NavLink to="/authority/find-pw">
                    <Button>비밀번호 찾기</Button>
                </NavLink>
            ),
        },
        {
            key: 5,
            label: "로그아웃",
            content: <Button>로그아웃</Button>,
        },
    ];
    return (
        <Box className="mainLayout">
            <Box id="headerWrapId" className="headerWrap">
                <Flex justify="space-between" align="center">
                    <Box className="leftMenu">
                        <Flex justify="flex-start" align="center" gap="16px">
                            {authItems.map((data: any, index: number) => {
                                return (
                                    <Box className="menuBtn" key={index}>
                                        {data.content}
                                    </Box>
                                );
                            })}
                        </Flex>
                    </Box>
                    <Box className="rightMenu">
                        <Flex justify="flex-start" align="center" gap="16px">
                            {rightMenuItems.map((data: any, index: number) => {
                                return (
                                    <Box className="menuBtn" key={index}>
                                        {data.content}
                                    </Box>
                                );
                            })}
                        </Flex>
                    </Box>
                </Flex>
            </Box>

            <Box className="bodyWrap">
                <Box id="contentWrapId" className="contentWrap">
                    {/* <VcContainer fullWidth={true} fullHeight={true}> */}
                    <Outlet />
                    {/* </VcContainer> */}
                </Box>
            </Box>

            <Box id="footerWrapId" className="footerWrap">
                <Typography>©{new Date().getFullYear()} Created by VERITASCONNECT</Typography>
            </Box>
        </Box>
    );
}

export default AuthLayout;
