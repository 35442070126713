import React, { useState, PureComponent, useEffect } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip } from "recharts";

import { mediaDetailCard5ChartDataGroup } from "data/advertiser/advertising/media";

import { colorGroup } from "theme/palette";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function MediaDetailCard5ChartSix({ className, style }: vcProps) {
    const setStyles = {
        "&": {},
        ...style,
    };

    const mondayData: any = mediaDetailCard5ChartDataGroup.chart3.Monday;
    const tuesdayData: any = mediaDetailCard5ChartDataGroup.chart3.Tuesday;
    const wednesdayData: any = mediaDetailCard5ChartDataGroup.chart3.Wednesday;
    const thursdayData: any = mediaDetailCard5ChartDataGroup.chart3.Thursday;
    const fridayData: any = mediaDetailCard5ChartDataGroup.chart3.Friday;
    const saturdayData: any = mediaDetailCard5ChartDataGroup.chart3.Saturday;
    const sundayData: any = mediaDetailCard5ChartDataGroup.chart3.Sunday;

    // Sunday
    // Monday
    // Tuesday
    // Wednesday
    // Thursday
    // Friday
    // Saturday

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <Box className="customTooltip">
                    <Box
                        className="tooltipTitle"
                        sx={label === undefined ? { display: "none" } : { boxSizing: "border-box", paddingBottom: "4px" }}
                    >
                        <Typography>{label}</Typography>
                    </Box>
                    <Box className="tooltipNameValue tooltipNameValueTwo">
                        <Flex justify="flex-start" align="flex-end">
                            <Box className="tooltipName">
                                <Typography>{payload[1].name}</Typography>
                            </Box>
                            <Box className="tooltipSeparator">
                                <Typography>:</Typography>
                            </Box>
                            <Box className="tooltipValue">
                                <Typography>{payload[1].value}</Typography>
                            </Box>
                        </Flex>
                    </Box>
                    <Box className="tooltipNameValue tooltipNameValueOne">
                        <Flex justify="flex-start" align="flex-end">
                            <Box className="tooltipName">
                                <Typography>{payload[0].name}</Typography>
                            </Box>
                            <Box className="tooltipSeparator">
                                <Typography>:</Typography>
                            </Box>
                            <Box className="tooltipValue">
                                <Typography>{payload[0].value}</Typography>
                            </Box>
                        </Flex>
                    </Box>
                    <Box className="tooltipNameValue tooltipNameValueTwo">
                        <Flex justify="flex-start" align="flex-end">
                            <Box className="tooltipName">
                                <Typography>{payload[2].name}</Typography>
                            </Box>
                            <Box className="tooltipSeparator">
                                <Typography>:</Typography>
                            </Box>
                            <Box className="tooltipValue">
                                <Typography>{payload[2].value}</Typography>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            );
        } else {
            return null;
        }
    };

    const renderTooltip = (props: any) => {
        const { active, payload } = props;

        if (active && payload && payload.length) {
            const data = payload[0] && payload[0].payload;
            return (
                <div
                    style={{
                        backgroundColor: "#fff",
                        border: "1px solid #999",
                        margin: 0,
                        padding: 10,
                    }}
                >
                    <p>{data.hour}</p>
                    <p>
                        <span>value: </span>
                        {data.value}
                    </p>
                </div>
            );
        }

        return null;
    };

    const parseDomain = () => [
        0,
        Math.max(
            Math.max.apply(
                null,
                mondayData.map((entry: any) => entry.value)
            )
        ),
    ];

    const domain = parseDomain();
    const range = [16, 225];

    const weekArray = [
        { addClass: "", label: "월요일", chartData: [] },
        { addClass: "", label: "화요일", chartData: [] },
        { addClass: "", label: "수요일", chartData: [] },
        { addClass: "", label: "목요일", chartData: [] },
        { addClass: "", label: "금요일", chartData: [] },
        { addClass: "", label: "토요일", chartData: [] },
        { addClass: "", label: "일요일", chartData: [] },
    ];

    const originalChartData = [
        { hour: 2, week: 1, z: 100 },
        { hour: 4, week: 1, z: 400 },
        { hour: 6, week: 1, z: 800 },
        { hour: 8, week: 1, z: 1200 },
        { hour: 10, week: 1, z: 2000 },
        { hour: 12, week: 1, z: 3000 },
        { hour: 14, week: 1, z: 2500 },
        { hour: 16, week: 1, z: 1000 },
        { hour: 18, week: 1, z: 200 },
        { hour: 20, week: 1, z: 1200 },
        { hour: 22, week: 1, z: 2200 },
        { hour: 24, week: 1, z: 4000 },
    ];

    return (
        <Box className="mediaDetailCard5ChartSixWrap" sx={setStyles}>
            <Flex vertical justify="center" align="center">
                <Box className="ChartItemGroupBox">
                    {weekArray.map((data: any, index: number) => {
                        return (
                            <Box className={index === 6 ? "ChartItemBox" : "ChartItemBox ChartItemHideBox"} key={index}>
                                <ResponsiveContainer width="100%" height={64}>
                                    <ScatterChart margin={{ top: 0, right: 40, bottom: 0, left: 20 }}>
                                        <CartesianGrid />
                                        <XAxis
                                            type="number"
                                            dataKey="hour"
                                            name="stature"
                                            unit=":00"
                                            tickCount={7}
                                            tickLine={false}
                                            tickMargin={8}
                                            padding={{ right: 40 }}
                                            axisLine={{ stroke: "#777", strokeWidth: 2 }}
                                            strokeDasharray="3 3"
                                        />
                                        <YAxis
                                            label={data.label}
                                            dataKey="week"
                                            name="weight"
                                            tick={false}
                                            tickCount={1}
                                            tickLine={false}
                                            tickMargin={8}
                                            padding={{ top: 18 }}
                                            axisLine={false}
                                            strokeDasharray="3 3"
                                        />
                                        <ZAxis type="number" dataKey="z" range={[60, 400]} name="score" unit="km" />
                                        <Tooltip content={<CustomTooltip />} cursor={false} />
                                        <Scatter name="A school" data={originalChartData} fill="#8884d8" />
                                    </ScatterChart>
                                </ResponsiveContainer>
                            </Box>
                        );
                    })}
                </Box>
            </Flex>
        </Box>
    );
}

export default MediaDetailCard5ChartSix;
