import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface PrivateRouteProps {
  children: React.ReactNode;
  allowedRoutes: string[];
}

const PrivateRoute = ({ children, allowedRoutes }: PrivateRouteProps) => {
  const authority = Cookies.get('authority');
  
  if (!authority) {
    return <Navigate to="/authority/login" replace />;
  }
  
  if (!allowedRoutes.includes(authority)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;