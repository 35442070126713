import React, { useState } from "react";

import { Row, Col, Flex, Typography, Space, Divider } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcToggleButton from "components/custom/toggleButton";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import ChartComp from "pages/advertiser/advertising/media/chart";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { unitDataGroup, mediaDataGroup } from "data/advertiser/advertising/media";
import { interestListDataGroup } from "data/mypage/advertiser/interest/list";

import useFlagSerialData from 'hooks/useFlagSerialData';
import { fetchInterestData } from 'services/findInterest';

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserInterestMediaList({ className, style }: vcProps) {

    const { data: InterestListData, loading: InterestListLoading, error: InterestListError } = useFlagSerialData(fetchInterestData, 'interest', "1");
    
    console.log(InterestListData);

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Flex vertical justify="flex-start" align="center">
                <Box className="interestMediaListBox">
                    <ul>
                        {InterestListData && mediaDataGroup.slice(0, InterestListData.length).map((mediaData: any, index: number) => {
                            const interestData = InterestListData[index];
                            return (
                                <li key={index}>
                                    <Box className="interestMediaBox">
                                        <Box className="favoriteButtonBox">
                                            <VcToggleButton
                                                conceptType="clear"
                                                toggleOnIcon={<HeartFilled />}
                                                toggleOffIcon={<HeartOutlined />}
                                                // toggleDefaultState={interestListDataGroup[index].favorite}
                                                iconColor="#ff7777"
                                                iconSize="22px"
                                                buttonSize="large"
                                            />
                                        </Box>
                                        <Row gutter={[0, 0]}>
                                            <Col span={12}>
                                                <Box className="colInnerBox colInnerBox1">
                                                    <Box className="colInnerPaddingBox">
                                                        <Box className="imgBox">
                                                            <img src={mediaData.bannerImg} alt="" />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Col>
                                            <Col span={12}>
                                                <Box className="colInnerBox colInnerBox2">
                                                    <Box className="colInnerPaddingBox">
                                                        <Box className="chartBox">
                                                            <Flex justify="center" align="center" style={{ width: "100%", height: "100%" }}>
                                                                <ChartComp chartBoxWidth={300} chartBoxHeight={200} chartSize={400} />
                                                            </Flex>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Col>
                                            <Col span={12}>
                                                <Box className="colInnerBox colInnerBox3">
                                                    <Box className="colInnerPaddingBox">
                                                        <Box className="mediaInfoBox">
                                                            <Box className="mediaInfo">
                                                                <Box className="infoLine1">
                                                                    <Box className="infoTitle">
                                                                        <Typography>{interestData.name || mediaData.information.name}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="infoLine2">
                                                                    <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                                                                        <Box className="infoCharacter">
                                                                            <Typography>{interestData.character || mediaData.information.character}</Typography>
                                                                        </Box>
                                                                        <Box className="infoSize">
                                                                            <Typography>{interestData.standard}</Typography>
                                                                            {/* <Typography>
                                                                                {mediaData.information.size.displayHorizontalValue.toLocaleString("ko-KR")}
                                                                                {unitDataGroup.information.size.displayHorizontalUnit}
                                                                            </Typography>
                                                                            <Typography style={{ boxSizing: "border-box", padding: "0 4px" }}>
                                                                                {unitDataGroup.information.multiply}
                                                                            </Typography>
                                                                            <Typography>
                                                                                {mediaData.information.size.displayVerticalValue.toLocaleString("ko-KR")}
                                                                                {unitDataGroup.information.size.displayVerticalUnit}
                                                                            </Typography>
                                                                            <Typography style={{ boxSizing: "border-box", paddingLeft: "8px" }}>
                                                                                &#40;
                                                                                {mediaData.information.size.displayTotalValue.toLocaleString("ko-KR")}
                                                                                {unitDataGroup.information.size.displayTotalUnit}
                                                                                &#41;
                                                                            </Typography> */}
                                                                        </Box>
                                                                    </Space>
                                                                </Box>
                                                                <Box className="infoLine3">
                                                                    <Box className="infoPrice">
                                                                        <Flex justify="flex-start" align="flex-end">
                                                                            <Typography className="priceDateUnit">{unitDataGroup.price.dateUnit}</Typography>
                                                                            <Typography className="priceValue">
                                                                                {mediaData.price.value.toLocaleString("ko-KR")}
                                                                            </Typography>
                                                                            <Typography className="priceValueUnit">{unitDataGroup.price.valueUnit}</Typography>
                                                                            <Typography className="priceEtc">{unitDataGroup.price.priceEtc}</Typography>
                                                                        </Flex>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Col>
                                            <Col span={12}>
                                                <Box className="colInnerBox colInnerBox4">
                                                    <Box className="colInnerPaddingBox">
                                                        <Flex justify="center" align="center" style={{ width: "100%", height: "100%" }}>
                                                            <Box className="mediaMatching">
                                                                <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                                                                    <Box className="matchingTitle">
                                                                        <Typography>{unitDataGroup.matching.percentTitle}</Typography>
                                                                    </Box>
                                                                    <Box className="matchingPercent">
                                                                        <Flex justify="flex-start" align="flex-end">
                                                                            <Box className="matchingPercentValue">
                                                                                <Typography>{mediaData.matching.percentValue}</Typography>
                                                                            </Box>
                                                                            <Box className="matchingPercentUnit">
                                                                                <Typography>{unitDataGroup.matching.percentUnit}</Typography>
                                                                            </Box>
                                                                        </Flex>
                                                                    </Box>
                                                                </Space>
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                </Box>
                                            </Col>
                                        </Row>
                                    </Box>
                                </li>
                            );
                        })}
                    </ul>
                </Box>
            </Flex>
        </Box>
    );

    const topTitleData = advertiserMenuItemsDataGroup[5].label;

    return (
        <Box className="advertiserInterestMediaListWrap">
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default AdvertiserInterestMediaList;
