export const messageCommonDataGroup = {
    dateMiddlePeriod: ".",
    timeMiddleColon: ":",
    messageLastUpdateTimeUnitHour: "시간",
    messageLastUpdateTimeUnitMinute: "분",
    messageLastUpdateTimeBeforeWord: "전",
};

export const myProfileData = {
    profileImg: "https://picsum.photos/401/401",
};

export const messageDataGroup = [
    {
        companyImg: "https://picsum.photos/301/301",
        companyName: "나이키",
        companyDescription: "Culpa ut tempor id dolore consequat quis.",
        messageLastUpdateTimeValue: {
            year: 0,
            month: 0,
            day: 0,
            hour: 0,
            minute: 5,
        },
        messageNewBadge: true,
        messageData: [
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 4, hour: 15, minute: 33 },
                conversationValue: "Velit sit adipisicing laboris amet ipsum ea veniam adipisicing irure consectetur magna.",
            },
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 4, hour: 15, minute: 34 },
                conversationValue: "Velit id est irure consectetur occaecat laboris consectetur ut amet velit cillum incididunt duis nisi.",
            },
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 4, hour: 15, minute: 34 },
                conversationValue: "Velit id est irure consectetur occaecat laboris consectetur ut amet velit cillum incididunt duis nisi.",
            },
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 4, hour: 15, minute: 34 },
                conversationValue: "Velit id est irure consectetur occaecat laboris consectetur ut amet velit cillum incididunt duis nisi.",
            },
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 4, hour: 15, minute: 34 },
                conversationValue: "Velit id est irure consectetur occaecat laboris consectetur ut amet velit cillum incididunt duis nisi.",
            },
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 4, hour: 15, minute: 34 },
                conversationValue: "Velit id est irure consectetur occaecat laboris consectetur ut amet velit cillum incididunt duis nisi.",
            },
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 4, hour: 15, minute: 34 },
                conversationValue: "Velit id est irure consectetur occaecat laboris consectetur ut amet velit cillum incididunt duis nisi.",
            },
            {
                writer: "user",
                date: { year: 2024, month: 2, day: 4, hour: 15, minute: 42 },
                conversationValue: "Officia elit in incididunt dolore ad. Eiusmod officia elit id amet culpa voluptate do mollit quis.",
            },
        ],
    },
    {
        companyImg: "https://picsum.photos/302/302",
        companyName: "아디다스",
        companyDescription: "Sint ut cillum exercitation esse commodo deserunt laboris occaecat duis aliquip in ex elit ullamco.",
        messageLastUpdateTimeValue: {
            year: 0,
            month: 0,
            day: 0,
            hour: 0,
            minute: 5,
        },
        messageLastUpdateTimeUnit: "분",
        messageNewBadge: true,
        messageData: [
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 5, hour: 10, minute: 21 },
                conversationValue: "Dolore nisi pariatur id commodo. Tempor in ex occaecat in.",
            },
            {
                writer: "user",
                date: { year: 2024, month: 2, day: 5, hour: 10, minute: 26 },
                conversationValue: "Laborum nulla ullamco tempor magna magna ipsum in culpa qui sint consectetur elit eiusmod do.",
            },
            {
                writer: "partner",
                date: { year: 2024, month: 2, day: 5, hour: 10, minute: 41 },
                conversationValue: "Mollit consectetur magna tempor qui aliqua in sint ad.",
            },
        ],
    },
];
