import axios from 'axios';

const API_URL = "/api/";

export interface BookingRequestData {
    campain_serial: number;
    ad_co_serial: number;
    media_serial: number;
    me_co_serial: number;
    location: string;
    place: string;
    gender: string;
    age: string;
    budget: string;
    category: string;
    kpi1?: string;
    kpi2?: string;
    kpi3?: string;
    kpi4?: string;
    kpi5?: string;
    kpi6?: string;
    kpi7?: string;
    kpi8?: string;
    kpi9?: string;
    kpi10?: string;
}

export const bookingService = {
    createBooking: async (bookingData: BookingRequestData) => {
        try {
            const response = await axios.post(
                `${API_URL}?cat=booking&req=create`,
                bookingData
              );
          
            return response.data;
        } catch (error) {
            throw new Error('부킹 등록 중 오류가 발생했습니다.');
        }
    },

    updateBooking: async (bookingData: BookingRequestData) => {
        try {
            const response = await axios.post('?cat=booking&req=update', bookingData);
            return response.data;
        } catch (error) {
            throw new Error('부킹 수정 중 오류가 발생했습니다.');
        }
    },

    getBooking: async (bookingData: BookingRequestData) => {
        try {
            const response = await axios.post('?cat=booking&req=read', bookingData);
            return response.data;
        } catch (error) {
            throw new Error('부킹 정보 조회 중 오류가 발생했습니다.');
        }
    }
}; 