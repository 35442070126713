import React, { useState } from "react";

import { Row, Col, Typography, Flex } from "antd";
import {
    CameraOutlined,
    PlusOutlined,
    MinusOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    tableData?: any;
}

function MypageSectionMaterialTableLayout({
    className,
    style,
    tableData,
}: vcProps) {

    // const [categoryAllDataArrayState, setCategoryAllDataArrayState] = useState<any[]>([]);
    // useEffect(() => {
    //     const targetDataTitleArray = tableData.map((data: any, index: number) => data.categoryTitle);
    //     const targetDataTitleArrayLength = targetDataTitleArray.length;
    //     const targetDataContentArray = tableData.map((data: any, index: number) => data.categoryData);
    //     const targetAllDataArray = [];
    //     for (let i = 0; i < targetDataTitleArrayLength; i++) {
    //         targetAllDataArray[i] = { title: targetDataTitleArray[i], content: targetDataContentArray[i] };
    //     }
    //     setCategoryAllDataArrayState(targetAllDataArray);
    // }, [tableData]);

    const [data, setData] = useState(tableData);

    function materialMinusOnClickEvent(index: number) {
        const newData = data.filter((_: any, i: number) => i !== index);
        setData(newData);
    }

    return (
        <Box>
            {data.map((dataItem: any, index: number) => {
                return (
                    <Box
                        className="mypageSectionMaterialTableLayoutWrap"
                        key={index}
                    >
                        <Box className="borderBox">
                            <Box className="borderBoxInner">
                                <Row
                                    gutter={[16, 16]}
                                    justify="start"
                                    align="middle"
                                >
                                    <Col span={4} className="titleCol">
                                        <Box className="categoryTitleBox">
                                            <Box className="categoryTitleBoxInner">
                                                <Typography>
                                                    {dataItem.categoryOneTitle}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col span={20} className="dataCol">
                                        <Box className="categoryDataBox">
                                            {dataItem.categoryOneData}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row
                                    gutter={[16, 16]}
                                    justify="start"
                                    align="middle"
                                >
                                    <Col span={4} className="titleCol">
                                        <Box className="categoryTitleBox">
                                            <Box className="categoryTitleBoxInner">
                                                <Typography>
                                                    {dataItem.categoryTwoTitle}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col span={20} className="dataCol">
                                        <Box className="categoryDataBox">
                                            {dataItem.categoryTwoData}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row
                                    gutter={[16, 16]}
                                    justify="start"
                                    align="middle"
                                >
                                    <Col span={4} className="titleCol">
                                        <Box className="categoryTitleBox">
                                            <Box className="categoryTitleBoxInner">
                                                <Typography>
                                                    {dataItem.categoryThreeTitle}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col span={20} className="dataCol">
                                        <Box className="categoryDataBox">
                                            {dataItem.categoryThreeData}
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                            <Box className="minusButtonBox">
                                <VcAntButton
                                    shape="circle"
                                    icon={<MinusOutlined />}
                                    buttonSize="small"
                                    onClick={() => materialMinusOnClickEvent(index)}
                                />
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
}

export default MypageSectionMaterialTableLayout;
