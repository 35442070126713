import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // useLocation 추가

import { Flex, Row, Col, Typography, Space, Modal, Divider } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcModalPopup from "components/custom/modalPopup";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import BookingApplyMediaLayout from "./layout";

import { campaignListDataGroup } from "data/mypage/advertiser/campaign/list";

import { mediaDataGroup } from "data/advertiser/advertising/media";
import { bookingInformationDataGroup } from "data/mypage/advertiser/booking/information";

import useFlagSerialData from 'hooks/useFlagSerialData';
import useFetchCampaignData from 'hooks/useFetchCampaignData';

import { fetchBookingData } from 'services/findBooking';



/**
 * 쿠키에서 특정 값을 가져오는 유틸리티 함수
 * @param name 쿠키 이름
 * @returns 쿠키 값
 */
const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
};


interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserBookingDetail({ className, style }: vcProps) {
    const navigate = useNavigate();
    const location = useLocation(); // useLocation 훅 사용

    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentPageState, setCurrentPageState] = useState<number>(1);
    const [postsPerPageState, setPostsPerPageState] = useState<number>(10);

    const queryParams = new URLSearchParams(location.search);
    const member_serial = getCookie('serial') || '';
    const company_serial = getCookie('company_serial') || '';
    const campaign_serial = queryParams.get("serial") || "";


    const { data: campaignData, loading: campaignLoading, error: campaignError } = useFetchCampaignData("campains",company_serial || '');
    const { data: bookingListData, loading: bookingListLoading, error: bookingListError } = useFlagSerialData(fetchBookingData, 'ad_co', company_serial || '');
    
    console.log(bookingListData);
    
    const [bookingInformationModalOpenState, setBookingInformationModalOpenState] = useState(false);


    useEffect(() => {
        if (campaignData) {
            const mappedData = campaignData
                .filter((item: any) => item.serial.toString() === campaign_serial)  // serial과 일치하는 항목만 필터링            
                .map((item: any) => ({
                imgPath: `https://picsum.photos/210/210?random=${item.serial}`,
                campaignName: item.title,
                description: item.content,
                campaignSerial: item.serial,
            }));
            setPostsState(mappedData);
        } else {
            // 기본 데이터 사용
            // setPostsState(campaignListDataGroup);
        }
    }, [campaignData]);
    
    function goToListButtonEvent() {
        // console.log("목록으로");
        navigate("/mypage/advertiser/booking-management/booking-list");
    }

    // modal open, close 이벤트
    function changeBookingModalOpenEvent() {
        setBookingInformationModalOpenState(true);
    }
    function changeBookingModalHandleCancel() {
        setBookingInformationModalOpenState(false);
    }

    function confirmButtonOnClickEvent() {
        // console.log("확인");
        setBookingInformationModalOpenState(false);
    }

    function bookingResultViewOnClickEvent() {
        navigate("/mypage/advertiser/booking-management/booking-campaign-name/booking-request-media-name/result");
    }

    const bodyContentData1 = (
        <Box className="bodyContentDataBox bodyContentDataBox1">
            {postsState.map((element, index) => (
                <Box key={index} className="bodyContentDataLayoutBox">
                    <Flex justify="flex-start" align="center" gap="16px">
                        <Box className="imgBox">
                            <Box className="imgBoxInner">
                                <img src={element.imgPath} alt="" />
                            </Box>
                        </Box>
                        <Box className="informationBox">
                            <Box className="informationBoxInner">
                                <Flex justify="flex-start" align="center" style={{ width: "100%", height: "100%" }}>
                                    <Box className="textBox">
                                        <Box className="nameBox">
                                            <Typography>{element.campaignName}</Typography>
                                        </Box>
                                        <Box className="subinformationBox">
                                            <Typography>{element.description}</Typography>
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <Box className="buttonBox">
                            <Box>
                                <VcAntButton
                                    conceptType="concept"
                                    buttonType="default"
                                    onClick={changeBookingModalOpenEvent}
                                    buttonTitle="부킹 정보"
                                    buttonSize="middle"
                                />
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            ))}
        </Box>
    );

    const bookingStateType = ["대기", "수락", "거절", "취소"];
    const bookingStateArray = [...Array(mediaDataGroup.length)];
    const fillBookingStateArray = bookingStateArray.map((data: any, index: number) => bookingStateType[index]);
    // console.log(bookingStateArray);
    // console.log(fillBookingStateArray);

    const bodyContentData2 = (
        <Box className="bodyContentDataBox bodyContentDataBox2">
            <Box>
                <Box className="bookingApplyMediaWrap">
                    <Box className="bookingApplyMediaWrapInner">
                        <Row gutter={[24, 24]}>
                            {mediaDataGroup.map((data: any, index: number) => {
                                return (
                                    <Col span={12} key={index}>
                                        <BookingApplyMediaLayout
                                            fillBookingStateArray={fillBookingStateArray}
                                            mediaDataGroupData={data}
                                            mediaDataGroupIndex={index}
                                            bookingResultViewOnClickEvent={bookingResultViewOnClickEvent}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const modalContentBodyData = (
        <Box className="modalContentBodyDataWrap">
            <Box className="groupBox">
                <Box className="groupTitleBox">
                    <Typography>{bookingInformationDataGroup.target.groupTitle}</Typography>
                </Box>
                <Box className="groupItemBox">
                    <Row gutter={[2, 2]}>
                        {bookingInformationDataGroup.target.groupItemData.map((data: any, index: number) => {
                            const itemValueArray = data.value;
                            return (
                                <Col span={12} key={index}>
                                    <Box className="groupItem">
                                        <Flex justify="flex-start" align="center" gap="8px">
                                            <Box className="titBox">
                                                <Typography>{data.title}</Typography>
                                            </Box>
                                            <Box className="valBox">
                                                <Flex justify="flex-start" align="center" gap="2px">
                                                    {itemValueArray.map((data: any, index: number) => {
                                                        return (
                                                            <Box className="val" key={index}>
                                                                <Typography>{data}</Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Col>
                            );
                        })}
                    </Row>
                </Box>
            </Box>
            <Box className="dividerBox">
                <Divider />
            </Box>
            <Box className="groupBox">
                <Box className="groupTitleBox">
                    <Typography>{bookingInformationDataGroup.kpi.groupTitle}</Typography>
                </Box>
                <Box className="groupItemBox">
                    <Row gutter={[2, 2]}>
                        {bookingInformationDataGroup.kpi.groupItemData.indicatorData.map((data: any, index: number) => {
                            return (
                                <Col span={12} key={index}>
                                    <Box className="groupItem">
                                        <Flex justify="flex-start" align="center" gap="8px">
                                            <Box className="titBox">
                                                <Typography>{data.title}</Typography>
                                            </Box>
                                            <Box className="valBox">
                                                <Flex justify="flex-start" align="center" gap="2px">
                                                    <Box className="val">
                                                        <Typography>
                                                            {data.value}
                                                            {data.valueUnit}
                                                        </Typography>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Col>
                            );
                        })}
                    </Row>
                </Box>
            </Box>
            <Box className="dividerBox">
                <Divider />
            </Box>
            <Box className="groupBox">
                <Box className="groupTitleBox">
                    <Typography>{bookingInformationDataGroup.campaign.groupTitle}</Typography>
                </Box>
                <Box className="groupItemBox">
                    <Box className="modalCampaignInfoBox">
                        <Flex justify="flex-start" align="center" gap="12px">
                            <Box className="imgBox">
                                <Box className="imgBoxInner">
                                    <img src={campaignListDataGroup[0].imgPath} alt="" />
                                </Box>
                            </Box>
                            <Box className="informationBox">
                                <Box className="informationBoxInner">
                                    <Flex justify="flex-start" align="center" style={{ width: "100%", height: "100%" }}>
                                        <Box className="textBox">
                                            <Box className="nameBox">
                                                <Typography>{campaignListDataGroup[0].campaignName}</Typography>
                                            </Box>
                                            <Box className="subinformationBox">
                                                <Typography>{campaignListDataGroup[0].description}</Typography>
                                            </Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
    const modalContentFooterData = (
        <Box className="modalContentFooterDataWrap">
            <Box className="confirmBox">
                <VcAntButton
                    buttonTitle="확인"
                    buttonType="default"
                    conceptType="concept"
                    buttonSize="large"
                    fullWidth="100%"
                    onClick={confirmButtonOnClickEvent}
                />
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserBookingDetailWrap">
            <MypageSectionCard topTitle="부킹 상세" bodyContent={bodyContentData1} />
            <MypageSectionCard topTitle="부킹 신청 매체" bodyContent={bodyContentData2} />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={goToListButtonEvent}
                            buttonTitle="목록으로"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
            <VcModalPopup
                open={bookingInformationModalOpenState}
                onCancel={changeBookingModalHandleCancel}
                width="500px"
                titleText="부킹정보"
                contentBodyData={modalContentBodyData}
                contentFooterData={modalContentFooterData}
                footerSwitch={false}
                footerItem={
                    <Box>
                        <VcAntButton buttonTitle="Footer" />
                    </Box>
                }
            />
        </Box>
    );
}

export default AdvertiserBookingDetail;
