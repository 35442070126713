import axios from 'axios';

const API_URL = '/api/';

/**
 * 캠페인 정보를 가져오는 함수
 * @param serial 회원 시리얼 번호
 * @returns AxiosPromise<any>
 */

export const fetchBookingData = async (flag: string, serial: string) => {
    let payload;

    switch (flag) {
        case 'ad_all':
        case 'me_all':
            payload = { flag, member_serial: serial };
            break;
        case 'ad_co':
            payload = { flag, ad_co_serial: serial };
            break;
        case 'campain':
            payload = { flag, campain_serial: serial };
            break;
        case 'me_co':
            payload = { flag, me_co_serial: serial };
            break;
        case 'media':
            payload = { flag, media_serial: serial };
            break;
        default:
            payload = {};
    }
    
    const response = await axios.post(`${API_URL}?cat=booking&req=read`, payload);
    return response;
};