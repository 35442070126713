import { Navigate } from 'react-router-dom';
import AuthLayout from "layout/authLayout";

import Cookies from 'js-cookie'; // 쿠키 사용을 위해 추가

import JoinMembership from "pages/authority/joinMembership";
import JoinMembershipComplete from "pages/authority/joinMembership/complete";
import Login from "pages/authority/login";
import FindId from "pages/authority/findId";
import FindPassword from "pages/authority/findPassword";

// ==============================|| AUTHORITY ROUTING ||============================== //

// PublicRoute 컴포넌트 생성
const PublicRoute = ({ children }: { children: React.ReactElement }) => {

  const isAuthenticated = Cookies.get('authority');

  // 이미 로그인한 사용자는 메인 페이지로 리다이렉트
  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const AuthRoutes = {
    path: "/authority",
    element: (
        <PublicRoute>
            <AuthLayout />
        </PublicRoute>
    ),
    children: [
        {
            path: "join-membership",
            element: <JoinMembership />,
        },
        {
            path: "join-membership/complete",
            element: <JoinMembershipComplete />,
        },
        {
            path: "login",
            element: <Login />,
        },
        {
            path: "find-id",
            element: <FindId />,
        },
        {
            path: "find-pw",
            element: <FindPassword />,
        },
    ],
};

export default AuthRoutes;
