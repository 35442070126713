import axios from 'axios';

const API_URL = '/api/';

export interface campaignData {
    flag: string;
    member_serial: number;
    company_serial: number;
    title: string;
    content: string;
}
export interface materialData {
    flag: string;
    campain_serial: number;
    title: string;
    content: string;
    url: string;
}

export const campaignService = {
    createCampaign: async (data: campaignData) => {
        try {
            const response = await axios.post(`${API_URL}?cat=campain&req=create`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    createMaterial: async (data: materialData) => {
        try {
            const response = await axios.post(`${API_URL}?cat=campain&req=create`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    updateCampaign: async (data: campaignData) => {
        try {
            const response = await axios.post(`${API_URL}?cat=campain&req=update`, data);
            return response.data;
        } catch (error) {
            throw new Error('파트너사 수정 중 오류가 발생했습니다.');
        }
    },

    getCampaign: async (flag: string, serial: string) => {
        try {
            let data;

            switch (flag) {
                case 'all':
                    data = { flag, member_serial: serial };
                    break;
                case 'campains':
                    data = { flag, company_serial: serial };
                    break;            
                default:
                    data = { flag, campain_serial: serial };
            }

            const response = await axios.post(`${API_URL}?cat=campain&req=read`, data);
            return response.data;
        
        } catch (error) {
            throw new Error('파트너사 정보 조회 중 오류가 발생했습니다.');
        }
    },

    
    deleteCampaign: async (data: campaignData) => {
        try {
            const response = await axios.post(`${API_URL}?cat=campain&req=delete`, data);
            return response.data;
        } catch (error) {
            throw new Error('파트너사 삭제 중 오류가 발생했습니다.');
        }
    },
};