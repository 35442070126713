import React, { useState, useEffect } from "react";

import { Flex } from "antd";

import { Box, Button } from "@mui/material";

import VcAntInput from "components/antd/data-entry/input";

import AdvertisingTableLayout from "pages/advertiser/advertising/tableLayout";

import { VcAntInputTheme } from "theme/component";

import { kpiCategoryDataGroup } from "data/advertiser/advertising/kpi";
import { useNavigate } from "react-router-dom";

// KPI 데이터 타입 정의
interface KpiDataType {
    [key: string]: {
        title?: string;
        content?: string;
    };
}

function AdvertisingKPI() {
    // 선택된 KPI 데이터를 저장할 상태
    const [selectedKpiData, setSelectedKpiData] = useState<KpiDataType>({});

    const navigate = useNavigate();

    // 지표 번호를 kpi 번호로 변환하는 함수
    const convertTitleToKpiKey = (title: string): string => {
        const number = title.replace('지표', '');
        return `kpi${number}`;
    };

    // 입력값 변경 핸들러 추가
    const handleInputChange = (categoryTitle: string, type: 'title' | 'content', value: string) => {
        const kpiKey = convertTitleToKpiKey(categoryTitle);
        setSelectedKpiData(prev => ({
            ...prev,
            [kpiKey]: {
                ...prev[kpiKey],
                [type]: value
            }
        }));
    };

    const kpiDataArray: any[] = [];
    kpiCategoryDataGroup.forEach((element) => {
        kpiDataArray.push({
            categoryTitle: element.title,
            categoryData: (
                <Box className="kpiInput">
                    <Flex justify="flex-start" align="center" gap="16px" style={{ width: "100%" }}>
                        <Box style={{ width: "auto" }}>
                            <VcAntInput 
                                chooseType="basic" 
                                placeholder={element.indicatorTitPh} 
                                size="large"
                                onChange={(e) => handleInputChange(element.title, 'title', e.target.value)}
                            />
                        </Box>
                        <Box style={{ flex: 1 }}>
                            <VcAntInput 
                                chooseType="basic" 
                                placeholder={element.indicatorConPh} 
                                size="large"
                                onChange={(e) => handleInputChange(element.title, 'content', e.target.value)}
                            />
                        </Box>
                    </Flex>
                </Box>
            ),
        });
    });

    const kpiDataWrapSetStyles = {
        "&": {
            "& .kpiInput": {
                "& .ant-input": {
                    backgroundColor: VcAntInputTheme.backgroundColor,
                    "&:hover": {
                        backgroundColor: VcAntInputTheme.backgroundColorHover,
                    },
                },
            },
        },
    };

    // 'Next' 버튼 클릭 시 로컬 스토리지에 데이터 저장
    const handleNext = () => {
        try {
            const postData = selectedKpiData;
            // 로컬 스토리지에 데이터 저장
            localStorage.setItem('advertisingKpiData', JSON.stringify(postData));
            console.log('KPI Data saved to localStorage:', postData);
            navigate('../campaign'); // 다음 페이지로 이동
        } catch (error) {
            console.error('Error saving KPI data to localStorage:', error);
        }
    };

    // 커스텀 이벤트 리스너 추가
    useEffect(() => {
        const handleCustomNext = () => {
            handleNext();
        };

        window.addEventListener('kpiHandleNext', handleCustomNext);

        return () => {
            window.removeEventListener('kpiHandleNext', handleCustomNext);
        };
    }, []);

    return (
        <Box className="kpiDataWrap" sx={kpiDataWrapSetStyles}>
            <AdvertisingTableLayout tableData={kpiDataArray} />
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button 
                    id="kpi-next-button" 
                    className="kpi-next-button" 
                    variant="contained" 
                    color="primary" 
                    sx={{ display: 'none' }} 
                    onClick={handleNext}
                >
                    다음
                </Button>
            </Box>
        </Box>
    );
}

export default AdvertisingKPI;
