import mainPageSectionOneBg from "assets/images/main/advertiser/section/one/section1_background_img.jpg";

import mainPageSectionTwoBannerOneThumbnail from "assets/images/main/advertiser/section/two/section2_banner1_thumbnail.png";
import mainPageSectionTwoBannerTwoThumbnail from "assets/images/main/advertiser/section/two/section2_banner2_thumbnail.png";
import mainPageSectionTwoBannerThreeThumbnail from "assets/images/main/advertiser/section/two/section2_banner3_thumbnail.png";

import mainPageSectionThreeBg from "assets/images/main/advertiser/section/three/section3_background_img.jpg";
import mainPageSectionThreeBannerOneThumbnail from "assets/images/main/advertiser/section/three/section3_banner1_thumbnail.jpg";
import mainPageSectionThreeBannerTwoThumbnail from "assets/images/main/advertiser/section/three/section3_banner2_thumbnail.jpg";
import mainPageSectionThreeBannerThreeThumbnail from "assets/images/main/advertiser/section/three/section3_banner3_thumbnail.jpg";

import mainPageSectionFourCenterImage from "assets/images/main/advertiser/section/four/section4_center_image.png";

export const mainPageAdvertiserDataGroup = {
    section: {
        one: {
            backgroundImg: mainPageSectionOneBg,
        },
        two: {
            title: "광고주를 위한 효율적인 광고 서비스",
            description: [
                "당신의 브랜드를 더욱 효과적으로 홍보하고,",
                "타겟 시장에 도달하여 성과를 창출하는 데 도움이 되는 특별한 서비스를 경험해보세요.",
            ],
            banner: [
                {
                    thumbnail: mainPageSectionTwoBannerOneThumbnail,
                    title: "AI 기반 매체 추천",
                    explain: [
                        "광고 타겟과 KPI에 적합한 매체를 AI 기술이 추천해드립니다.",
                        "이를 통해 더 효율적인 광고 캠페인을 구성할 수 있습니다.",
                    ],
                },
                {
                    thumbnail: mainPageSectionTwoBannerTwoThumbnail,
                    title: "매체사와의 신속한 계약",
                    explain: [
                        "희망하는 매체를 선택하고 부킹 신청을 하면, 우리 플랫폼이 매체사와의 미팅을 주선하여 신속하고 효율적으로 계약을 성사시킵니다.",
                    ],
                },
                {
                    thumbnail: mainPageSectionTwoBannerThreeThumbnail,
                    title: "전문가 팀의 지원",
                    explain: [
                        "우리 플랫폼의 전문가 팀이 여러분의 광고 캠페인을 지원하고 최적화하는 데 도움을 드립니다.",
                        "광고 전략 수립부터 실행과 최적화까지, 당신의 팀을 최상의 결과를 이끌어내도록 도와드립니다.",
                    ],
                },
            ],
        },
        three: {
            backgroundImg: mainPageSectionThreeBg,
            title: "디지털트윈 시뮬레이션 지원",
            description: [
                "우리 플랫폼은 디지털트윈 시뮬레이션을 통해 옥외광고 매체를 선택하는 과정을 혁신하였습니다.",
                "디지털트윈 시뮬레이션으로 광고주는 옥외광고 매체를 선택하는 과정에서 더욱 확실한 기준과 데이터를 바탕으로 최적의 결정을 내릴 수 있습니다.",
            ],
            banner: {
                miniTitle: "광고 캠페인의 성공을 위한 첫걸음을 함께 하세요.",
                itemList: [
                    {
                        thumbnail: mainPageSectionThreeBannerOneThumbnail,
                        title: "위치 및 인구 통계학",
                        explain: [
                            "옥외 광고 매체의 위치와 해당 지역의 인구 통계 정보를 확인하여 타겟 시장에 적합한 위치를 선택할 수 있습니다.",
                        ],
                    },
                    {
                        thumbnail: mainPageSectionThreeBannerTwoThumbnail,
                        title: "교통 및 통행량",
                        explain: ["매체가 위치한 지역의 교통량 및 통행량 데이터를 분석하여 광고 노출 기회를 예측할 수 있습니다."],
                    },
                    {
                        thumbnail: mainPageSectionThreeBannerThreeThumbnail,
                        title: "환경 요소",
                        explain: [
                            "매체 주변의 환경 요소를 고려하여 광고 효과를 극대화할 수 있는 위치를 선택할 수 있습니다.",
                            "예를 들어, 인근의 건물, 자연 경치 등을 고려합니다.",
                        ],
                    },
                ],
            },
        },
        four: {
            title: "서비스 절차",
            description: ["우리 플랫폼은 광고주를 위한 효율적인 광고 서비스를 제공합니다."],
            centerImage: mainPageSectionFourCenterImage,
            explain: [
                "광고 타겟과 KPI를 설정하고 캠페인을 만들면, AI 기술이 적합한 매체를 추천해드립니다.",
                "여러분이 희망하는 매체를 선택하고 부킹을 신청하면",
                "플랫폼이 해당 매체사와의 미팅을 주선하여 계약을 성사시킬 수 있습니다.",
            ],
        },
    },
};
