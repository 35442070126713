import { useState } from 'react';
import { BookingRequestData, bookingService } from '../services/bookingService';

import Cookies from "js-cookie";

export const useBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const registerBooking = async (mediaSerial: number) => {
      setIsLoading(true);
      setError(null);

      try {
          // localStorage에서 데이터 가져오기
          const campaignData = JSON.parse(localStorage.getItem('advertisingCampaignData') || '{}');
          const kpiData = JSON.parse(localStorage.getItem('advertisingKpiData') || '{}');
          const selectedData = JSON.parse(localStorage.getItem('advertisingSelectedData') || '{}');

            if(campaignData.status === 'existing'){
                // 캠페인 시리얼 번호 설정
                const campainSerial = campaignData.status === 'existing' 
                    ? Number(campaignData.campaignSerial)  // existing인 경우 campaignSerial 사용
                    : 0;  // new인 경우 0 또는 다른 기본값 설정

                // 데이터 변환
                const bookingData = {
                    campain_serial: campainSerial,
                    ad_co_serial: Number(Cookies.get('company_serial')),
                    media_serial: mediaSerial,
                    me_co_serial: Number(Cookies.get('serial')),
                    location: "전국",
                    place: Array.isArray(selectedData?.장소) ? selectedData.장소.join(',') : "전체",
                    gender: Array.isArray(selectedData?.성별) ? selectedData.성별.join(',') : "전체",
                    age: Array.isArray(selectedData?.연령대) ? selectedData.연령대.join(',') : "전체",
                    budget: Array.isArray(selectedData?.['예산(월)']) ? selectedData['예산(월)'].join(',') : "전체",
                    category: Array.isArray(selectedData?.광고카테고리) ? selectedData.광고카테고리.join(',') : "전체",
                    kpi1: JSON.stringify({ title: kpiData?.kpi1?.title || "", content: kpiData?.kpi1?.content || "" }),
                    kpi2: JSON.stringify({ title: kpiData?.kpi2?.title || "", content: kpiData?.kpi2?.content || "" }),
                    kpi3: JSON.stringify({ title: kpiData?.kpi3?.title || "", content: kpiData?.kpi3?.content || "" }),
                    kpi4: JSON.stringify({ title: kpiData?.kpi4?.title || "", content: kpiData?.kpi4?.content || "" }),
                    kpi5: JSON.stringify({ title: kpiData?.kpi5?.title || "", content: kpiData?.kpi5?.content || "" }),
                    kpi6: JSON.stringify({ title: kpiData?.kpi6?.title || "", content: kpiData?.kpi6?.content || "" }),
                    kpi7: JSON.stringify({ title: kpiData?.kpi7?.title || "", content: kpiData?.kpi7?.content || "" }),
                    kpi8: JSON.stringify({ title: kpiData?.kpi8?.title || "", content: kpiData?.kpi8?.content || "" }),
                    kpi9: JSON.stringify({ title: kpiData?.kpi9?.title || "", content: kpiData?.kpi9?.content || "" }),
                    kpi10: JSON.stringify({ title: kpiData?.kpi10?.title || "", content: kpiData?.kpi10?.content || "" })
                };
                const response = await bookingService.createBooking(bookingData);
                return response;
            }   
            else{
                
            }
          
          
      } catch (err) {
          setError(err instanceof Error ? err.message : '부킹 등록 중 오류가 발생했습니다.');
          throw err;
      } finally {
          setIsLoading(false);
      }
  };

  return {
    registerBooking,
    isLoading,
    error
  };
};
