// Layout
import LandingLayout from "layout/landingLayout";

import MainIndex from "pages/main";

// common
import KnowledgeServiceIndex from "pages/common/knowledgeService";
import KnowledgeServiceClassList from "pages/common/knowledgeService/list";
import KnowledgeServiceClassDetail from "pages/common/knowledgeService/detail";

// import Terms from "pages/common/terms";
// import Privacy from "pages/common/privacy";

// ==============================|| MAIN ROUTING ||============================== //

const LandingRoutes = {
    path: "/",
    element: <LandingLayout />,
    children: [
        {
            path: "main",
            element: <MainIndex />,
        },
        {
            path: "main/advertiser",
            // element: "", // 메인페이지와 광고주소개페이지 동일
            element: <MainIndex />,
        },
        {
            path: "main/media-company",
            element: <MainIndex />,
        },
    ],
};

export default LandingRoutes;
