import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import VcAntCheckBox from "components/antd/data-entry/checkbox";
import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";
import VcAntModal from "components/antd/feedback/modal";

import { generalMenuItemsDataGroup } from "data/mypage/main";

import { membershipWithdrawalCommonDataGroup, membershipWithdrawalDataGroup } from "data/mypage/common/membershipWithdrawal";

import { VcAntSwitchTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function MembershipWithdrawal({ className, style }: vcProps) {
    const navigate = useNavigate();

    const [confirmCheckedState, setConfirmCheckedState] = useState<boolean>(false);
    const [membershipWithdrawalButtonState, setMembershipWithdrawalButtonState] = useState<boolean>(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    function withdrawalConfirmModalShowEvent() {
        setIsModalOpen(true);
    }

    function withdrawalConfirmModalHandleOk() {
        setIsModalOpen(false);
    }

    function withdrawalConfirmModalHandleCancel() {
        setIsModalOpen(false);
    }

    const classNames = {
        mask: "membershipWithdrawalMask",
        content: "membershipWithdrawalContent",
        header: "membershipWithdrawalHeader",
        body: "membershipWithdrawalBody",
        footer: "membershipWithdrawalFooter",
    };

    function confirmCheckboxOnChangeEvent(event: any) {
        const isChecked = event.target.checked;
        const isValue = event.target.value;
        setConfirmCheckedState(isChecked);
    }

    function foundResultModalNaviLoginPageEvent() {
        // console.log("로그인");
        navigate("/authority/login");
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="membershipWithdrawalInnerBox">
                <Box className="topNoticeBox">
                    <Typography>{membershipWithdrawalCommonDataGroup.pageNoticeDescription}</Typography>
                </Box>
                <Box className="greyBox">
                    <Box className="greyInnerBox">
                        <Box className="greyTitle">
                            <Typography>{membershipWithdrawalCommonDataGroup.greyBoxTitle}</Typography>
                        </Box>
                        <Box className="userAccount">
                            <Typography>{membershipWithdrawalDataGroup.userInfoAccount}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="warningNotice">
                    {membershipWithdrawalCommonDataGroup.warningBox.map((data: any, index: number) => {
                        return (
                            <Box className="warningNoticeBox" key={index}>
                                <Box className="warningNoticeInnerBox">
                                    <Box className="warningTitle">
                                        <Typography>{data.title}</Typography>
                                    </Box>
                                    <Box className="warningTextList">
                                        {data.list.map((data: any, index: number) => {
                                            return (
                                                <Box className="warningText" key={index}>
                                                    <Typography>{data}</Typography>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
                <Box className="dividerBox"></Box>
                <Box className="confirmBox">
                    <Box className="confirmInnerBox">
                        <Box className="confirmTextbox">
                            <Typography>{membershipWithdrawalCommonDataGroup.confirm.text}</Typography>
                        </Box>
                        <Box className="confirmCheckbox">
                            <VcAntCheckBox
                                conceptType="default"
                                label="동의합니다."
                                checked={confirmCheckedState}
                                value="withdrawal"
                                onChange={confirmCheckboxOnChangeEvent}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box className="buttonBox">
                    <Box className="buttonInnerBox">
                        <VcAntButton
                            buttonType="default"
                            conceptType="concept"
                            buttonTitle="회원탈퇴"
                            onClick={withdrawalConfirmModalShowEvent}
                            disabled={confirmCheckedState === true ? false : true}
                            boxStyle={{ width: "200px" }}
                            buttonStyle={{ width: "100%" }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const membershipWithdrawalModalView = (
        <Box className="detailDataWrap">
            <Box className="detailHeaderBox">
                <Typography>{membershipWithdrawalCommonDataGroup.modal.text.top}</Typography>
            </Box>

            <Box className="detailBodyBox">
                <Box className="detailMainBox">
                    <Flex vertical justify="flex-start" align="center" gap="24px">
                        <Box className="messageBox">
                            {membershipWithdrawalCommonDataGroup.modal.text.body.map((data: any, index: number) => {
                                return <Typography key={index}>{data}</Typography>;
                            })}
                        </Box>
                        <Box className="pwInputBox">
                            <Box className="pwInputInnerBox">
                                <VcAntInput chooseType="password" placeholder="비밀번호를 입력해주세요" />
                            </Box>
                        </Box>
                    </Flex>
                </Box>

                <Box className="detailFooterBox">
                    <Box className="footerButtonGroupBox">
                        <Flex justify="flex-start" align="center" gap="12px">
                            <Box className="footerButtonBox footerLoginButton">
                                <VcAntButton
                                    conceptType="danger"
                                    buttonType="default"
                                    onClick={() => {
                                        console.log("최종 회원탈퇴");
                                        withdrawalConfirmModalHandleOk();
                                    }}
                                    buttonTitle="회원탈퇴"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                            <Box className="footerButtonBox footerFindPwButton">
                                <VcAntButton
                                    conceptType="clear"
                                    buttonType="default"
                                    onClick={() => {
                                        console.log("취소");
                                        withdrawalConfirmModalHandleCancel();
                                    }}
                                    buttonTitle="취소"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const topTitleData = generalMenuItemsDataGroup[3].label;

    const setStyles = {
        "& .ant-typography": { color: "white" },
        ...style,
    };

    return (
        <Box className="membershipWithdrawalWrap" sx={setStyles}>
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
            <VcAntModal
                open={isModalOpen}
                onOk={withdrawalConfirmModalHandleOk}
                onCancel={withdrawalConfirmModalHandleCancel}
                classNames={classNames}
                wrapClassName="withdrawalConfirmModal"
                footer={null}
                maskClosable={false}
                centered={true}
                width="auto"
                mainContent={membershipWithdrawalModalView}
            />
        </Box>
    );
}

export default MembershipWithdrawal;
