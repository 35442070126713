import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";

import { Flex, Typography, Row, Col } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import { partnerListDataGroup } from "data/mypage/advertiser/partner/list";
import { campaignListDataGroup } from "data/mypage/advertiser/campaign/list";

import useFetchCompanyData from 'hooks/useFetchCompanyData';
import useFetchCampaignData from 'hooks/useFetchCampaignData';


interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserPartnerDetail({ className, style }: vcProps) {
    const navigate = useNavigate();

    const [postsState, setPostsState] = useState<any[]>([]);

    const { companySerial } = useParams<{ companySerial: string }>();
    const serial = companySerial || '';

    // 커스텀 훅 사용하여 데이터 가져오기
    const { data: companyData, loading, error } = useFetchCompanyData("partner",serial);
    const { data: campaignData, loading: campaignLoading, error: campaignError } = useFetchCampaignData("campains",serial);

    function partnerInformationOnClickEvent() {
        // console.log("파트너사 정보");
        navigate(
            "/mypage/advertiser/partner-management/partner-name/partner-information"
        );
    }

    function goToListButtonEvent() {
        // console.log("목록으로");
        navigate("/mypage/advertiser/partner-management/partner-list");
    }

    const bodyContentData1 = (
        <Box className="bodyContentDataBox bodyContentDataBox1">
            <Box className="bodyContentDataLayoutBox">
                <Flex justify="flex-start" align="center" gap="16px">
                    <Box className="imgBox">
                        <Box className="imgBoxInner">
                            <img
                                src={partnerListDataGroup[0].companyImgPath}
                                alt=""
                            />
                        </Box>
                    </Box>
                    <Box className="informationBox">
                        <Box className="informationBoxInner">
                            <Flex
                                justify="flex-start"
                                align="center"
                                style={{ width: "100%", height: "100%" }}
                            >
                                <Box className="textBox">
                                    <Box className="nameBox">
                                        <Typography>
                                            {
                                                companyData?.[0]?.name
                                            }
                                        </Typography>
                                    </Box>
                                    <Box className="subinformationBox">
                                        <Typography>
                                            {
                                                companyData?.[0]?.addr
                                            }
                                        </Typography>
                                    </Box>
                                    <Box className="descriptionBox">
                                        <Typography>
                                            {
                                                companyData?.[0]?.tell
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Box className="buttonBox">
                        <Box>
                            <VcAntButton
                                conceptType="concept"
                                buttonType="default"
                                onClick={partnerInformationOnClickEvent}
                                buttonTitle="파트너사 정보"
                                buttonSize="middle"
                            />
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );

    // 임시로 4번만 반복문 하였습니다.
    const empryArray = ["1", "2", "3", "4"];

    const bodyContentData2 = (
        <Box className="bodyContentDataBox bodyContentDataBox2">
            <Row gutter={[8, 8]}>
                {campaignData && campaignData.length > 0 ? (
                    campaignData.map((campaign: any, index: number) => {
                        return (
                        <Col span={12} key={index}>
                            <Box className="bodyContentDataLayoutBox">
                                <Flex justify="flex-start" align="center">
                                    <Box className="imgBox">
                                        <Box className="imgBoxInner">
                                            <img
                                                src={
                                                    campaignListDataGroup[index]
                                                    .imgPath                                                }
                                                alt=""
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="informationBox">
                                        <Box className="informationBoxInner">
                                            <Flex
                                                justify="flex-start"
                                                align="center"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            >
                                                <Box className="textBox">
                                                    <Box className="campaignNameBox">
                                                        <Typography>
                                                            {
                                                                campaign.title
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Box className="adMediabox">
                                                        <Typography>
                                                            {
                                                                campaign.content
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                            </Col>
                        );
                    })
                ) : (
                    <Col span={12}>
                        <Typography>캠페인이 없습니다.</Typography>
                    </Col>
                )}
            </Row>
        </Box>
    );

    return (
        <Box className="advertiserPartnerDetailWrap">
            <MypageSectionCard
                topTitle="파트너사 계정 상세"
                bodyContent={bodyContentData1}
            />
            <MypageSectionCard
                topTitle="캠페인"
                bodyContent={bodyContentData2}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={goToListButtonEvent}
                            buttonTitle="목록으로"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserPartnerDetail;
