export const campaignEditDataGroup = [
    {
        title: "캠페인",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "캠페인명을 입력해주세요",
            id: "campaignTitle"
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "캠페인 내용",
        mainInput: {
            switch: true,
            inputType: "textarea",
            type: "basic",
            placeholder: "캠페인 내용을 입력해주세요",
            id: "campaignContent"
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
];

// 타입 정의
export type MaterialFormType = {
    dataGroup: {
        lineGroup1: {
            title: string;
            mainInput: {
                switch: boolean;
                type: string;
                placeholder: string;
                id: string;
            };
            subButton: {
                left: {
                    switch: boolean;
                    label: string;
                    event: () => void;
                };
                right: {
                    switch: boolean;
                    label: string;
                    event: () => void;
                };
            };
        };
        lineGroup2: {
            title: string;
            mainInput: {
                switch: boolean;
                type: string;
                placeholder: string;
                id: string;
            };
            subButton: {
                left: {
                    switch: boolean;
                    label: string;
                    event: () => void;
                };
                right: {
                    switch: boolean;
                    label: string;
                    event: () => void;
                };
            };
        };
        lineGroup3: {
            title: string;
            mainInput: {
                switch: boolean;
                type: string;
                placeholder: string;
                id: string;
            };
            subButton: {
                left: {
                    switch: boolean;
                    label: string;
                    event: () => void;
                };
                right: {
                    switch: boolean;
                    label: string;
                    event: () => void;
                };
            };
        };
    };
};

// sample
// {
//     title: "타이틀",
//     mainInput: {
//         switch: true,
//         type: "basic",
//         placeholder: "",
//     },
//     secondInput: {
//         switch: false,
//         type: "basic",
//         placeholder: "",
//     },
//     subButton: {
//         left: {
//             switch: false,
//             label: "",
//         },
//         right: {
//             switch: false,
//             label: "",
//         },
//     },
//     moreInput: {
//         switch: false,
//         type: "basic",
//         placeholder: "",
//     },
// },
