import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie';

import { Flex, Row, Col, Typography, message } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";
import SectionMaterialFormControl from "pages/mypage/common/sectionMaterialFormControl";

import notLoadingImage from "assets/images/icon/not_loading_image.svg";

import {
    campaignEditDataGroup,
    MaterialFormType,
} from "data/mypage/advertiser/campaign/edit";

import { useCampaign } from "hooks/useCampaign";

function AdvertiserCampaignRegister() {
    const navigate = useNavigate();
    const [materialForms, setMaterialForms] = useState<MaterialFormType[]>([]);
    const {getCampaign,createCampaign,createMaterial, isLoading, error } = useCampaign();
    const [formData, setFormData] = useState({title: '',content: ''});
    const [latestCampaign, setLatestCampaign] = useState<number>(0);


    function modifyCancelButtonEvent() {
        navigate(-1);
    }
    
    useEffect(() => {
        console.log("Updated materialForms:", materialForms);
        console.log('formData가 변경됨:', formData);
    }, [materialForms,formData]);

    async function modifySaveButtonEvent() {
        const titleElement = document.getElementById('campaignTitle') as HTMLInputElement;
        const contentElement = document.getElementById('campaignContent') as HTMLInputElement;
        const member_serial = Number(Cookies.get('serial')) || 0;
        const company_serial = Number(Cookies.get('company_serial')) || 0;
        let campaignSerial: number;
        const campaignData = {
            flag: "campain",
            member_serial: member_serial,
            company_serial: company_serial,
            title: titleElement?.value || '',
            content: contentElement?.value || '',   
        };
        
        try {
            const result = await createCampaign(campaignData);
            if (result === true) {
                const campaigns = await getCampaign('all', member_serial.toString());
                
                if (Array.isArray(campaigns) && campaigns.length > 0) {
                    campaignSerial = campaigns[campaigns.length - 1].serial;
                    setLatestCampaign(campaignSerial);
                } else {
                    throw new Error('새로 생성된 캠페인을 찾을 수 없습니다.');
                }
                
                message.success('캠페인이 성공적으로 생성되었습니다.');
            } else {
                throw new Error(result);
            }
        } catch (err) {
            console.log('캠페인 생성 실패:', err);
            message.error('캠페인 생성에 실패했습니다.');
            return;
        }
        
        // material forms 처리
        try {
            for (let i = 0; i < materialForms.length; i++) {
                const materialData = {
                    flag: "material",
                    campain_serial: campaignSerial,
                    title: (document.getElementById(`materialTitle_${i}`) as HTMLInputElement)?.value || '',
                    url: (document.getElementById(`materialUrl_${i}`) as HTMLInputElement)?.value || '',
                    content: (document.getElementById(`materialContent_${i}`) as HTMLTextAreaElement)?.value || ''
                };

                const result = await createMaterial(materialData);
                if (result === true) {
                    console.log(`소재 ${i + 1} 생성 성공`);
                } else {
                    message.error(`소재 ${i + 1} 생성 실패`);
                }
            }
            message.success('모든 광고 소재가 성공적으로 생성되었습니다.');
            console.log('모든 광고 소재 생성 완료');
            //navigate(-1);
        } catch (err) {
            console.error('광고 소재 생성 중 오류 발생:', err);
            message.error('광고 소재 생성에 실패했습니다.');
        }
    }

    function createMaterialEditDataGroup(index: number) {  // index 매개변수 추가
        return {
            dataGroup: {
                lineGroup1: {
                    title: "광고 소재",
                    mainInput: {
                        switch: true,
                        type: "basic",
                        placeholder: "광고 소재를 입력해주세요1",
                        id: `materialTitle_${index}`  // index 추가
                    },
                    subButton: {
                        left: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기");
                            },
                        },
                        right: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기");
                            },
                        },
                    },
                },
                lineGroup2: {
                    title: "광고 소재 URL",
                    mainInput: {
                        switch: true,
                        type: "basic",
                        placeholder: "광고 소재 URL을 입력해주세요2",
                        id: `materialUrl_${index}`  // index 추가
                    },
                    subButton: {
                        left: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기1");
                            },
                        },
                        right: {
                            switch: true,
                            label: "보기",
                            event: () => {
                                console.log("보기1");
                            },
                        },
                    },
                },
                lineGroup3: {
                    title: "광고 소재 내용",
                    mainInput: {
                        switch: true,
                        inputType: "textarea",
                        type: "basic",
                        placeholder: "광고 소재 내용을 입력해주세요3",
                        id: `materialContent_${index}`  // index 추가
                    },
                    subButton: {
                        left: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기2");
                            },
                        },
                        right: {
                            switch: false,
                            label: "",
                            event: () => {
                                console.log("보기2");
                            },
                        },
                    },
                },
            },
        };
    }

    function materialPlusOnClickEvent() {
        setMaterialForms(prevForms => [...prevForms, createMaterialEditDataGroup(prevForms.length)]);
    }

    // function materialMinusOnClickEvent(index: number) {
    //     setMaterialForms(materialForms.filter((_, i) => i !== index));
    // }
    function materialMinusOnClickEvent(index: number) {
        setMaterialForms((prevForms) => {
            const updatedForms = [...prevForms];
            updatedForms.splice(index, 1);
            return updatedForms;
        });
    }

     const handleInputChange = (id: string, value: string) => {
        console.log(`Input changed: ${id} = ${value}`);
    };

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <SectionFormControl 
                categoryInputData={campaignEditDataGroup} 
                onInputChange={handleInputChange} 
            />
                {materialForms.map((materialData, index) => (
                    <SectionMaterialFormControl
                        key={index}
                        categoryInputData={[materialData]} // 배열로 감싸서 전달
                        onInputChange={handleInputChange}
                    />
                ))}
            <Box className="materialPlusButtonBox">
                <Flex justify="center" align="center">
                    <VcAntButton
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={materialPlusOnClickEvent}
                    />
                </Flex>
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserCampaignRegisterWrap">
            <MypageSectionCard
                topTitle="캠페인 추가"
                bodyContent={bodyContentData}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="space-between" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifyCancelButtonEvent}
                            buttonTitle="취소"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifySaveButtonEvent}
                            buttonTitle="저장하기"
                            buttonSize="middle"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserCampaignRegister;
