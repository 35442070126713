import React from "react";
import { NavLink } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import { colorGroup } from "theme/palette";

import VcAntButton from "components/antd/general/button";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function Landing({ className, style }: vcProps) {
    const setStyles = {
        "&": {
            width: "100%",
            height: "100%",
            "& .guideBox": {
                width: "max-content",
                height: "auto",
                boxSizing: "border-box",
                padding: "24px",
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: colorGroup.realColor.white.main[60],
                borderRadius: "16px",
                background: colorGroup.realColor.black.main[30],
                "& .guideTopBox": {},
                "& .guideBodyBox": {
                    "& .messageBox": {
                        textAlign: "center",
                        "& .ant-typography": {
                            color: colorGroup.realColor.white.main[100],
                            fontSize: "16px",
                        },
                    },
                    "& .buttonBox": {
                        boxSizing: "border-box",
                        paddingTop: "20px",
                    },
                },
            },
        },
    };

    return (
        <Box sx={setStyles}>
            <Flex justify="center" align="center" style={{ width: "100%", height: "100%" }}>
                <Box className="guideBox">
                    <Box className="guideTopBox"></Box>
                    <Box className="guideBodyBox">
                        <Box className="messageBox">
                            <Typography>랜딩페이지 입니다.</Typography>
                        </Box>
                        <Box className="buttonBox">
                            <Flex justify="center" align="cernter">
                                <NavLink to="/main">
                                    <VcAntButton buttonTitle="메인으로 이동하기" />
                                </NavLink>
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
}

export default Landing;
